import * as React from 'react';
import { Dispatch } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Copyright } from './copyright';
import { getItem } from '@wealthberry/common-base/src/utils/localstorage';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: '115px',
        padding: theme.spacing(3)
    },
    formControl: {
        minWidth: 120,
        paddingLeft: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(4)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

export const AuthenticationPageTemplate: React.FC<{
    language: string;
    setLanguage: Dispatch<any>
}> = ({ children, language, setLanguage }) => {
    const classes = useStyles();
    const languages = Object.keys(getItem('wb-translations')).map((key) => ({ value: key, option: key.toUpperCase() }));

    const handleChange = (event: React.ChangeEvent<{ name?: string, value: string }>) => setLanguage(event.target.value);

    return (
        <React.Fragment>
            <Container
                className={classes.container}
                component="main"
                maxWidth="xs"
            >
                <Paper className={classes.paper}>
                    {children}
                </Paper>
            </Container>

            <Container maxWidth="xs" style={{ padding: 0 }}>
                <FormControl className={classes.formControl}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={language}
                        onChange={handleChange}
                        disableUnderline
                    >
                        {languages.map(({ value, option }) => (
                            <MenuItem key={value} value={value}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Container>
            <Box mt={8} style={{ display: 'flex', justifyContent: 'space-around', marginTop: '16px' }}>
                <Copyright />
            </Box>
        </React.Fragment>
    )
}
