import Grid from '@mui/material/Grid';
import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import { TransactionFormatter } from '@wealthberry/common-base/src/components/transaction-formatter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Link from '@mui/material/Link';
import { getPickerFormat, initialFiltration } from '../../../common/utils';
import { formatDateTechnical, getRoute } from '@wealthberry/common-base/src/utils';
import { ROUTES, TRANSACTION_TYPE } from '../../../common/constants';
import WBLabel from '@wealthberry/common-base/src/components/wb-label';
import { Filters } from '@wealthberry/common-base/src/components/wb-list/filters';
import { WBFilterDialogWrapper } from '@wealthberry/common-base/src/components/wb-list/wb-filter-dialog';
import { WBFormField } from '@wealthberry/common-base/src/forms/field';
import { useContext, useEffect, useReducer, useState } from 'react';
import { updateFormReducer } from '../../../common/forms/reducer';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';
import { useHistory, useParams } from 'react-router-dom';
import { getTokenDetails, getTokenDetailsApi, getTransactionsAPI } from '../../../common/api/getters';
import { useApiCall } from '../../../common/api/hook';
import { getValues } from '@wealthberry/common-base/src/utils/index';
import { transactionFiltration } from '../../deposits/utils';
import { PageLoad } from '../../../common/components/page-load';
import { ApiContext } from '@wealthberry/common-base/src/api/index';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

const FIELD = {
    STATUS: 'status',
    TYPE: 'type',
    TOKEN_SYMBOL: 'tokenSymbol'
}

const immutableFields = {
    [FIELD.STATUS]: {
        id: 'status',
        label: 'translate.status',
        type: 'autocomplete'
    },
    [FIELD.TYPE]: {
        id: 'type',
        label: 'translate.type',
        type: 'autocomplete'
    },
    [FIELD.TOKEN_SYMBOL]: {
        id: 'token_symbol',
        label: 'translate.token.symbol',
        type: 'autocomplete'
    }
}
const filterState = {
    filters: {
        from: {
            error: false,
            value: getPickerFormat(new Date(new Date().setDate(new Date().getDate() - 30))),
        },
        to: {
            error: false,
            value: getPickerFormat(new Date())
        },
    },
    dialog: {
        [FIELD.STATUS]: {
            error: false,
            value: null,
        },
        [FIELD.TYPE]: {
            error: false,
            value: null,
        },
        [FIELD.TOKEN_SYMBOL]: {
            error: false,
            value: null,
        }
    },
    selected: []
}

const StatusLabel = ({ text }) => {
    const color = text === 'translate.status.done' ? 'success' : 'warning';

    return (
        <WBLabel text={text} color={color} variant="text" />
    )
}

export const Transactions = () => {
    const api = useApiCall();
    const [state] = useContext(ApiContext);
    const history = useHistory();
    const [open, setIsOpen] = useModal();
    const [form, dispatch] = useReducer(updateFormReducer, filterState);
    const [items, setItems] = useState([]);
    const { id: clientId } = useParams<{ id: string }>();

    const TRANSACTIONS_API = getTransactionsAPI();
    const TOKEN_DETAILS_API = getTokenDetailsApi();

    const tokenDetails = getTokenDetails(state, TOKEN_DETAILS_API).map(({ symbol }) => ({ name: symbol, value: symbol }));

    const custom = {
        [FIELD.TOKEN_SYMBOL]: {
            options: tokenDetails
        },
        [FIELD.TYPE]: {
            options: [
                { name: 'Buy', value: TRANSACTION_TYPE.Buy },
                { name: 'Burn', value: TRANSACTION_TYPE.Burn },
                { name: 'Deposit', value: TRANSACTION_TYPE.Deposit },
                { name: 'Withdrawal', value: TRANSACTION_TYPE.Withdrawal }
            ]
        },
        [FIELD.STATUS]: {
            options: [
                { name: 'Completed', value: 'done' },
                { name: 'Pending', value: 'pending' }
            ]
        },
    };

    const handleFiltration = () => {
        const filters = getValues(form.dialog);
        let url = initialFiltration({ url: `${TRANSACTIONS_API}?clientUuid=${clientId}&`, form });
        url = transactionFiltration({ url, data: filters });

        api('GET', url)
            .then((result: any[]) => setItems(() => result))
    }

    useEffect(() => {
        handleFiltration()
    }, [form.selected, form.filters]);

    return (
        <PageLoad endpoints={[TOKEN_DETAILS_API]} getters={[TOKEN_DETAILS_API]}>
            <Grid item xs={12}>
                <WBList
                    emptyMessage="translate.pages.investor.transactions.empty"
                    headItems={[
                        'translate.type',
                        'translate.amount',
                        'translate.date',
                        'translate.status'
                    ]}
                    data={items}
                    row={(transaction: Transaction) => {
                        const { type } = transaction;
                        const { transaction: t, value } = TransactionFormatter[type]
                        return (
                            <TableRow>
                                <TableCell>
                                    <Link
                                        sx={{ textTransform: 'capitalize', cursor: 'pointer' }}
                                        onClick={() => history.push(getRoute(ROUTES.TRANSACTION_DETAILS, transaction))}
                                    >
                                        <TranslatedMessage
                                            id="translate.transaction.history.transaction"
                                            values={{
                                                type: type === 'primary_buy' ? 'Buy' : type,
                                                ...t(transaction)
                                            }}
                                        />
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {value(transaction)}
                                </TableCell>
                                <TableCell>
                                    {formatDateTechnical(transaction.createdAt)}
                                </TableCell>
                                <TableCell>
                                    <StatusLabel text={transaction.status} />
                                </TableCell>
                            </TableRow>
                        )
                    }}
                >
                    <Filters
                        form={form}
                        formInitial={filterState}
                        dispatch={dispatch}
                        toggle={setIsOpen}
                    />
                    <WBFilterDialogWrapper
                        toggle={setIsOpen}
                        open={open}
                        title="translate.filter.transactions"
                        dispatch={dispatch}
                        form={form}
                        formInitial={filterState}
                    >
                        <Grid container spacing={4}>
                            {Object.keys(form.dialog).map((key) => (
                                <WBFormField
                                    key={key}
                                    {...form.dialog[key]}
                                    _fieldInfo={{
                                        ...immutableFields[key],
                                        ...custom[key]
                                    }}
                                    setState={(updatedField) => dispatch({
                                        type: 'UPDATE_FORM',
                                        payload: { dialog: { [key]: updatedField } }
                                    })}
                                    validations={[]}
                                />
                            ))}
                        </Grid>
                    </WBFilterDialogWrapper>
                </WBList>
            </Grid>
        </PageLoad>
    )
}
