import Grid from '@mui/material/Grid';
import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Link from '@mui/material/Link';
import { formatCurrency, getRoute } from '@wealthberry/common-base/src/utils';
import { ROUTES } from '../../../common/constants';
import { useHistory } from 'react-router-dom';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

export const Portfolio = ({ assets }) => {
    const history = useHistory();

    return (
        <Grid item xs={12}>
            <WBList
                emptyMessage="translate.pages.investor.list.empty"
                headItems={[
                    'translate.asset.name',
                    'translate.asset.class',
                    'translate.token',
                    'translate.number.of.tokens',
                    'translate.price.per.token',
                    'translate.investment.value'
                ]}
                data={assets}
                row={(asset) => (
                    <TableRow key={asset.id} hover style={{ cursor: 'pointer' }}>
                        <TableCell component="th" scope="row">
                            <Link onClick={() => history.push(getRoute(ROUTES.ASSET, { id: asset.id }))}>
                                {asset.name}
                            </Link>
                        </TableCell>
                        <TableCell>
                            <TranslatedMessage id={asset.class} />
                        </TableCell>

                        <TableCell>
                            {asset.tokenDetailsSymbol}
                        </TableCell>

                        <TableCell>
                            {asset.userTokens}
                        </TableCell>

                        <TableCell>
                            {formatCurrency(asset.tokenPrice)}
                        </TableCell>

                        <TableCell>
                            {formatCurrency(parseFloat(asset.userTokens) * parseFloat(asset.tokenPrice))}
                        </TableCell>
                    </TableRow>
                )}
            />
        </Grid>
    )
}
