import { FC, useCallback, useReducer, useState, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import { useApiCall } from '../../../common/api/hook';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid';
import { getItem } from '@wealthberry/common-base/src/utils/localstorage';
import { WBFormField } from '@wealthberry/common-base/src/forms/field'
import { handleSubmit } from '@wealthberry/common-base/src/forms/handle-submit';
import { getValues } from '@wealthberry/common-base/src/utils';
import { NotificationManager } from '@wealthberry/common-base/src/notifications';
import { fileRequest } from '@wealthberry/common-base/src/api/utils';
import { getFormValidationError, reducer, RESET_STATE, SAVE_FILES } from '../reducer';
import { formInitial, formName, immutableFields, } from './constants';
import { getAssetAPI } from '../../../common/api/getters';
import CONFIG from '../../../config';
import { composeReducers } from '@wealthberry/common-base/src/utils/reducer';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

interface Props {
    id: string;
    onClose: (event?) => void;
    open: boolean;
}

export const AddAssetValuationDialog: FC<Props> = ({ onClose, open, id }) => {
    const api = useApiCall();
    const valuationReducer = composeReducers([updateFormReducer, reducer]);
    const [form, dispatch] = useReducer(valuationReducer, formInitial);

    const { valuations } = form;
    const validations = getItem('wb-doc')?.[`${CONFIG.ASSETS_API}`]?.['/api/asset_valuations']?.['post']?.['x-validation'] || {};

    const handleClose = () => {
        dispatch({
            type: RESET_STATE,
            payload: formInitial
        });
        setDocumentInfo({ name: '', error: false });
        onClose();
    }

    const setValuation = useCallback(() => {
        const values = getValues(valuations);
        const body = {
            amount: values['amount'],
            date: new Date(values['date']) as any / 1000,
            assetUuid: id,
            assetValuationDocuments: [form.documents]
        };

        const hasValidationErrors = getFormValidationError(form);
        if (hasValidationErrors || body.amount === 0) {
            return NotificationManager.error(`Something's wrong with the form`)
        }

        api('POST', `${CONFIG.ASSETS_API}/asset_valuations`, body)
            .then(() => {
                NotificationManager.success('Successfully added new valuation');
                const ASSET_ID_API = getAssetAPI(id);
                api('GET', ASSET_ID_API);
                handleClose();
            })
    }, [form]);

    const [documentInfo, setDocumentInfo] = useState({ error: false, name: '' });

    const handleCapture = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const arrayOfFiles = Array.from(target.files);
        return Promise.all(arrayOfFiles.map((doc: File, index: number) => {
            const formData = new FormData();
            formData.append('file', doc);

            return new Promise((resolve, reject) => {
                fileRequest<{ contentUrl: string }>('POST', `${CONFIG.ASSETS_API}/media_objects`, formData,)
                    .then(({ contentUrl }) => {
                        setDocumentInfo({ name: doc.name, error: false });
                        dispatch({
                            type: SAVE_FILES,
                            payload: {
                                id: 'assetValuationDocuments',
                                updatedField: {
                                    contentUrl,
                                    title: doc.name,
                                }
                            }
                        });
                        resolve({});
                    })
                    .catch(reject);
            })
        }))
    };

    const formSubmit = handleSubmit({
        dispatch,
        formId: 'valuations',
        form: form.valuations,
        validations,
        callback: setValuation
    });

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                <TranslatedMessage id="translate.valuation.dialog.title" />
            </DialogTitle>
            <Box p={3}>
                <Grid container spacing={2}>
                    {Object.keys(valuations)
                        .map((key) => {
                                return (
                                    <WBFormField
                                        key={key}
                                        {...valuations[key]}
                                        _fieldInfo={immutableFields[key]}
                                        setState={(updatedField) =>
                                            dispatch({
                                                type: 'UPDATE_FORM',
                                                payload: {
                                                    [formName]: {
                                                        [key]: updatedField,
                                                    }
                                                }
                                            })
                                        }
                                        validations={validations[key]}
                                    />
                                )
                            }
                        )}
                    <Grid item xs={12}>
                        <input
                            accept={'.pdf,.doc'}
                            style={{ display: 'none' }}
                            id="file"
                            type="file"
                            onChange={handleCapture}
                        />
                        <TextField
                            variant='outlined'
                            fullWidth
                            value={documentInfo.name}
                            label='Document'
                            error={documentInfo.error}
                            helperText={documentInfo.error ? 'Please select a file' : ''}
                            onBlur={() => {
                                setDocumentInfo({ name: documentInfo.name, error: documentInfo.name === '' });
                            }}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <label htmlFor="file">
                                            <div style={{
                                                background: '#E0E0E0',
                                                padding: '10px',
                                                fontSize: '15px',
                                                cursor: 'pointer'
                                            }}>
                                                <TranslatedMessage id="translate.select.file" />
                                            </div>
                                        </label>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'end' }}>
                        <Button disableElevation onClick={handleClose} color="inherit" sx={{ mr: 2 }}>
                            <TranslatedMessage id="translate.button.cancel" />
                        </Button>
                        <Button
                            disableElevation
                            color="primary"
                            variant="contained"
                            onClick={formSubmit}
                        >
                            <TranslatedMessage id="translate.button.confirm" />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};
