import React from 'react';
import { NotificationCenter } from '@wealthberry/common-base/src/notifications';

export const NotificationProvider: React.FC = ({ children }) => {
    return (
        <React.Fragment>
            <NotificationCenter />
            { children }
        </React.Fragment>
    )
}
