import { filters } from '../../common/constants';

export const FIELDS = {
    ASSET_ID: 'assetId',
    OWNER_NAME: 'ownerId',
    CATEGORY: 'category',
    PERFORMED_BY: 'staffId'
}

export const formInitial = {
    ...filters,
    dialog: {
        [FIELDS.ASSET_ID]: {
            error: false,
            value: null,
        },
        [FIELDS.CATEGORY]: {
            error: false,
            value: null,
        },
        [FIELDS.OWNER_NAME]: {
            error: false,
            value: null
        },
        [FIELDS.PERFORMED_BY]: {
            error: false,
            value: null
        },
    },
    selected: []
}
