import Grid from '@mui/material/Grid';
import { PaperBox } from '../../../common/components/paper-box';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { InfoBoxRow } from '../../../common/components/info-box-row';
import { PageLoad } from '../../../common/components/page-load';
import ListItem from '@mui/material/ListItem';

import { formatCurrency, formatDateTechnical } from '@wealthberry/common-base/src/utils';
import { formatCountry } from '../../../common/utils';
import {
    getBankAccounts,
    getBankAccountsApi,
    getClientBalance,
    getClientBalanceApi,
    getCreditCards,
    getCreditCardsApi,
    getFAEnabled,
    getFAEnabledAPI,
} from '../../../common/api/getters';
import React, { useContext, useEffect } from 'react';
import { ApiContext } from '@wealthberry/common-base/src/api/index';
import { useApiCall } from '../../../common/api/hook';
import WBLabel from '@wealthberry/common-base/src/components/wb-label';
import { useParams } from 'react-router-dom';
import { Dialog, DialogTitle, TableCell } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Link from '@mui/material/Link';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';
import Button from '@mui/material/Button';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import CONFIG from '../../../config';

const EmptyPlaceholder = ({ title = 'translate.missing' }) => {
    return (
        <Box
            display="flex"
            padding={2}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            data-e2e="empty-transactions-history"
        >
            <Typography color="textSecondary">
                <TranslatedMessage id={title} />
            </Typography>
        </Box>

    )
}

export const InvestorInfo = ({ clientDetails }) => {
    const [state] = useContext(ApiContext);
    const { id } = useParams<{ id: string }>()
    const api = useApiCall();
    const [isOpen, setIsOpen, bankAccount] = useModal();

    const faEnabled = getFAEnabled(state, `${getFAEnabledAPI()}?user=${clientDetails?.email}`);

    useEffect(() => {
        if (clientDetails?.email) {
            const FA = `${getFAEnabledAPI()}?user=${clientDetails?.email}`;
            api('GET', FA);
        }
    }, [clientDetails?.email]);

    const CLIENT_BALANCE_API = clientDetails?.currency
        ? getClientBalanceApi(id, clientDetails?.currency)
        : '';

    const balanceDetails: { data: InvestorBalance } = getClientBalance(state, CLIENT_BALANCE_API);

    const CC_API = getCreditCardsApi(id);
    const creditCards = getCreditCards(state, `${CONFIG.CREDIT_CARD_API}/cards`);

    const BANK_ACCOUNTS_API = getBankAccountsApi(id);
    const bankAccounts: BankAccount[] = getBankAccounts(state, `${CONFIG.CREDIT_CARD_API}/bank_accounts` );

    const contactInformation = [
        { property: 'translate.first.and.middle.name', value: clientDetails?.firstName },
        { property: 'translate.last.name', value: clientDetails?.lastName },
        {
            property: 'translate.account.type', value: clientDetails?.accountType
                ? <TranslatedMessage id={clientDetails?.accountType} />
                : '-'
        },
        { property: 'translate.company.name', value: clientDetails?.companyName },
        { property: 'translate.company.tax.number', value: clientDetails?.companyTaxNumber },
        { property: 'translate.country', value: formatCountry(clientDetails?.clientAddress?.country) },
        { property: 'translate.address', value: clientDetails?.clientAddress?.street },
        { property: 'translate.postal.code', value: clientDetails?.clientAddress?.zip },
        { property: 'translate.city', value: clientDetails?.clientAddress?.city },
        { property: 'translate.region', value: clientDetails?.clientAddress?.state },
        { property: 'translate.email', value: clientDetails?.email },
        { property: 'translate.phone', value: clientDetails?.clientPhone?.[0]?.number }
    ];

    const accountInformation = [
        { property: 'translate.join.date', value: formatDateTechnical(clientDetails?.createdAt) },
        { property: 'translate.currency', value: clientDetails.currency },
        {
            property: 'translate.2fa', value: faEnabled?.enabled ? (
                <WBLabel density={0} text='translate.active' color='success' variant='light' type='inline' />
            ) : (
                <WBLabel density={0} text='translate.inactive' color='error' variant='light' type='inline' />
            )
        }
    ];

    const balanceInformation = [
        { property: 'translate.invested.in.tokens', value: formatCurrency(balanceDetails?.data?.tokensFiatAmount) },
        { property: 'translate.available.to.invest', value: formatCurrency(balanceDetails?.data?.fiatAmount) }
    ];

    const bankAccountInformation = [
        { property: 'translate.bank.country', value: formatCountry(bankAccount?.country) },
        { property: 'translate.account.currency', value: bankAccount?.currency },
        { property: 'translate.iban', value: bankAccount?.iban },
        { property: 'translate.bic.swift', value: bankAccount?.swiftCode },
        { property: 'translate.recipient.first.name', value: clientDetails?.firstName },
        { property: 'translate.recipient.last.name', value: clientDetails?.lastName },
        { property: 'translate.recipient.country', value: formatCountry(clientDetails?.clientAddress?.country) },
        { property: 'translate.recipient.address', value: clientDetails?.clientAddress?.street },
        { property: 'translate.recipient.postal.code', value: clientDetails?.clientAddress?.zip },
        { property: 'translate.recipient.city', value: clientDetails?.clientAddress?.city },
        { property: 'translate.recipient.region', value: clientDetails?.clientAddress?.state }
    ]

    return (
        <PageLoad endpoints={[CC_API, CLIENT_BALANCE_API, BANK_ACCOUNTS_API]} getters={[CC_API, CLIENT_BALANCE_API, BANK_ACCOUNTS_API]}>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <PaperBox>
                            <Box p={2} display="flex" justifyContent="space-between">
                                <Typography variant="h5" gutterBottom>
                                    <TranslatedMessage id="translate.investor.details.contact.information" />
                                </Typography>
                            </Box>

                            <List>
                                {contactInformation.map(({ property, value }, i) => (
                                    <InfoBoxRow
                                        key={property}
                                        divider={i < contactInformation.length - 1}
                                        property={property}
                                        value={value}
                                    />
                                ))}
                            </List>
                        </PaperBox>
                    </Grid>

                    <Grid item xs={12}>
                        <PaperBox>
                            <Box p={2} display="flex" justifyContent="space-between">
                                <Typography variant="h5" gutterBottom>
                                    <TranslatedMessage id="translate.investor.details.account.information" />
                                </Typography>
                            </Box>

                            <List>
                                {accountInformation.map(({ property, value }, i) => (
                                    <InfoBoxRow
                                        key={property}
                                        divider={i < accountInformation.length - 1}
                                        property={property}
                                        value={value}
                                    />
                                ))}
                            </List>
                        </PaperBox>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Grid container spacing={4}>
                    <PaperBox>
                        <List>
                            <ListItem>
                                <Box display="flex" justifyContent="space-between" flexGrow={1}>
                                    <Typography variant="h5" gutterBottom>
                                        <TranslatedMessage
                                            id="translate.investor.details.wealth.balance" />
                                    </Typography>

                                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                                        <Typography variant="h5">
                                            <Box fontWeight="fontWeightMedium">
                                                {formatCurrency(balanceDetails?.data?.total)}
                                            </Box>
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            <TranslatedMessage id="translate.balance.total" />
                                        </Typography>
                                    </Box>

                                </Box>
                            </ListItem>
                            {balanceInformation.map(({ property, value }, i) => (
                                <InfoBoxRow
                                    key={property}
                                    divider={i < balanceInformation.length - 1}
                                    property={property}
                                    value={value}
                                />
                            ))}
                        </List>
                    </PaperBox>

                    <Grid item xs={12}>
                        <PaperBox>
                            <Box p={2} display="flex" justifyContent="space-between">
                                <Typography variant="h5" gutterBottom>
                                    <TranslatedMessage id="translate.investor.details.cards" />
                                </Typography>
                            </Box>
                            {creditCards.length === 0
                                ? <EmptyPlaceholder title="translate.empty.cards.list" />
                                : (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <TranslatedMessage id="translate.card.type" />
                                                </TableCell>
                                                <TableCell>
                                                    <TranslatedMessage id="translate.last4.digits" />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TranslatedMessage id="translate.expiry.date" />
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {creditCards.map(({ expiryMonth, expiryYear, last4, type }) => (
                                                <TableRow>
                                                    <TableCell>
                                                        {type}
                                                    </TableCell>
                                                    <TableCell>
                                                        {last4}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {expiryMonth}/{expiryYear}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                        </PaperBox>
                    </Grid>

                    <Grid item xs={12}>
                        <PaperBox>
                            <Box p={2} display="flex" justifyContent="space-between">
                                <Typography variant="h5" gutterBottom>
                                    <TranslatedMessage id="translate.investor.details.bank.accounts" />
                                </Typography>
                            </Box>

                            <List>
                                {bankAccounts.length === 0
                                    ? <EmptyPlaceholder title="translate.empty.bank.accounts.list" />
                                    : (
                                        bankAccounts.map((account, i) => (
                                            <InfoBoxRow
                                                key={account.id}
                                                divider={i < bankAccounts.length - 1}
                                                property="translate.iban"
                                                value={
                                                    <Link onClick={() => setIsOpen(account)}>
                                                        {account.iban}
                                                    </Link>
                                                }
                                            />
                                        )))
                                }
                            </List>
                        </PaperBox>

                        <Dialog open={isOpen} maxWidth="sm" fullWidth>
                            <DialogTitle>
                                <TranslatedMessage id="translate.bank.account.details" />
                            </DialogTitle>

                            <List sx={{ pl: 1, pr: 1 }}>
                                {bankAccountInformation.map((row, i) => (
                                    <InfoBoxRow
                                        key={row.property}
                                        divider={i < bankAccountInformation.length - 1}
                                        {...row}
                                    />
                                ))}
                            </List>

                            <Box p={2} display="flex" alignContent="flex-end" justifyContent="flex-end">
                                <Button disableElevation color="inherit" onClick={setIsOpen} sx={{ mr: 2 }}>
                                    <TranslatedMessage id="translate.button.close" />
                                </Button>
                            </Box>
                        </Dialog>
                    </Grid>
                </Grid>
            </Grid>
        </PageLoad>
    )
}
