import { FC, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import { immutableFields } from './reducer';
import { WBFormField } from '@wealthberry/common-base/src/forms/field';
import { WBFilterDialogWrapper } from '@wealthberry/common-base/src/components/wb-list/wb-filter-dialog';
import { TextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { getFlagEmoji, getFlagEmojiByCode } from '@wealthberry/common-base/src/utils/flag';
import { FIELDS } from './reducer';
import { getCountries } from '@wealthberry/common-base/src/utils/localstorage';

type Props = {
    toggle: () => void,
    open: boolean;
    dispatch: any;
    form: any;
    formInitial: any;
}
const countries = getCountries('list') || [];
const extraProps: Record<string, Partial<TextFieldProps>> = {
    [FIELDS.PHONE_CODE]: {
        children: countries.map(({ countryCode, name, code }) => (
            <MenuItem key={code} value={countryCode} onClick={(e) => e.stopPropagation()}>
                {`${getFlagEmoji(code)} ${countryCode} ${name}`}
            </MenuItem>
        )),
        SelectProps: {
            renderValue: (code: string) => (
                <Fragment>
                        <span style={{ position: 'relative', top: '2px ' }}>
                            {getFlagEmojiByCode(code, countries)}
                        </span>
                    &nbsp;
                    {code}
                </Fragment>
            )
        },
        sx: {
            '& .MuiOutlinedInput-root': {
                '& .MuiOutlinedInput-notchedOutline': {
                    // borderRight: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                }
            }
        }
    },
    [FIELDS.PHONE]: {
        sx: {
            '& .MuiOutlinedInput-root': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                }
            }
        }

    }
};

export const FilterDialog: FC<Props> = ({ toggle, open, form, dispatch, formInitial }) => {
    return (
        <WBFilterDialogWrapper
            title="translate.filter.dialog.investors"
            toggle={toggle}
            open={open}
            form={form}
            dispatch={dispatch}
            formInitial={formInitial}
        >
            <Grid container spacing={3}>
                {Object.keys(form.dialog).map((key) => (
                    <WBFormField
                        key={key}
                        {...form.dialog[key]}
                        _fieldInfo={{
                            ...immutableFields[key],
                            ...extraProps[key]
                        }}
                        setState={(updatedField) =>
                            dispatch({
                                type: 'UPDATE_FORM',
                                payload: {
                                    dialog: {
                                        [key]: updatedField,
                                    }
                                }
                            })
                        }
                        validations={[]}
                    />
                ))}
            </Grid>
        </WBFilterDialogWrapper>
    );
};

