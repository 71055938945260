import { filters } from '../../common/constants';

export const FIELDS = {
    EMAIL: 'email',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PHONE_CODE: 'phoneCode',
    PHONE: 'phone',
    COMPANY: 'companyName',
    COUNTRY: 'country',
    COMPLETED_PROFILE: 'profile'
}
export const immutableFields = {
    [FIELDS.EMAIL]: {
        id: FIELDS.EMAIL,
        label: 'translate.email',
    },
    [FIELDS.FIRST_NAME]: {
        id: FIELDS.FIRST_NAME,
        label: 'translate.first.and.middle.name',
        type: 'text',
    },
    [FIELDS.LAST_NAME]: {
        id: FIELDS.LAST_NAME,
        label: 'translate.last.name',
        type: 'text',
    },
    [FIELDS.PHONE_CODE]: {
        id: FIELDS.PHONE_CODE,
        xs: 3,
        label: 'translate.add.asset.owner.phone',
        select: true,
    },
    [FIELDS.PHONE]: {
        id: FIELDS.PHONE,
        xs: 9,
        GridProps: {
            style: {
                paddingLeft: 0
            }
        },
        label: '',
    },
    [FIELDS.COMPANY]: {
        id: FIELDS.COMPANY,
        label: 'translate.company.name',
    },
    [FIELDS.COUNTRY]: {
        id: FIELDS.COUNTRY,
        label: 'translate.country',
        type: 'autocomplete'
    },
    [FIELDS.COMPLETED_PROFILE]:{
        id: FIELDS.COMPLETED_PROFILE,
        label: 'translate.search.completed.profiles',
        type: 'checkbox',
        size: 'small'
    },
}

export const formInitial = {
    ...filters,
    dialog: {
        [FIELDS.EMAIL]: {
            error: false,
            value: null,
        },
        [FIELDS.FIRST_NAME]: {
            error: false,
            value: '',
        },
        [FIELDS.LAST_NAME]: {
            error: false,
            value: '',
        },
        [FIELDS.PHONE_CODE]: {
            error: false,
            value: '',
        },
        [FIELDS.PHONE]: {
            error: false,
            value: '',
        },
        [FIELDS.COMPANY]: {
            error: false,
            value: '',
        },
        [FIELDS.COUNTRY]: {
            error: false,
            value: null,
        },
        [FIELDS.COMPLETED_PROFILE]: {
            error: false,
            value: null,
            _selected: 'Completed profiles',
        },
    },
    selected: []
}
