import { ImmutableFields, MutableFields } from '../../../common/forms/types';

export const AssetOwnerFields = {
    CompanyName: 'companyName',
    CompanyTaxNumber: 'companyTaxNumber',
    Country: 'country',
    Address: 'address',
    Zip: 'zip',
    City: 'city',
    Region: 'region',
    PhoneCode: 'phone-code',
    Phone: 'phone',
    Email: 'email',
    ResponsiblePerson: 'responsiblePerson'
}

export const AssetOwnerMutable: MutableFields =
    Object.values(AssetOwnerFields).reduce((acc, key) => ({
        ...acc,
        [key]: {
            error: false,
            helperText: null,
            value: key === 'country' ? null : '',
            touched: false,
        }
    }), {});

export const AssetOwnerImmutable: ImmutableFields = {
    [AssetOwnerFields.CompanyName]: {
        id: AssetOwnerFields.CompanyName,
        autoFocus: true,
        label: 'translate.add.asset.owner.company-name',
    },
    [AssetOwnerFields.CompanyTaxNumber]: {
        id: AssetOwnerFields.CompanyTaxNumber,
        label: 'translate.add.asset.owner.company-tax-number',
    },
    [AssetOwnerFields.Country]: {
        id: AssetOwnerFields.Country,
        label: 'translate.add.asset.owner.country',
        type: 'autocomplete',
    },
    [AssetOwnerFields.Address]: {
        id: AssetOwnerFields.Address,
        label: 'translate.add.asset.owner.address',
    },
    [AssetOwnerFields.Zip]: {
        id: AssetOwnerFields.Zip,
        label: 'translate.add.asset.owner.zip',
    },
    [AssetOwnerFields.City]: {
        id: AssetOwnerFields.City,
        label: 'translate.add.asset.owner.city',
    },
    [AssetOwnerFields.Region]: {
        id: AssetOwnerFields.Region,
        label: 'translate.add.asset.owner.region',
    },
    [AssetOwnerFields.PhoneCode]: {
        id: AssetOwnerFields.PhoneCode,
        xs: 3,
        label: 'translate.add.asset.owner.phone',
        select: true,
    },
    [AssetOwnerFields.Phone]: {
        id: AssetOwnerFields.Phone,
        xs: 9,
        GridProps: {
            style: {
                paddingLeft: 0
            }
        },
        label: '',
    },
    [AssetOwnerFields.Email]: {
        id: AssetOwnerFields.Email,
        label: 'translate.add.asset.owner.email',
    },
    [AssetOwnerFields.ResponsiblePerson]: {
        id: AssetOwnerFields.ResponsiblePerson,
        label: 'translate.add.asset.owner.responsible.person',
    }
}
