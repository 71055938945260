import { formatCurrencySymbol } from '@wealthberry/common-base/src/utils';
export const formName = 'valuations';

export const immutableFields = {
    ['date']: {
        id: 'date',
        label: 'translate.valuation.date',
        type: 'date',
    },
    ['amount']: {
        id: 'amount',
        label: 'translate.valuation.amount',
        InputProps: {
            startAdornment: formatCurrencySymbol()
        },
    },
}
export const formInitial = {
    [formName]: {
        ['date']: {
            error: false,
            value: null,
            touched: false,
        },
        ['amount']: {
            error: false,
            value: '',
            touched: false,
        }
    },
    documents: []
}
