import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Paper } from '@mui/material';
import Link from '@mui/material/Link';
import { formatCurrency, formatDateTechnical } from '@wealthberry/common-base/src/utils';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';
import { AddAssetValuationDialog } from './add-asset-valuation-dialog';
import CONFIG from '../../../config';
import { ASSET_STATUS } from '../../../common/constants';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

export default function AssetValuations({ asset, title }) {
    const [open, setDialogState] = useModal();
    return (
        <Grid item xs={12} sm={12}>
            <AddAssetValuationDialog open={open} onClose={setDialogState} id={asset.id} />
            <Paper square variant="outlined">
                <Box p={2} display='flex' justifyContent='space-between'>
                    <Typography variant='h5' gutterBottom>{title}</Typography>
                    <Button
                        disabled={asset.status !== ASSET_STATUS.SaleOpen}
                        variant="outlined"
                        color="primary"
                        onClick={setDialogState}
                    >
                        <TranslatedMessage id="translate.add" />
                    </Button>
                </Box>
                {asset && Boolean(asset.assetValuations?.length) && (
                    <Table aria-label="list of asset valuations">
                        <TableHead>
                            <TableRow>
                                <TableCell><TranslatedMessage id="translate.date" /></TableCell>
                                <TableCell><TranslatedMessage id="translate.valuation" /></TableCell>
                                <TableCell align="right">
                                    <TranslatedMessage id="translate.document" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {asset && asset.assetValuations?.map((valuation) => (
                                <TableRow key={asset.id}>
                                    <TableCell component="th" scope="row">
                                        {formatDateTechnical(valuation.date, true)}
                                    </TableCell>
                                    <TableCell>
                                        {formatCurrency(valuation.amount, asset.currency)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Link
                                            target="_blank"
                                            href={`${CONFIG.ASSETS}/${valuation?.assetValuationDocuments[0]?.contentUrl}`}
                                        >
                                            <TranslatedMessage id="translate.download" />
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Paper>
        </Grid>
    )
}

