import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import { formatCountry, formatDateTimeTechnical } from '../../common/utils';
import { formatCurrency, getRoute } from '@wealthberry/common-base/src/utils';
import { ROUTES } from '../../common/constants';
import WBLabel from '@wealthberry/common-base/src/components/wb-label';

import { useHistory } from 'react-router-dom';
import { TranslatedMessage, useIntlWithDefaultMessage } from '@wealthberry/common-base/src/packages/translated-message';

const statusColors = {
    'translate.status.completed': 'success',
    'translate.status.pending': 'warning',
}

export const Row: FC<{ item }> = ({ item }) => {
    const history = useHistory();
    const intl = useIntlWithDefaultMessage();
    return (
        <TableRow key={item.id} hover style={{ cursor: 'pointer' }}>
            <TableCell component="th" scope="row">
                <Link onClick={() => history.push(getRoute(ROUTES.TRANSACTION_DETAILS, {
                    id: item.id,
                    type: 'withdrawal'
                }))}>
                    {formatCurrency(item.details?.amount)}
                </Link>
            </TableCell>
            <TableCell>
                {item.bankAccountUuid && <TranslatedMessage id="translate.bank.wire" />}
            </TableCell>
            <TableCell>
                <Link
                    onClick={() => history.push(getRoute(ROUTES.INVESTOR_DETAILS, { id: item.clientData?.clientUuid }))}
                >
                    {item.clientData?.firstName} {item.clientData?.lastName}
                </Link>
            </TableCell>
            <TableCell>
                {formatCountry(item.clientData?.country)}
            </TableCell>
            <TableCell>
                {formatDateTimeTechnical(item.createdAt)}
            </TableCell>
            <TableCell>
                {item?.completedAt ? formatDateTimeTechnical(item.completedAt) : '-'}
            </TableCell>
            <TableCell>
                {item.status && (
                    <WBLabel
                        text={intl.formatMessage({ id: item.status })}
                        type='inline'
                        color={statusColors[item.status]}
                        variant="text"
                    />)}
            </TableCell>
        </TableRow>
    )
}
