import { FC, useEffect, useReducer, useState } from 'react';
import { useApiCall } from '../../common/api/hook';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';
import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import { PageLoad } from '../../common/components/page-load';
import { formInitial } from './reducer';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import { getTransactionsAPI } from '../../common/api/getters';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Row } from './row';
import { FilterDialog } from './filter-dialog';
import { getValues } from '@wealthberry/common-base/src/utils/index';
import { Filters } from '@wealthberry/common-base/src/components/wb-list/filters';
import { initialFiltration } from '../../common/utils';
import { transactionFiltration } from './utils';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { getFiltersLoadingState } from '@wealthberry/common-base/src/loader/context';

const Deposits: FC = () => {
    const api = useApiCall();
    const [open, toggle] = useModal();
    const [form, dispatch] = useReducer(updateFormReducer, formInitial)
    const [items, setItems] = useState<Transaction[]>([]);

    const handleFiltration = () => {
        const filters = getValues(form.dialog);
        let url = initialFiltration({ url: `${getTransactionsAPI()}?type=deposit&`, form });
        url = transactionFiltration({ url, data: filters });

        api('GET', url).then((result: Transaction[]) => {
            setItems(result);
        });
    }

    useEffect(() => {
        handleFiltration()
    }, [form.selected, form.filters]);

    const loading = getFiltersLoadingState([getTransactionsAPI()])

    return (
        <Container disableGutters maxWidth="lg">
            <Box py={4} display="flex" justifyContent="space-between">
                <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant='h4'>
                        <TranslatedMessage id='translate.pages.deposit.list.title' />
                    </Typography>
                </Box>
            </Box>
            <WBList
                emptyMessage='translate.pages.deposit.list.empty'
                loading={loading}
                headItems={[
                    'translate.amount',
                    'translate.method',
                    'translate.investor.name',
                    'translate.investor.country',
                    'translate.date',
                    'translate.status'
                ]}
                form={form}
                dispatch={dispatch}
                data={items}
                toggle={toggle}
                formInitial={formInitial}
                row={(item) => <Row item={item} />}
            >
                <Filters
                    form={form}
                    formInitial={formInitial}
                    dispatch={dispatch}
                    toggle={toggle}
                />
                <FilterDialog
                    open={open}
                    formInitial={formInitial}
                    toggle={toggle}
                    form={form}
                    dispatch={dispatch}
                />
            </WBList>
        </Container>
    );
}

export default Deposits;
