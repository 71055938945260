import React, { FC, useCallback } from 'react';
import { Alert, Dialog, DialogTitle } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useApiCall } from '../../../common/api/hook';
import { PageLoad } from '../../../common/components/page-load';
import { updateAssetStatusApi } from '../../../common/api/getters';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

export const AssetStatusUpdate: FC<{
    assetId: string;
    alert: string;
    alertValues?: any;
    button: string;
    message: string;
    onComplete: VoidFunction;
    open: boolean;
    status: string;
    title: string;
    toggle: VoidFunction;
}> = ({ assetId, alert, alertValues, button, open, message, onComplete, status, title, toggle }) => {
    const api = useApiCall();

    const STATUS_UPDATE_API = updateAssetStatusApi(assetId);
    const updateStatus = useCallback((status) => {
        api('PUT', STATUS_UPDATE_API, { status })
            .then(onComplete)
    }, [assetId, status, onComplete]);

    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <PageLoad endpoints={assetId ? [STATUS_UPDATE_API] : []} getters={assetId ? [STATUS_UPDATE_API] : []} >
                <DialogTitle>
                    <TranslatedMessage id={title} />
                </DialogTitle>

                <Grid container spacing={2} p={3}>
                    <Grid item>
                        <Alert severity="warning">
                            <TranslatedMessage id={alert} values={alertValues}/>
                        </Alert>
                    </Grid>

                    <Grid item>
                        <Typography>
                            <TranslatedMessage id={message} />
                        </Typography>
                    </Grid>
                </Grid>

                <Box p={2} display="flex" alignContent="flex-end" justifyContent="flex-end">
                    <Button disableElevation color="inherit" onClick={toggle} sx={{ mr: 2 }}>
                        <TranslatedMessage id="translate.button.cancel" />
                    </Button>
                    <Button disableElevation color="primary" onClick={() => updateStatus(status)} variant="contained">
                        <TranslatedMessage id={button} />
                    </Button>
                </Box>
            </PageLoad>
        </Dialog>
    )
}
