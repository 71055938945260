import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Typography from '@mui/material/Typography';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

export function InfoBoxRow({ divider, property, value = '-' }): JSX.Element {
    return (
        <ListItem divider={divider} data-e2e='list-item'>
            <Typography variant="body1" color="textSecondary">
                <TranslatedMessage id={property} />
            </Typography>

            <ListItemSecondaryAction>
                <Box
                    fontWeight="fontWeightMedium"
                    fontSize="body1.fontSize"
                >
                    {value}
                </Box>
            </ListItemSecondaryAction>
        </ListItem>
    )
}
