import { FC, useEffect, useReducer, useState } from 'react';
import { useApiCall } from '../../common/api/hook';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';

import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import { PageLoad } from '../../common/components/page-load';
import { FIELDS, formInitial } from './reducer';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import { getTransactionsAPI, getWithdrawalsApi } from '../../common/api/getters';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Row } from './row';
import { FilterDialog } from './filter-dialog';
import { getValues } from '@wealthberry/common-base/src/utils/index';
import { Filters } from '@wealthberry/common-base/src/components/wb-list/filters';
import { initialFiltration } from '../../common/utils';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { getFiltersLoadingState } from '@wealthberry/common-base/src/loader/context';

export const withdrawalsFiltration = ({ url, data }) => {
    if (data[FIELDS.STATUS]) {
        url += `&status=${data[FIELDS.STATUS].value}`;
    }

    if (data[FIELDS.FIRST_NAME]) {
        url += `&clientData.firstName=${data[FIELDS.LAST_NAME]}`;
    }

    if (data[FIELDS.LAST_NAME]) {
        url += `&clientData.lastName=${data[FIELDS.LAST_NAME]}`;
    }

    if (data[FIELDS.AMOUNT_FROM]) {
        url += `&details.amount[gte]=${data[FIELDS.AMOUNT_FROM]}`;
    }

    if (data[FIELDS.AMOUNT_TO]) {
        url += `&details.amount[lte]=${data[FIELDS.AMOUNT_TO]}`;
    }

    if (data[FIELDS.COUNTRY]) {
        url += `&clientData.country=${data[FIELDS.COUNTRY].code}`;
    }
    if (data[FIELDS.IBAN]) {
        url += `&iban=${data[FIELDS.IBAN]}`;
    }

    if (data[FIELDS.COMPLETED_BY]) {
        url += `&staffUuid=${data[FIELDS.COMPLETED_BY]}`;
    }

    if (data[FIELDS.COMPLETED_FROM]) {
        url += `&completedAt[gte]=${data[FIELDS.COMPLETED_FROM]}`;
    }

    if (data[FIELDS.COMPLETED_TO]) {
        url += `&completedAt[lte]=${data[FIELDS.COMPLETED_TO]}`;
    }

    return url;
}

const Withdrawals: FC = () => {
    const api = useApiCall();
    const [open, toggle] = useModal();
    const [form, dispatch] = useReducer(updateFormReducer, formInitial);
    const [items, setItems] = useState<Transaction[]>([]);

    const handleFiltration = () => {
        const filters = getValues(form.dialog);
        let url = initialFiltration({ url: `${getWithdrawalsApi()}?`, form });
        url = withdrawalsFiltration({ url, data: filters });

        api('GET', url).then((result: Transaction[]) => {
            setItems(result);
        });
    }

    useEffect(() => {
        handleFiltration()
    }, [form.selected, form.filters]);
    const loading = getFiltersLoadingState([getWithdrawalsApi()])
    return (
        <Container disableGutters maxWidth="lg">
            <Box py={4} display="flex" justifyContent="space-between">
                <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant='h4'>
                        <TranslatedMessage id='translate.pages.withdrawals.list.title' />
                    </Typography>
                </Box>
            </Box>
            <WBList
                loading={loading}
                emptyMessage='translate.pages.liquidations.list.empty'
                headItems={[
                    'translate.amount',
                    'translate.method',
                    'translate.investor.name',
                    'translate.investor.country',
                    'translate.date.created',
                    'translate.date.completed',
                    'translate.status'
                ]}
                form={form}
                dispatch={dispatch}
                data={items}
                toggle={toggle}
                formInitial={formInitial}
                row={(item) => <Row item={item} />}
            >
                <Filters
                    form={form}
                    formInitial={formInitial}
                    dispatch={dispatch}
                    toggle={toggle}
                />
                <FilterDialog
                    open={open}
                    formInitial={formInitial}
                    toggle={toggle}
                    form={form}
                    dispatch={dispatch}
                />
            </WBList>
        </Container>
    );
}

export default Withdrawals;
