import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export const Copyright: React.FC = () => (
    <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit">
            Wealthberry
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
)
