import { getPickerFormat } from './utils';

export const ROUTES = {
    // Authentication
    LOGIN: '/',
    LOGIN_TEST: '/automation-login',
    ADMIN: '/admin',
    ASSET: '/asset/:id',
    ASSET_OWNER: '/asset-owner',
    ASSET_OWNER_DETAILS: '/asset-owner/:id',
    ADD_NEW_ASSET: '/asset-creation/:type',
    UPDATE_DRAFT_ASSET: '/asset-creation/:type/:id/:draftId',
    INVESTORS: '/investors',
    INVESTOR_DETAILS: '/investor/:id',
    DEPOSITS: '/deposits',
    LIQUIDATIONS: '/liquidations',
    TRANSACTION_DETAILS: '/transaction/:type/:id',
    WITHDRAWALS: '/withdrawals',
    PRIMARY_MARKET: '/primary-market'
}

export const ASSET_STATUS = {
    Created: 'translate.asset.status.created',
    Deleted: 'translate.asset.status.deleted',
    ComingSoon: 'translate.asset.status.coming_soon',
    SaleOpen: 'translate.asset.status.sale_open',
    Draft: 'translate.asset.status.draft',
    Liquidated: 'translate.asset.status.liquidated'
}

export const STATUS_COLOR_MAP: Record<string, WB.Label['color']> = {
    [ASSET_STATUS.Created]: 'created',
    [ASSET_STATUS.Deleted]: 'error',
    [ASSET_STATUS.ComingSoon]: 'success',
    [ASSET_STATUS.SaleOpen]: 'published',
    [ASSET_STATUS.Draft]: 'info',
    [ASSET_STATUS.Liquidated]: 'liquidated',
}

export const FIELDS = {
    FROM: 'from',
    TO: 'to',
}

export const filters = {
    filters: {
        [FIELDS.FROM]: {
            error: false,
            value: getPickerFormat(new Date(new Date().setDate(new Date().getDate() - 30))),
        },
        [FIELDS.TO]: {
            error: false,
            value: getPickerFormat(new Date(new Date().setHours(23, 59))),
        },
    }
}

export const TRANSACTION_TYPE = {
    Deposit: 'deposit',
    Buy: 'primary_buy',
    Withdrawal: 'withdrawal',
    Burn: 'burn',
}
