export const investmentDetails = [
    {
        key: 'Initial asset valuation',
        value: 'assetValuation',
        type: 'currency',
        id: 'assetValuation'
    }, {
        key: 'Investment type',
        value: 'investmentType',
        type: 'custom',
        id: 'investmentType'
    }, {
        key: 'Target return',
        value: 'targetReturn',
        type: 'percentage',
        id: 'targetReturn'
    }, {
        key: 'Maximum expected return',
        value: 'expectedReturn',
        type: 'custom',
        id: 'expectedReturn'
    }, {
        key: 'Asset owner',
        value: 'assetOwner',
        id: 'assetOwner',
        type: 'custom'
    }, {
        key: 'Documents',
        type: 'link',
        edit: true,
        value: 'investmentDocumentsDialog',
        id: 'documents'
    },
];

export const tokenDetails = [
    {
        key: 'Token symbol',
        value: 'tokenDetailsSymbol',
        id: 'tokenDetailsSymbol'
    },
    {
        key: 'Token issuance price',
        value: 'tokenIssuancePrice',
        type: 'currency',
        id: 'tokenIssuancePrice'
    },
    {
        key: 'Min token buy back price',
        value: 'tokenBuybackPrice',
        type: 'currency',
        id: 'tokenBuybackPrice'
    },
    {
        key: 'Token supply',
        value: 'tokenSupply',
        type: 'number',
        id: 'tokenSupply'
    },
    {
        key: 'Reserved tokens',
        value: 'reservedTokens',
        type: 'number',
        id: 'reservedTokens'
    },
    {
        key: 'Token issuance date',
        value: 'tokenIssuanceDate',
        type: 'date',
        short: true,
        id: 'tokenIssuanceDate',
    },
    {
        key: 'Trading start date',
        value: 'tokenLiquidationDate',
        type: 'date',
        short: true,
        id: 'tokenLiquidationDate',
    },
    {
        key: 'Token liquidation date',
        value: 'tokenLiquidationDate',
        type: 'date',
        short: true,
        id: 'tokenLiquidationDate',
    },
]

export const details = [
    {
        key: 'Name',
        value: 'name',
        id: 'name',
    },
    {
        key: 'Status',
        value: 'status',
        type: 'custom',
        id: 'status',
    },
    {
        key: 'Date published',
        value: 'createdAt',
        type: 'date',
        format: 'technical',
        id: 'datePublished',
    },
    {
        key: 'Class',
        value: 'class',
        type: 'custom',
        id: 'class',
    },
    {
        key: 'Type',
        type: 'custom',
        value: 'category',
        id: 'category',
    },
    {
        key: 'Subtype',
        type: 'nested',
        value: 'subcategory',
        id: 'subcategory',
    },
    {
        key: 'Description',
        type: 'link',
        value: 'descriptionDialog',
        id: 'description',
    },
    {
        key: 'Gallery',
        value: 'gallery',
        type: 'link',
        id: 'gallery',
        edit: true,
    },
    {
        key: 'Location',
        type: 'link',
        value: 'locationDialog',
        id: 'location',
        edit: true,
    },
    {
        key: 'Total area (sq. m.)',
        type: 'nested',
        value: 'totalArea',
        id: 'totalArea',
    },
    {
        key: 'Year of completion',
        type: 'nested',
        value: 'yearCompleted',
        id: 'yearCompleted',
    },
    {
        key: 'Year of creation',
        value: 'fineartYearOfCreation',
        type: 'nested',
        id: 'fineartYearOfCreation',
    },
    {
        key: 'Characteristics',
        type: 'link',
        value: 'assetCharacteristicsDialog',
        id: 'assetCharacteristicsDialog',
    },
    {
        key: 'Author name',
        value: 'authorName',
        type: 'nested',
        id: 'authorName',
    },
    {
        key: 'Author birth year',
        value: 'authorBirthyear',
        type: 'nested',
        id: 'authorBirthyear',
    },
    {
        key: 'Author death year',
        value: 'authorDeathyear',
        type: 'nested',
        id: 'authorDeathyear',
    },
    {
        key: 'Author description',
        value: 'authorDescriptionDialog',
        type: 'link',
        id: 'authorDescriptionDialog',
    },
    {
        key: 'Author image',
        value: 'authorGallery',
        type: 'link',
        id: 'authorImageDialog',
    },
]

export const investmentTypeMap = {
    passive_income_and_capital_gain: 'Passive income & capital gain',
    capital_gain: 'Capital gain'
}
