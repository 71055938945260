import React, { FC, useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { initSearch } from '../../../common/utils/google-maps';
import DialogTitle from '@mui/material/DialogTitle';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import CONFIG from '../../../config';

interface Props {
    onClose: (event?) => void;
    open: boolean;
    location: any;
}

const containerStyle = {
    width: '100%',
    height: '540px'
};

export const LocationEditDialog: FC<Props> = ({ onClose, open, location }) => {
    const info = location ? JSON.parse(location) : { address: { formatted_address: '' } };
    const [coordinatesNew, setLocationCoordinates] = useState(info)
    const saveCoordinates = (coordinates) => {
        if (coordinates.lat !== info.lat || coordinates.lng !== info.lng) {
            setLocationCoordinates(coordinates)
        }
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: CONFIG.GOOGLE_MAPS_API_KEY,
        libraries: ['places']
    });

    const center = {
        lat: coordinatesNew.lat,
        lng: coordinatesNew.lng
    };

    const [map, setMap] = useState(null)

    const onLoad = useCallback(function callback(map) {
        setMap(map);
        initSearch(map, saveCoordinates)
    }, []);

    const formSubmit = () => {
        console.log('call to save new coordinates - ')
    }

    return (
        <Dialog onClose={onClose} open={open} maxWidth='md' fullWidth>
            <DialogTitle>
                Edit Location
            </DialogTitle>
            <Grid item xs={12} sx={{ position: 'relative' }}>
                <input
                    id="pac-input"
                    data-e2e='pac-input'
                    style={{
                        height: '60px',
                        padding: '10px',
                        width: '300px',
                        borderRadius: '3px',
                        position: 'absolute',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        top: 30,
                        zIndex: 100000
                    }}
                    type="text"
                    placeholder="Postcode, Address, City, State, Country"
                />
                {isLoaded && (
                    <GoogleMap
                        id='google-map'
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                        onLoad={onLoad}
                    >
                        <Marker position={center} />
                    </GoogleMap>
                )}
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'end', px: 3, py: 2 }}>
                <Button disableElevation onClick={onClose} color="inherit" sx={{ mr: 2 }} data-e2e="btn-cancel">
                    <TranslatedMessage id='translate.button.cancel' />
                </Button>
                <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    data-e2e="btn-update"
                    onClick={formSubmit}
                >
                    <TranslatedMessage id='translate.button.update' />
                </Button>
            </Grid>
        </Dialog>
    );
};
