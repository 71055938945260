import { FC, useCallback } from 'react';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { NotificationManager } from '@wealthberry/common-base/src/notifications/index';
import { getAssetOwnersApi } from '../../../common/api/getters';
import { useApiCall } from '../../../common/api/hook';
import { ROUTES } from '../../../common/constants';
import { useHistory } from 'react-router-dom';
import WBDialogWrapper from '../../../common/components/wb-dialog-wrapper';

export const DialogDeleteAssetOwner: FC<{
    id: string;
    open: boolean;
    setClose: VoidFunction;
}> = (props) => {
    const { id, open, setClose } = props;
    const history = useHistory();
    const api = useApiCall();
    const ASSET_OWNERS_API = getAssetOwnersApi();
    const deleteOwner = useCallback(() => {
        api('DELETE', `${ASSET_OWNERS_API}/${id}`)
            .then(() => {
                setClose();
                NotificationManager.success('translate.asset.owner.deleted');
                history.push(ROUTES.ASSET_OWNER)
            });
    }, [id]);

    return (
        <WBDialogWrapper
            maxWidth='xs'
            open={open}
            onClose={setClose}
            onSubmit={deleteOwner}
            onSubmitBtnLabel='translate.button.delete'
            title='translate.delete.asset.owner'
        >
            <Grid container spacing={3} px={3} pb={3}>
                <Grid item xs={12}>
                    <Alert severity="warning">
                        <TranslatedMessage id="translate.delete.owner.alert" />
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1' color='textSecondary'>
                        <TranslatedMessage id="translate.delete.owner.confirmation" />
                    </Typography>
                </Grid>
            </Grid>

        </WBDialogWrapper>
    )
}
