import { useEffect } from 'react';
import CONFIG from '../../../config';
import { setSessionData } from '@wealthberry/common-base/src/utils/localstorage';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { useApiCall } from '../../../common/api/hook';

export const LoginTest = () => {
    const publicApi = useApiCall(true);
    const history = useHistory();

    useEffect(() => {
        publicApi('POST', `${CONFIG.ADMIN_API}/authenticate/google`, { loginToken: 'playwrightAutomationToken' })
            .then((session: { token: string, refreshToken: string }) => {
                setSessionData(session);
                history.push(ROUTES.ADMIN)
            })
            .catch(console.log);
    }, [])

    return (
        <div>Automation test</div>
    )
}
