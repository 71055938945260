import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { ROUTES } from '../../../common/constants';
import { loginWithGooglePromise } from '../utils';
import { setSessionData } from '@wealthberry/common-base/src/utils/localstorage';
import { PageLoad } from '../../../common/components/page-load';
import { useApiCall } from '../../../common/api/hook';
import CONFIG from '../../../config';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

export const UPDATE_FIELD = 'UPDATE_FIELD';

export const Login: React.FC = () => {
    const history = useHistory();
    const publicApi = useApiCall(true);

    // TODO change to only load script instead of
    // trying to login
    React.useEffect(() => {
        loginWithGooglePromise()
            .then((response) => {
                const { id_token: loginToken }: any = Object.values(response).reduce((acc: object, current: object) => ({
                    ...acc,
                    ...current
                }), {})

                publicApi('POST', `${CONFIG.ADMIN_API}/authenticate/google`, { loginToken })
                    .then((session: any) => {
                        setSessionData(session);
                        history.push(ROUTES.ADMIN);
                    })
                    .catch(console.log)
            });
    }, []);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography component="h1" variant="h5" align="center">
                    <TranslatedMessage id="translate.login.title" />
                </Typography>
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Link component="button" variant="body2">
                    <div
                        id="gLoginButton"
                        className="g-signin2"
                        data-onsuccess="onSignIn"
                    />
                </Link>
            </Grid>
        </Grid>
    )
}
