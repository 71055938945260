import { PageLoad } from '../../common/components/page-load';
import React, { FC, useContext, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    getAssets,
    getAssetsAPI,
    getClientBalance,
    getClientBalanceApi,
    getClientDetails,
    getClientDetailsApi,
    getClientLogsAPI,
} from '../../common/api/getters';
import { ApiContext } from '@wealthberry/common-base/src/api/index';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from '../../common/components/tab-panel';
import { InvestorInfo } from './tabs/investor-info';
import { Portfolio } from './tabs/portfolio';
import { Transactions } from './tabs/transactions';
import { Logs } from './tabs/logs';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import { formInitial } from '../../common/logs/reducer';

export const InvestorDetails: FC = () => {
    const { id: clientId } = useParams<{ id: string }>();
    const [state] = useContext(ApiContext);
    const [selectedTab, setSelectedTab] = useState(0);

    const CLIENT_DETAILS_API = getClientDetailsApi(clientId);
    const clientDetails: Client = getClientDetails(state, CLIENT_DETAILS_API);

    const ASSETS_API = getAssetsAPI();
    const assets = getAssets(state, ASSETS_API);

    const USER_ASSETS_API = getClientBalanceApi(clientId, 'EUR');
    const { data } = getClientBalance(state, USER_ASSETS_API);

    const clientAssetData = data?.assets
        ? data.assets.map((a) => ({ ...a, ...assets.find(({ id }) => id === a.id) }))
        : [];

    const [form, dispatch] = useReducer(updateFormReducer, formInitial);

    return (
        <PageLoad endpoints={[CLIENT_DETAILS_API, ASSETS_API]} getters={[CLIENT_DETAILS_API, ASSETS_API]}>
            <Container disableGutters maxWidth="lg">
                <Box py={4} display="flex" justifyContent="space-between">
                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">
                            <TranslatedMessage id="translated.investor.details.title" />
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        indicatorColor="primary"
                        onChange={(e, newValue) => setSelectedTab(() => newValue)}
                        textColor="primary"
                        value={selectedTab}
                        variant="standard"
                    >
                        <Tab label={<TranslatedMessage id="translate.investor.info" />} />
                        <Tab label={<TranslatedMessage id="translate.portfolio" />} />
                        <Tab label={<TranslatedMessage id="translate.transactions" />} />
                        <Tab label={<TranslatedMessage id="translate.log" />} />
                    </Tabs>
                </Box>

                <TabPanel value={selectedTab} index={0}>
                    <InvestorInfo clientDetails={clientDetails} />
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                    <Portfolio assets={clientAssetData} />
                </TabPanel>

                <TabPanel value={selectedTab} index={2}>
                    <Transactions />
                </TabPanel>

                <TabPanel value={selectedTab} index={3}>
                    <Logs dispatch={dispatch} form={form} />
                </TabPanel>
            </Container>
        </PageLoad>
    )
}
