import React, { createContext, FC, useReducer } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import UploadImage from '../../assets-list/components/upload-image';
import { saveFileLocationReducer } from '../../../common/reducers/save-file-location';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const STORE_LOCATION = 'assetImages';

interface Props extends DialogProps {
    images: Image[];
    onClose: VoidFunction;
}

export const EditGalleryDialog: FC<Props> = (props) => {
    const { onClose, open, images } = props;
    const EditGalleryContext = createContext([{ fileLocation: { [STORE_LOCATION]: [] } }, saveFileLocationReducer]);
    const value = useReducer(saveFileLocationReducer, { fileLocation: { [STORE_LOCATION]: images } });

    return (
        <Dialog onClose={onClose} open={open} maxWidth="lg" fullWidth>
            <DialogTitle>
                <TranslatedMessage id="translate.edit.gallery.dialog.title" />
            </DialogTitle>

            <Box px={3} py={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <EditGalleryContext.Provider value={value}>
                            <UploadImage context={EditGalleryContext} storeLocation={STORE_LOCATION} />
                        </EditGalleryContext.Provider>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'end' }}>
                        <Button disableElevation onClick={onClose} color="inherit" sx={{ mr: 2 }}>
                            <TranslatedMessage id='translate.button.close' />
                        </Button>

                        <Button
                            disableElevation
                            color="primary"
                            variant="contained"
                            data-e2e="btn-update"
                            //onClick={formSubmit}
                        >
                            <TranslatedMessage id='translate.button.update' />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}
