import { FC } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { InfoBoxRow } from '../../../common/components/info-box-row';
import { formatCurrency } from '@wealthberry/common-base/src/utils';
import Typography from '@mui/material/Typography';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import WBDialogWrapper from '../../../common/components/wb-dialog-wrapper';

export const DialogFundsForLiquidation: FC<{
    liquidations: { amount: string; id: string; name: string; }[];
    open: boolean;
    setClose: VoidFunction;
}> = (props) => {
    const { liquidations, open, setClose } = props;

    return (
        <WBDialogWrapper
            open={open}
            onClose={setClose}
            title='translate.raised.funds'
        >
            <List sx={{ pl: 1, pr: 1 }}>
                {liquidations.length === 0
                    ? (
                        <Box
                            display="flex"
                            padding={2}
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            data-e2e="empty-transactions-history"
                        >
                            <Typography color="textSecondary">
                                <TranslatedMessage id="translate.no.assets.have.liquidation" />
                            </Typography>
                        </Box>
                    )

                    : liquidations.map((l, i) => (
                        <InfoBoxRow
                            key={l.id}
                            divider={i < liquidations.length - 1}
                            property={l.name}
                            value={formatCurrency(parseInt(l.amount))}
                        />
                    ))
                }
            </List>
        </WBDialogWrapper>
    )
}
