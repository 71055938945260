const BASE_CONFIG = {
    ASSETS: 'https://assets.local.wealthberry.com',
    ASSETS_API: 'https://assets.local.wealthberry.com/api',
    CLIENTS_API: 'https://clients.local.wealthberry.com/api',
    CREDIT_CARD_API: 'https://cc.local.wealthberry.com/api',
    LOGIN_API: 'https://login.local.wealthberry.com/api',
    ADMIN_API: 'https://admin-service.local.wealthberry.com/api',
    ORDERS_API: 'https://order.local.wealthberry.com/api',
    TOKENIZE_API: 'https://tokenize.local.wealthberry.com',
    LOGS_API: 'https://logger.local.wealthberry.com/api',

    NODE_ENV: 'development',
    GAUTH_CLIENT_ID: '1021817510753-gl9rnk9rdve004su0h2gh2b7f04a4lcb.apps.googleusercontent.com',
    RECAPTCHA_KEY: '6LfbsRkbAAAAAClZxurrxu-exOlMHlTaJ31pIyJ-',
    GOOGLE_MAPS_API_KEY: 'AIzaSyDTfAn3lQpjncgm_LNOiXL8xMBoJ0nZkxk',
    TEST: false
};

const STAGING_CONFIG = {
    ADMIN_API: 'https://admin-service.staging.wealthberry.com/api',
    ASSETS: 'https://assets.staging.wealthberry.com',
    ASSETS_API: 'https://assets.staging.wealthberry.com/api',
    CLIENTS_API: 'https://clients.staging.wealthberry.com/api',
    CREDIT_CARD_API: 'https://cc.staging.wealthberry.com/api',
    LOGIN_API: 'https://login.staging.wealthberry.com/api',
    ORDERS_API: 'https://order.staging.wealthberry.com/api',
    TOKENIZE_API: 'https://tokenize.staging.wealthberry.com',
    LOGS_API: 'https://logger.staging.wealthberry.com/api',

    NODE_ENV: 'development',
    GAUTH_CLIENT_ID: '1021817510753-gl9rnk9rdve004su0h2gh2b7f04a4lcb.apps.googleusercontent.com',
    RECAPTCHA_KEY: '6LfbsRkbAAAAAClZxurrxu-exOlMHlTaJ31pIyJ-',
    GOOGLE_MAPS_API_KEY: 'AIzaSyDTfAn3lQpjncgm_LNOiXL8xMBoJ0nZkxk',
    TEST: false
};

const PRODUCTION_CONFIG = {
    ADMIN_API: 'https://admin.api.wealthberry.com/api',
    ASSETS: 'https://www.wealthberry.com',
    ASSETS_API: 'https://assets.api.wealthberry.com/api',
    CREDIT_CARD: 'https://cc.api.wealthberry.com',
    CREDIT_CARD_API: 'https://cc.api.wealthberry.com/api',
    CLIENTS_API: 'https://clients.api.wealthberry.com/api',
    LOGIN_API: 'https://login.api.wealthberry.com/api',
    ORDERS_API: 'https://order.api.wealthberry.com/api',
    TOKENIZE_API: 'https://tokenize.api.wealthberry.com',
    LOGS_API: 'https://logger.api.wealthberry.com/api',

    GAUTH_CLIENT_ID: '1021817510753-gl9rnk9rdve004su0h2gh2b7f04a4lcb.apps.googleusercontent.com',
    GOOGLE_MAPS_API_KEY: 'AIzaSyDTfAn3lQpjncgm_LNOiXL8xMBoJ0nZkxk',
    RECAPTCHA_KEY: '6LfbsRkbAAAAAClZxurrxu-exOlMHlTaJ31pIyJ-',

    NODE_ENV: 'production',
    TEST: false
}

const TEST_CONFIG = {
    ...BASE_CONFIG,
    TEST: true
}

const getConfig = () => {
    switch (process.env.APP_CONFIG) {
        case 'development':
            return BASE_CONFIG;

        case 'staging':
            return STAGING_CONFIG;

        case 'production':
            return PRODUCTION_CONFIG;

        case 'test':
            return TEST_CONFIG;

        default:
            return BASE_CONFIG;
    }
}

export default getConfig();
