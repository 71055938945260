export const WBLogo = () => (
    <svg id="faa5c0db-1251-4748-b285-df9782e9cb5d" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 228.04 41.37" width="230px" height="33px">
        <path
            d="M158.928,18.016c0,6.327-3.445,9.315-7.277,9.315a6.55,6.55,0,0,1-4.639-2.109v1.723h-3.586V3.182l3.586-1.863v8.929A7.031,7.031,0,0,1,151.827,8.1C155.8,8.1,158.928,10.986,158.928,18.016Zm-3.62.106c0-4.64-1.477-6.679-4.149-6.679a5.671,5.671,0,0,0-4.182,2.355v7.769a5.911,5.911,0,0,0,4.323,2.425C153.9,23.992,155.308,22.023,155.308,18.122Z"
            fill="#4a148c" />
        <path
            d="M178.438,17.348c0,.668-.07,1.266-.1,1.652h-12.2c.386,3.551,2.39,5.062,4.639,5.062a6.468,6.468,0,0,0,4.008-1.476l2.144,2.32a8.58,8.58,0,0,1-6.363,2.425c-4.323,0-7.979-3.48-7.979-9.6,0-6.257,3.3-9.631,8.085-9.631C175.907,8.1,178.438,12.357,178.438,17.348Zm-3.655-1.335c-.176-2.531-1.371-4.64-4.359-4.64-2.426,0-3.9,1.687-4.218,4.64Z"
            fill="#4a148c" />
        <path
            d="M193.906,8.877l-.949,3.445a5.406,5.406,0,0,0-2.777-.668c-2.285,0-3.62,1.617-3.62,4.746V26.945h-3.621V8.49h3.621v1.758A5.115,5.115,0,0,1,190.778,8.1,5.311,5.311,0,0,1,193.906,8.877Z"
            fill="#4a148c" />
        <path
            d="M208.6,8.877l-.95,3.445a5.4,5.4,0,0,0-2.777-.668c-2.284,0-3.62,1.617-3.62,4.746V26.945h-3.621V8.49h3.621v1.758A5.117,5.117,0,0,1,205.472,8.1,5.317,5.317,0,0,1,208.6,8.877Z"
            fill="#4a148c" />
        <path
            d="M228.04,8.49l-9.28,24.958h-3.831l2.671-6.855-6.784-18.1h3.972l3.023,9.034c.527,1.617,1.406,4.359,1.617,5.379.316-1.091,1.09-3.727,1.652-5.309l3.129-9.1Z"
            fill="#4a148c" />
        <path
            d="M12.615,21.858l3.59-2.072,3.59,2.072V26L16.2,28.077,12.615,26ZM4.205,26,7.8,28.077,11.385,26V21.858L7.8,19.786l-3.59,2.072ZM16.82,14.571v4.146l3.59,2.072L24,18.717V14.571L20.41,12.5Zm-8.41,0v4.146L12,20.789l3.59-2.072V14.571L12,12.5ZM0,18.717l3.59,2.072,3.59-2.072V14.571L3.59,12.5,0,14.571Zm12.615-7.286L16.2,13.5l3.59-2.072V7.285L16.2,5.212l-3.59,2.073Zm-8.41,0L7.8,13.5l3.59-2.072V7.285L7.8,5.212,4.205,7.285Z"
            fill="#4a148c" />
        <path
            d="M56.453,2.339,51.321,26.945H46.505L43.482,14.29c-.351-1.476-.7-3.3-.844-4.218a42.474,42.474,0,0,1-.808,4.218L38.737,26.945H34.132L29,2.339h5.132l2.144,11.986c.211,1.2.493,3.023.6,4.043.176-1.02.562-2.847.844-4.043L40.6,2.339h4.288l2.918,11.986c.281,1.2.668,3.023.843,4.043.106-1.02.387-2.883.6-4.043L51.391,2.339Z"
            fill="#06b405" />
        <path
            d="M73.961,17.735a14.929,14.929,0,0,1-.071,1.547H62.853a3.551,3.551,0,0,0,3.8,3.48,4.117,4.117,0,0,0,3.164-1.3l3.445,2.882a8.608,8.608,0,0,1-6.609,2.988c-5.307,0-8.682-3.761-8.682-9.6,0-5.659,3.55-9.631,8.226-9.631C71.114,8.1,73.961,12.463,73.961,17.735Zm-4.71-2.109a3.275,3.275,0,0,0-3.3-3.3c-1.758,0-2.848,1.441-3.024,3.3Z"
            fill="#06b405" />
        <path
            d="M92.593,26.945H87.742v-1.23a5.9,5.9,0,0,1-4.148,1.616c-3.445,0-6.89-2.109-6.89-6.573,0-4.078,3.3-6.117,7.488-6.117a7.646,7.646,0,0,1,3.55.739v-.914a2.336,2.336,0,0,0-2.637-2.355,9.1,9.1,0,0,0-5.061,1.23L78.181,9.967A12.415,12.415,0,0,1,85.105,8.1c4.465,0,7.488,2.32,7.488,6.468Zm-4.851-5.414V19.212a5.616,5.616,0,0,0-3.375-.774c-1.827,0-2.812.844-2.812,2.32,0,1.3.739,2.461,2.812,2.461A3.969,3.969,0,0,0,87.742,21.531Z"
            fill="#06b405" />
        <path d="M102.156,26.945H97.305V3.745l4.851-2.426Z" fill="#06b405" />
        <path
            d="M118.045,21.285l-.7,4.922a6.466,6.466,0,0,1-3.761,1.124c-2.988,0-4.921-2.32-4.921-5.554V13.06h-3.094V8.49h3.094V3.745l4.85-2.426V8.49h4.429v4.57H113.51v7.417c0,1.758.633,2.214,1.793,2.214C116.182,22.691,116.849,22.3,118.045,21.285Z"
            fill="#06b405" />
        <path
            d="M138.574,26.945h-4.851V16.962c0-3.3-1.125-4.289-3.128-4.289s-3.164.984-3.164,4.183V26.945H122.58V3.745l4.851-2.426v8.4A6.048,6.048,0,0,1,131.755,8.1c4.71,0,6.819,3.233,6.819,8.436Z"
            fill="#06b405" />
        <polygon points="8.41 0 8.41 4.146 12 6.219 15.59 4.146 15.59 0 12 2.126 8.41 0" fill="#06b405" />
        <path d="M132.189,39.02h-3.575l-.8,2.232h-1.161l3.258-8.531h.985l3.263,8.531H133Zm-3.235-.92h2.9L130.4,34.11Z"
              fill="#666" />
        <path
            d="M135.462,41.252V32.721h2.408a3.878,3.878,0,0,1,1.969.492,3.324,3.324,0,0,1,1.321,1.4,4.55,4.55,0,0,1,.472,2.086v.545a4.571,4.571,0,0,1-.466,2.115,3.274,3.274,0,0,1-1.33,1.394,4.106,4.106,0,0,1-2.013.5Zm1.125-7.611v6.691h1.184a2.572,2.572,0,0,0,2.024-.809,3.346,3.346,0,0,0,.724-2.3v-.5a3.4,3.4,0,0,0-.683-2.261,2.428,2.428,0,0,0-1.936-.818Z"
            fill="#666" />
        <path
            d="M144.941,32.721l2.789,6.961,2.789-6.961h1.459v8.531h-1.125V37.93l.1-3.586-2.8,6.908H147.3l-2.795-6.89.111,3.568v3.322h-1.125V32.721Z"
            fill="#666" />
        <path d="M155.326,41.252H154.2V32.721h1.125Z" fill="#666" />
        <path d="M164.1,41.252h-1.131l-4.3-6.574v6.574h-1.131V32.721h1.131l4.307,6.6v-6.6H164.1Z" fill="#666" />
        <path
            d="M170.5,37.913v3.339h-1.125V32.721h3.147a3.159,3.159,0,0,1,2.194.715,2.425,2.425,0,0,1,.794,1.893,2.391,2.391,0,0,1-.777,1.913,3.3,3.3,0,0,1-2.223.671Zm0-.92h2.022a2.018,2.018,0,0,0,1.382-.426,1.554,1.554,0,0,0,.481-1.23,1.6,1.6,0,0,0-.481-1.22,1.889,1.889,0,0,0-1.318-.476H170.5Z"
            fill="#666" />
        <path
            d="M183.665,37.262a5.268,5.268,0,0,1-.422,2.189,3.188,3.188,0,0,1-1.195,1.426,3.291,3.291,0,0,1-1.805.493,3.186,3.186,0,0,1-3-1.908,5.066,5.066,0,0,1-.442-2.124v-.615a5.2,5.2,0,0,1,.428-2.174,3.164,3.164,0,0,1,3-1.945,3.306,3.306,0,0,1,1.807.495,3.185,3.185,0,0,1,1.2,1.436,5.289,5.289,0,0,1,.422,2.188Zm-1.119-.551a3.828,3.828,0,0,0-.609-2.329,2.011,2.011,0,0,0-1.705-.811,1.988,1.988,0,0,0-1.679.811,3.75,3.75,0,0,0-.63,2.253v.627a3.833,3.833,0,0,0,.618,2.312,2.168,2.168,0,0,0,3.39.047,3.818,3.818,0,0,0,.615-2.277Z"
            fill="#666" />
        <path
            d="M188.638,37.8h-2v3.451H185.5V32.721h2.824a3.347,3.347,0,0,1,2.218.656,2.362,2.362,0,0,1,.776,1.911,2.294,2.294,0,0,1-.43,1.388,2.568,2.568,0,0,1-1.2.885l2,3.621v.07h-1.207Zm-2-.92h1.728a1.946,1.946,0,0,0,1.333-.434,1.466,1.466,0,0,0,.5-1.162,1.546,1.546,0,0,0-.472-1.215,2,2,0,0,0-1.362-.429h-1.723Z"
            fill="#666" />
        <path d="M198.48,33.641h-2.743v7.611h-1.119V33.641h-2.736v-.92h6.6Z" fill="#666" />
        <path d="M204.15,39.02h-3.575l-.8,2.232h-1.161l3.258-8.531h.985l3.263,8.531h-1.154Zm-3.235-.92h2.9l-1.454-3.99Z"
              fill="#666" />
        <path d="M208.553,40.332H212.6v.92h-5.174V32.721h1.131Z" fill="#666" />
    </svg>
);
