import React, { FC, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as getters from '../../../common/api/getters';
import { getLiquidation } from '../../../common/api/getters';
import { PageLoad } from '../../../common/components/page-load';
import { ApiContext } from '@wealthberry/common-base/src/api/index';
import { formatCurrency, formatDateTechnical, getRoute, omit } from '@wealthberry/common-base/src/utils';
import { InfoBoxRow } from '../../../common/components/info-box-row';
import { PaperBox } from '../../../common/components/paper-box';
import TableBody from '@mui/material/TableBody';
import { ASSET_STATUS, ROUTES } from '../../../common/constants';
import { raisedFunds } from '../../assets-list/utils';
import { DialogRaisedFunds } from './dialog-raised-funds';
import { DialogFundsForLiquidation } from './dialog-funds-for-liquidation';
import { DialogDeleteAssetOwner } from './dialog-delete-asset-owner';
import { TranslatedMessage, useIntlWithDefaultMessage } from '@wealthberry/common-base/src/packages/translated-message';
import CONFIG from '../../../config';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';
import { DialogEditAssetOwner } from './dialog-edit-asset-owner';
import { formatCountry } from '../../../common/utils';

const formatValue = {
    createdAt: formatDateTechnical,
    country: formatCountry
}

const contactInfoViewOrder = {
    companyName: 'translate.add.asset.owner.company-name',
    companyTaxNumber: 'translate.add.asset.owner.company-tax-number',
    country: 'translate.add.asset.owner.country',
    address: 'translate.add.asset.owner.address',
    zip: 'translate.add.asset.owner.zip',
    city: 'translate.add.asset.owner.city',
    region: 'translate.add.asset.owner.region',
    phone: 'translate.add.asset.owner.phone',
    email: 'translate.add.asset.owner.email',
    responsiblePerson: 'translate.add.asset.owner.responsible.person',
    createdAt: 'translate.add.asset.owner.created-at'
}

export const AssetOwnerDetails: FC = () => {
    const intl = useIntlWithDefaultMessage();
    const history = useHistory();
    const [state] = useContext(ApiContext);
    const params = useParams<{ id: string }>();
    const [raisedFundsDialog, setRaisedFundsDialog] = useModal();
    const [fundsInLiquidationDialog, setFundsInLiquidationDialog] = useModal();
    const [columns] = useState([
        { key: 'name', value: 'translate.asset.name' },
        { key: 'class', value: 'translate.asset.class' },
        { key: 'status', value: 'translate.status' }
    ]);

    const ASSET_OWNER_INFO_API = getters.getAssetOwnerInfoApi(params.id);
    const assetOwnerInfo: AssetOwner = getters.getAssetOwnerInfo(state, ASSET_OWNER_INFO_API);

    const ASSET_OWNER_ASSETS_API = getters.getAssetOwnerAssetsApi(params.id);
    const assetOwnerAssets: Asset[] = getters.getAssetOwnerAssets(state, `${CONFIG.ASSETS_API}/assets`);

    const ASSET_STATS_API = getters.getAssetsStatsAPI();
    const assetStats = getters.getAssetsStats(state, ASSET_STATS_API);
    const assetOwnerStats = assetOwnerAssets.reduce((acc, asset) => {
        const assetStat = assetStats.find((stat) => stat.id === asset.id);

        return [...acc, { ...assetStat, ...asset }];
    }, []);

    const LIQUIDATION_APIS = assetOwnerAssets.map(({ id }) => getters.getLiquidationAPI(id));
    const liquidationIds = LIQUIDATION_APIS
        .map((api) => getLiquidation(state, api))
        .filter((liquidation) => liquidation?.id)
        .map((l) => {
            const asset = assetOwnerAssets.find((a) => `/api/assets/${a.id}` === l.asset);

            return {
                ...(omit('id', l)),
                name: asset.name,
                id: asset.id,
                amount: asset.status === ASSET_STATUS.Liquidated ? 0 : l.amount
            }
        })
        .filter(Boolean);
    const [deleteDialog, setDeleteDialog] = useModal();
    const [editDialog, setEditDialog] = useModal();

    return (
        <PageLoad
            endpoints={[ASSET_OWNER_INFO_API, ASSET_OWNER_ASSETS_API, ASSET_STATS_API]}
            getters={[ASSET_OWNER_INFO_API, ASSET_OWNER_ASSETS_API, ASSET_STATS_API]}
        >
            <Container maxWidth="lg">
                <Box py={4} display="flex" justifyContent="space-between">
                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">
                            <TranslatedMessage id="translated.asset.owner.details.title" />
                        </Typography>
                    </Box>
                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="flex-end">
                        {!assetOwnerAssets.length && (
                            <Button
                                color='primary'
                                id='delete'
                                onClick={setDeleteDialog}
                                variant='outlined'
                                sx={{ ml: 2 }}
                            >
                                <TranslatedMessage id="translate.asset.owner.delete" />
                            </Button>
                        )}
                    </Box>
                </Box>

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <PaperBox>
                            <Box p={2} display="flex" justifyContent="space-between">
                                <Typography variant="h5" gutterBottom>
                                    <TranslatedMessage id="translate.asset.owner.details.contact-information" />
                                </Typography>

                                <Button variant="outlined" color="primary" onClick={setEditDialog}>
                                    <TranslatedMessage id="translate.edit" />
                                </Button>
                            </Box>

                            <List>
                                {Object.keys(contactInfoViewOrder).map((key, i) => (
                                    <InfoBoxRow
                                        key={key}
                                        divider={i < Object.keys(contactInfoViewOrder).length - 1}
                                        property={intl.formatMessage({ id: contactInfoViewOrder[key] })}
                                        value={formatValue[key]
                                            ? formatValue[key](assetOwnerInfo[key])
                                            : assetOwnerInfo[key]
                                        }
                                    />
                                ))}
                            </List>
                        </PaperBox>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={4}>
                            <PaperBox>
                                <Box p={2} display="flex" justifyContent="space-between">
                                    <Typography variant="h5" gutterBottom>
                                        <TranslatedMessage id="translate.asset.owner.details.balances" />
                                    </Typography>
                                </Box>

                                <List>
                                    <InfoBoxRow
                                        divider={true}
                                        property={intl.formatMessage({ id: 'translate.raised.funds' })}
                                        value={
                                            <Link onClick={() => setRaisedFundsDialog(true)}>
                                                {formatCurrency(
                                                    assetOwnerStats.reduce((acc, a) => acc + raisedFunds(a), 0)
                                                )}
                                            </Link>
                                        }
                                    />

                                    {
                                        assetOwnerAssets.length
                                            ? (
                                                <PageLoad endpoints={LIQUIDATION_APIS} getters={LIQUIDATION_APIS}>
                                                    <InfoBoxRow
                                                        divider={false}
                                                        property={intl.formatMessage({ id: 'translate.funds.in.liquidations' })}
                                                        value={
                                                            <Link onClick={() => setFundsInLiquidationDialog(true)}>
                                                                {formatCurrency(
                                                                    liquidationIds.reduce((acc, l) => acc + parseInt(l.amount), 0)
                                                                )}
                                                            </Link>
                                                        }
                                                    />
                                                </PageLoad>
                                            )
                                            : (
                                                <InfoBoxRow
                                                    divider={false}
                                                    property={intl.formatMessage({ id: 'translate.funds.in.liquidations' })}
                                                    value={formatCurrency(0)}
                                                />
                                            )
                                    }
                                </List>
                            </PaperBox>

                            <PaperBox>
                                <Box p={2} display="flex" justifyContent="space-between">
                                    <Typography variant="h5">
                                        <TranslatedMessage id="translate.asset.owner.details.assets" />
                                    </Typography>
                                </Box>

                                {assetOwnerAssets.length
                                    ? (
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((cell) => (
                                                        <TableCell key={cell.key}>
                                                            <TranslatedMessage id={cell.value} />
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {assetOwnerAssets.map((asset) => (
                                                    <TableRow key={asset.id}>
                                                        <TableCell>
                                                            <Link
                                                                onClick={() => history.push(getRoute(ROUTES.ASSET, { id: asset.id }))}
                                                            >
                                                                {asset.name}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TranslatedMessage id={asset.class} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TranslatedMessage id={asset.status} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                                }
                                            </TableBody>
                                        </Table>
                                    )

                                    : (
                                        <Box
                                            display="flex"
                                            padding={2}
                                            justifyContent="center"
                                            alignItems="center"
                                            flexDirection="column"
                                            data-e2e="empty-transactions-history"
                                        >
                                            <Typography variant="subtitle1" color="textSecondary">
                                                <TranslatedMessage id="translate.empty.assets-list" />
                                            </Typography>
                                        </Box>
                                    )}
                            </PaperBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            <DialogRaisedFunds
                assets={assetOwnerStats}
                open={raisedFundsDialog}
                setClose={() => setRaisedFundsDialog(false)}
            />

            <DialogFundsForLiquidation
                liquidations={liquidationIds}
                open={fundsInLiquidationDialog}
                setClose={() => setFundsInLiquidationDialog(false)}
            />
            <DialogDeleteAssetOwner open={deleteDialog} setClose={setDeleteDialog} id={params.id} />
            <DialogEditAssetOwner open={editDialog} setClose={setEditDialog} assetOwnerInfo={assetOwnerInfo} />
        </PageLoad>
    )
}
