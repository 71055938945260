import CONFIG from '../../config';

export const getAssetsAPI = () => `${CONFIG.ASSETS_API}/assets`;
export const getAssets = (state, endpoint) => {
    const assets = state[endpoint]?.GET?.response || [];
    return assets;
}

export const getAssetAPI = (id) => `${CONFIG.ASSETS_API}/assets/${id}`;
export const updateDraftAssetApi = (draftId) => `${CONFIG.ASSETS_API}/asset_drafts/${draftId}`;
export const submitNewDraftAssetApi = () => `${CONFIG.ASSETS_API}/asset_drafts`;
export const submitDraftAssetApi = (assetId) => `${CONFIG.ASSETS_API}/assets/${assetId}`;
export const submitAssetApi = () => `${CONFIG.ASSETS_API}/assets`;
export const getAsset = (state, endpoint) => {
    const asset = state[endpoint]?.GET?.response || {};
    return asset;
}

export const updateAssetStatusApi = (assetId) => `${CONFIG.ASSETS_API}/status/update/${assetId}`;

export const getUserAssets = (state, endpoint) => {
    const userAssets = state[endpoint]?.GET?.response?.data || { assets: [] };
    return userAssets;
}

export const getAssetsStatsAPI = () => `${CONFIG.TOKENIZE_API}/assetsStats`;
export const getAssetsStats = (state, endpoint) => {
    const assetsStats = state[endpoint]?.GET?.response.data || [];
    return assetsStats
}

export const getUserAssetAPI = (assetId) => `${CONFIG.TOKENIZE_API}/assets/${assetId}`;
export const getUserAsset = (state, endpoint) => {
    const userAssetStats = state[endpoint]?.GET?.response?.data || {};
    return userAssetStats
}

export const getUserAssetStatsAPI = (assetId) => `${CONFIG.TOKENIZE_API}/assets/${assetId}/stats`;
export const getUserAssetStats = (state, endpoint) => {
    const userAssetStats = state[endpoint]?.GET?.response?.data || {};
    return userAssetStats
}

export const getTransactionsAPI = () => `${CONFIG.ORDERS_API}/transactions/history`;
export const getTransactions = (state, endpoint) => {
    const transactions: Transaction[] = state[endpoint]?.GET?.response || [];
    return transactions;
}

export const submitLiquidationApi = () => `${CONFIG.ASSETS_API}/liquidate`;

export const getLiquidationAPI = (assetId) => `${CONFIG.ASSETS_API}/liquidate/${assetId}`;
export const getLiquidation = (state, endpoint) => {
    return state[endpoint]?.GET?.response;
}

export const getLiquidationsAPI = () => `${CONFIG.ASSETS_API}/liquidations`;
export const getLiquidations = (state, endpoint) => {
    return state[endpoint]?.GET?.response;
}

export const getAssetOwnersApi = (): string => `${CONFIG.ASSETS_API}/asset_owners`;
export const getAssetOwners = (state, endpoint: string) => state[endpoint]?.GET?.response || [];

export const getAssetOwnerInfoApi = (id: string): string => `${CONFIG.ASSETS_API}/asset_owners/${id}`;
export const getAssetOwnerInfo = (state, endpoint: string) => state[endpoint]?.GET?.response || {};

export const getAssetOwnerAssetsApi = (owner: string): string => `${CONFIG.ASSETS_API}/assets?owner=${owner}`;
export const getAssetOwnerAssets = (state, endpoint) => state[endpoint]?.GET?.response || [];

export const getClientsApi = (): string => `${CONFIG.CLIENTS_API}/clients`;
export const getClients = (state, endpoint) => state[endpoint]?.GET?.response || [];

export const getClientDetailsApi = (id): string => `${CONFIG.CLIENTS_API}/clients/${id}`;
export const getClientDetails = (state, endpoint) => state[endpoint]?.GET?.response || {};

export const getClientBalanceApi = (id, currency): string => `${CONFIG.TOKENIZE_API}/userAssets/${id}/${currency}`;
export const getClientBalance = (state, endpoint) => state[endpoint]?.GET?.response || {};

export const getTransactionDetailsApi = (id): string => `${CONFIG.ORDERS_API}/transactions/${id}`;
export const getTransactionDetails = (state, endpoint) => state[endpoint]?.GET?.response || {};

export const getWithdrawalsApi = (): string => `${CONFIG.ORDERS_API}/withdrawals`;
export const getWithdrawals = (state, endpoint) => state[endpoint]?.GET?.response || [];

export const getWithdrawalDetailsApi = (id: string): string => `${CONFIG.ORDERS_API}/withdrawals/${id}`;
export const getWithdrawalDetails = (state, endpoint) => state[endpoint]?.GET?.response || {};

export const getTokenDetailsApi = (): string => `${CONFIG.ASSETS_API}/token_details`;
export const getTokenDetails = (state, endpoint) => state[endpoint]?.GET?.response || [];

export const completeWithdrawalApi = (id: string) => `${CONFIG.ORDERS_API}/withdrawals/${id}/complete`;

export const getFAEnabledAPI = () => `${CONFIG.LOGIN_API}/2fa/enabled`;
export const getFAEnabled = (state, endpoint) => {
    const fa = state[endpoint]?.GET?.response || [];
    return fa;
}

export const getCreditCardsApi = (id) => `${CONFIG.CREDIT_CARD_API}/cards?status=2&clientUuid=${id}`;
export const getCreditCards = (state, endpoint) => state[endpoint]?.GET?.response || [];

export const getBankAccountsApi = (id) => `${CONFIG.CREDIT_CARD_API}/bank_accounts?clientUuid=${id}`;
export const getBankAccounts = (state, endpoint) => state[endpoint]?.GET?.response || [];

export const getBankAccountDetailsApi = (id) => `${CONFIG.CREDIT_CARD_API}/bank_account_addresses/${id}`;
export const getBankAccountDetails = (state, endpoint) => state[endpoint]?.GET?.response || {};

export const getAssetLogsAPI = () => `${CONFIG.LOGS_API}/asset_logs`
export const getAssetLogs = (state, endpoint) => {
    const fa = state[endpoint]?.GET?.response || [];
    return fa;
}

export const getClientLogsAPI = () => `${CONFIG.LOGS_API}/client_logs`
export const getClientLogs = (state, endpoint) => {
    const fa = state[endpoint]?.GET?.response || [];
    return fa;
}

export const getAssetOwnersLogsApi = () => `${CONFIG.LOGS_API}/asset_owner_logs`;
export const getAssetOwnersLogs = (state, endpoint) => state[endpoint]?.GET?.response || [];

export const getStaffApi = (): string => `${CONFIG.ADMIN_API}/staff`;
export const getStaff = (state, endpoint) => state[endpoint]?.GET?.response || [];
