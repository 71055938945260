import { FC, useEffect } from 'react';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
    open: boolean;
    onClose: (event?) => void;
    onSubmit?: (event?) => void;
    clearDialog?: () => void;
    title?: string;
    topClose?: boolean;
    onCloseBtnLabel?: string;
    onSubmitBtnLabel?: string;
    maxWidth?: any;
};

const WBDialogWrapper: FC<Props> = (
    {
        open = false,
        onClose,
        onSubmit,
        onCloseBtnLabel = 'translate.button.close',
        onSubmitBtnLabel = 'translate.button.submit',
        clearDialog,
        title,
        children,
        maxWidth = 'sm',
    }
) => {

    useEffect(() => {
        if (clearDialog && !open) {
            clearDialog()
        }
    }, [open]);

    return (
        <Dialog onClose={onClose} open={open} maxWidth={maxWidth} fullWidth>
            {title && (
                <DialogTitle>
                    <TranslatedMessage id={title} />
                </DialogTitle>
            )}
            {children}

            <Box p={3} display="flex" alignContent="center" justifyContent="flex-end">
                <Button disableElevation onClick={onClose} color="inherit" data-e2e="btn-close">
                    <TranslatedMessage id={onCloseBtnLabel} />
                </Button>
                {onSubmit && (
                    <Button
                        onClick={onSubmit}
                        disableElevation
                        color="primary"
                        data-e2e="btn-submit"
                        variant="contained"
                        sx={{ ml: 2 }}
                    >
                        <TranslatedMessage id={onSubmitBtnLabel} />
                    </Button>
                )}
            </Box>
        </Dialog>
    );
};

export default WBDialogWrapper;
