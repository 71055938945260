import { FC, Fragment, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import Avatar from '@mui/material/Avatar';
import ImageListItem from '@mui/material/ImageListItem';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import CONFIG from '../../config/index';

export function getImage(image) {
    return image
        && image.contentUrl
        && `${CONFIG.ASSETS}${image?.contentUrl}`;
}

const gridProps = (number) => {
    if (number <= 1) {
        return {
            cols: 1,
        }
    }

    if (number <= 4) {
        return {
            cols: 2,
        }
    }

    if (number <= 9) {
        return {
            cols: 3,
        }
    }

    return {
        cols: 4
    }
}

type Image = {
    alt: string;
    contentUrl: string;
    priority: number,
}

const Gallery: FC<{ open: boolean; onClose: () => void; images: Image[] }> = ({ onClose, open, images = [] }) => {
    const [selected, setSelected] = useState(null);

    const handlePrev = () => {
        const prev: Image = images.find(img => img.priority === selected.priority - 1);
        setSelected(prev);
    }

    const handleNext = () => {
        const next: Image = images.find(img => img.priority === selected.priority + 1);
        setSelected(next);
    }

    const handleClose = () => {
        setSelected(null);
        onClose();
    }
    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xl" fullWidth sx={{ width: '100%', height: '100%' }}>
            <Box
                display="flex"
                justifyContent={selected ? 'space-between' : 'flex-end'}
                alignItems="center"
                flexGrow={1}
                p={3}
                pb={2}
            >
                {selected && (
                    <Box
                        sx={{ cursor: 'pointer' }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => setSelected(null)}
                    >
                        <IconButton size="small">
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                        <Box ml={1} fontWeight="fontWeightMedium" fontSize="h6.fontSize" component="span">
                            Gallery
                        </Box>
                    </Box>
                )}
                <IconButton
                    size="small"
                    onClick={handleClose}
                >
                    <CloseOutlinedIcon />
                </IconButton>
            </Box>
            {!selected && (
                <Box px={5} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <ImageList sx={{ pb: 3, height: '79vh', width: '100%' }} {...gridProps(images.length)} gap={20}>
                        {images.map((item) => (
                            <ImageListItem
                                sx={{ overflow: 'hidden' }}
                                key={item.priority}
                                onClick={() => images.length != 1 && setSelected(item)}
                            >
                                <img
                                    style={{ cursor: 'pointer', height: '100%', objectFit: 'contain' }}
                                    src={getImage(item)}
                                    srcSet={getImage(item)}
                                    alt={item.alt}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            )}
            {selected && (
                <Fragment>
                    <Box px={5} display="flex" justifyContent="space-between" alignItems="center" width="100%"
                         height="85vh">
                        <div style={{ minWidth: '48px' }}>
                            {selected.priority !== 1 && (
                                <IconButton onClick={handlePrev} sx={{ p: 0, mr: 1 }}>
                                    <Avatar style={{
                                        background: 'transparent',
                                        color: '#0000008A',
                                        border: '1px solid #0000008A'
                                    }}>
                                        <KeyboardArrowLeftOutlinedIcon />
                                    </Avatar>
                                </IconButton>
                            )}
                        </div>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            width="100%"
                            height="100%"
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative'
                                }}
                            >
                                <img
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        bottom: 0,
                                        right: 0,
                                        margin: '0 auto',
                                        maxWidth: '100%',
                                    }}
                                    src={getImage(selected)}
                                    srcSet={getImage(selected)}
                                    alt={selected.alt}
                                />
                            </div>
                            <Box p={5} display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="subtitle1">{selected.priority}/{images.length}</Typography>
                            </Box>
                        </Box>
                        <div style={{ minWidth: '48px' }}>
                            {selected.priority !== images.length && (
                                <IconButton
                                    onClick={handleNext}
                                    sx={{ p: 0, ml: 1 }}
                                >
                                    <Avatar style={{
                                        background: 'transparent',
                                        color: '#0000008A',
                                        border: '1px solid #0000008A'
                                    }}>
                                        <KeyboardArrowRightOutlinedIcon />
                                    </Avatar>
                                </IconButton>
                            )}
                        </div>
                    </Box>
                </Fragment>
            )}
        </Dialog>
    );
}

export default Gallery;
