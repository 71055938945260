import { omit } from '@wealthberry/common-base/src/utils';

export const UPDATE_FORM = 'UPDATE_FORM';

export function updateFormReducer(state, action) {
    if (action.type === UPDATE_FORM) {
        const formIds = Object.keys(action.payload);

        const newState = formIds.reduce((acc, formId) => {
            const form = state[formId];
            const formFields = Object.keys(action.payload[formId]);

            const appliedChanges = formFields.reduce(
                (acc, fieldKey) => {
                    // purposefully deleting key
                    if (action.payload[formId][fieldKey] === null) {
                        return omit(fieldKey, acc);
                    }

                    return {
                        ...acc,
                        [fieldKey]: {
                            ...acc[fieldKey],
                            ...action.payload[formId][fieldKey]
                        }
                    }
                },
                form
            );

            return {
                ...acc,
                [formId]: appliedChanges
            }
        }, {});

        return {
            ...state,
            ...newState
        }
    }

    if (action.type === 'RESET_FORM') {
        return {
            ...state,
            ...action.payload
        }
    }
}
