import { FC, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ROUTES } from '../../constants';
import { getTokens, setTokens } from '@wealthberry/common-base/src/utils/localstorage';
import { NavigationDrawer } from './navigation-drawer';
import { setItem } from '@wealthberry/common-base/src/utils/localstorage';
import { WBLogo } from './logo-svg';
import { logoutWithGooglePromise } from '../../../pages/authentication/utils';
import { useStyles } from './styles';

export const Navigation: FC = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { token } = getTokens();

    const logout = () => {
        logoutWithGooglePromise()
            .then(() => {
                setTokens(null);
                setItem('wb-client', null);
                history.push(ROUTES.LOGIN);
            })
            .catch(() => {
                setTokens(null);
                setItem('wb-client', null);
                history.push(ROUTES.LOGIN);
            })
    }

    let menu = (
        <Fragment>
            <Toolbar className={classes.toolbar} style={{ paddingLeft: 0 }}>
                <Box display="flex" width="100%">
                    <Box display="flex" alignItems="center">
                        <Box
                            mr={2}
                            onClick={() => history.push(ROUTES.ADMIN)}
                            style={{ cursor: 'pointer' }}
                        >
                            <WBLogo />
                        </Box>
                    </Box>
                </Box>
                <div onClick={logout}>
                    <IconButton color="inherit">
                        <ExitToAppIcon />
                    </IconButton>
                </div>
            </Toolbar>
        </Fragment>
    );

    // TEMPORARY
    if (!token) {
        menu = (
            <Toolbar className={classes.toolbar}>
                <Box
                    justifyContent="center"
                    display="flex"
                    width="100%"
                    style={{ cursor: 'pointer' }}
                >
                    <WBLogo />
                </Box>
            </Toolbar>
        );
    }

    return (
        <Fragment>
            <AppBar
                position="absolute"
                sx={{
                    background: '#fff',
                    color: '#000',
                    height: '80px',
                    borderBottom: '1px solid #c3c1c3',
                    zIndex: 1201,
                }}
                elevation={0}
            >
                {menu}
            </AppBar>

            {token && (
                <NavigationDrawer />
            )}
            <main className={classes.content}>
                {props.children}
            </main>
        </Fragment>
    );
}
