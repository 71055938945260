import { filters } from '../../common/constants';
import { formatCurrencySymbol } from '@wealthberry/common-base/src/utils';

export const FIELDS = {
    STATUS: 'status',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    AMOUNT_FROM: 'baseAmount[gte]',
    AMOUNT_TO: 'baseAmount[lte]',
    COUNTRY: 'country',
    AGENT: 'agent',
    AGENT_ID: 'agent_id',
    TOKEN_SYMBOL: 'tokenSymbol',
    TYPE: 'type',
}
export const immutableFields = {
    [FIELDS.STATUS]: {
        id: FIELDS.STATUS,
        label: 'translate.status',
        type: 'autocomplete',
        options: [{
            'name': 'Completed',
            'value': 'done'
        }, {
            'name': 'Failed',
            'value': 'failed'
        }],
        getOptionLabel: (option) => option.name,
    },
    [FIELDS.FIRST_NAME]: {
        id: FIELDS.FIRST_NAME,
        label: 'translate.first.and.middle.name',
        type: 'text',
    },
    [FIELDS.LAST_NAME]: {
        id: FIELDS.LAST_NAME,
        label: 'translate.last.name',
        type: 'text',
    },
    [FIELDS.AMOUNT_FROM]: {
        id: FIELDS.AMOUNT_FROM,
        label: 'translate.amount.from',
        type: 'text',
        xs: 6,
    },
    [FIELDS.AMOUNT_TO]: {
        id: FIELDS.AMOUNT_TO,
        label: 'translate.amount.to',
        type: 'text',
        xs: 6,
    },
    [FIELDS.COUNTRY]: {
        id: FIELDS.COUNTRY,
        label: 'translate.country',
        type: 'autocomplete',
    },
    [FIELDS.AGENT]: {
        id: FIELDS.AGENT,
        label: 'translate.agent',
        value: 'Stripe',
        inputProps: {
            readOnly: true,
        }
    },
    [FIELDS.AGENT_ID]: {
        id: FIELDS.AGENT_ID,
        label: 'translate.agent.id',
        type: 'text',
    }
}

export const formInitial = {
    ...filters,
    dialog: {
        [FIELDS.STATUS]: {
            error: false,
            value: null,
        },
        [FIELDS.FIRST_NAME]: {
            error: false,
            value: '',
        },
        [FIELDS.LAST_NAME]: {
            error: false,
            value: '',
        },
        [FIELDS.AMOUNT_FROM]: {
            error: false,
            value: '',
            _label: `From ${formatCurrencySymbol()}{value}`,
        },
        [FIELDS.AMOUNT_TO]: {
            error: false,
            value: '',
            _label: `to ${formatCurrencySymbol()}{value}`,
        },
        [FIELDS.COUNTRY]: {
            error: false,
            value: null,
        },
        [FIELDS.AGENT]: {
            error: false,
            value: 'Stripe',
        },
        [FIELDS.AGENT_ID]: {
            error: false,
            value: '',
        }
    },
    selected: []
}
