import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import { formatCountry, formatDateTimeTechnical } from '../../common/utils';
import { getRoute } from '@wealthberry/common-base/src/utils';
import { ROUTES } from '../../common/constants';
import { useHistory } from 'react-router-dom';

export const Row: FC<{ item }> = ({ item }) => {
    const history = useHistory();
    return (
        <TableRow key={item.id} hover style={{ cursor: 'pointer' }}>
            <TableCell component="th" scope="row">
                <Link onClick={() => history.push(getRoute(ROUTES.INVESTOR_DETAILS, { id: item.id }))}>
                    {item.email}
                </Link>
            </TableCell>
            <TableCell>
                {item.firstName} {item.lastName}
            </TableCell>
            <TableCell>
                {formatCountry(item.clientAddress?.country)}
            </TableCell>
            <TableCell>
                {formatDateTimeTechnical(item.createdAt)}
            </TableCell>
        </TableRow>
    )
}
