import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import List from '@mui/material/List';
import { formatDateTimeTechnical } from '../../common/utils';
import { formatCurrency, formatDateTechnical, formatNumber } from '@wealthberry/common-base/src/utils';
import { DescriptionDialog } from './dialogs/description-dialog';
import { InvestmentDocumentsDialog } from './dialogs/investment-documnets-dialog';
import { InvestmentDocumentsEditDialog } from './dialogs/investment-documnets-edit-dialog';
import { LocationDialog } from './dialogs/location-dialog';
import { LocationEditDialog } from './dialogs/location-edit-dialog';
import { AssetCharacteristicsDialog } from './dialogs/asset-characteristics-dialog';
import { investmentTypeMap } from './constants';
import Gallery from '../../common/components/gallery';
import { InfoBoxRow } from '../../common/components/info-box-row';
import { PaperBox } from '../../common/components/paper-box';
import WBLabel from '@wealthberry/common-base/src/components/wb-label';
import { STATUS_COLOR_MAP } from '../../common/constants';
import { TranslatedMessage, useIntlWithDefaultMessage } from '@wealthberry/common-base/src/packages/translated-message';
import IconButton from '@mui/material/IconButton';
import UploadImage from '../assets-list/components/upload-image';
import { EditGalleryDialog } from './dialogs/edit-gallery-dialog';

export default function InfoBox({ asset, title, list }) {
    const intl = useIntlWithDefaultMessage();
    const [dialogState, setDialogState] = useState(null);


    const mapper = {
        expectedReturn: () => {
            //Maximum expected return = (Latest valuation - Initial valuation) / Initial valuation X 100
            const valuation = asset.assetValuations?.length ? parseFloat(asset?.assetValuations[0].amount) : 0;
            const maxExpectedReturn = ((valuation - asset.assetValuation) / asset.assetValuation * 100).toFixed(2)
            return asset.assetValuations?.length ?
                maxExpectedReturn + '%' :
                asset.targetReturn + '%';
        },
        category: () => {
            if (asset.realEstate) {
                return <TranslatedMessage id={asset.realEstate?.category} />;
            }

            return asset.fineart?.fineartCategory;
        },
        investmentType: () => {
            return investmentTypeMap[asset.investmentType];
        },
        class: () => intl.formatMessage({ id: asset?.class || 'translate.missing.translation' }),
        status: () => STATUS_COLOR_MAP[asset.status]
            ? <WBLabel text={asset.status} variant="light" color={STATUS_COLOR_MAP[asset.status]} size="large"
                       density={0} />
            : asset.status
        ,
        assetOwner: () => {
            return asset?.assetOwner?.companyName
        }
    }
    const renderValue = ({ type, value, short, format, edit }) => {
        switch (type) {
            case 'link':
                if (value === 'assetCharacteristicsDialog' && asset?.customFields?.length === 0) {
                    return '-';
                }

                if (value === 'investmentDocumentsDialog' && asset?.assetDocuments?.length === 0) {
                    return '-';
                }

                return (
                    <Fragment>
                        {edit && (
                            <IconButton size="small" onClick={() => setDialogState({ value, edit })}>
                                <ModeEditOutlinedIcon />
                            </IconButton>
                        )}
                        <IconButton size="small" onClick={() => setDialogState({ value })}>
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </Fragment>
                );
            case 'date':
                return format === 'technical' ? formatDateTimeTechnical(asset[value]) : formatDateTechnical(asset[value], short)
            case 'custom':
                return mapper[value]();
            case 'nested':
                if (asset?.realEstate) {
                    return asset.realEstate[value] ? <TranslatedMessage id={asset.realEstate[value]} /> : '-';
                }
                if (asset?.fineart) {
                    return asset.fineart[value] ? <TranslatedMessage id={asset.fineart[value]} /> : '-';
                }
                return '-';
            case 'currency':
                return formatCurrency(asset[value]);
            case 'number':
                return formatNumber(asset[value]);
            case 'percentage':
                return `${asset[value]}%`;
            default:
                return asset[value];
        }
    }

    const getLinkDialog = () => {
        switch (dialogState?.value) {
            case 'descriptionDialog':
                return <DescriptionDialog
                    open={true}
                    description={asset.description}
                    onClose={() => setDialogState(null)}
                />
            case 'investmentDocumentsDialog':
                if (dialogState.edit) {
                    return <InvestmentDocumentsEditDialog
                        open={true}
                        documents={asset.assetDocuments}
                        onClose={() => setDialogState(null)}
                    />
                }
                return <InvestmentDocumentsDialog
                    open={true}
                    documents={asset.assetDocuments}
                    onClose={() => setDialogState(null)}
                />
            case 'locationDialog':
                if (dialogState.edit) {
                    return <LocationEditDialog
                        open={true}
                        location={asset.realEstate?.googleMapsInfo}
                        onClose={() => setDialogState(null)}
                    />
                }

                return <LocationDialog
                    open={true}
                    location={asset.realEstate?.googleMapsInfo}
                    onClose={() => setDialogState(null)}
                />
            case 'assetCharacteristicsDialog':
                return <AssetCharacteristicsDialog
                    open={true}
                    asset={asset}
                    onClose={() => setDialogState(null)}
                />
            case 'authorDescriptionDialog':
                return <DescriptionDialog
                    open={true}
                    description={asset.fineart.authorDescription}
                    onClose={() => setDialogState(null)}
                />
            case 'gallery':
                if (dialogState?.edit) {
                    return <EditGalleryDialog
                        images={asset.images}
                        open={true}
                        onClose={() => setDialogState(null)}
                    />
                }
                return <Gallery
                    open={true}
                    images={asset.images}
                    onClose={() => setDialogState(null)}
                />
            case 'authorGallery':
                return <Gallery
                    open={true}
                    images={asset.fineart?.authorImages}
                    onClose={() => setDialogState(null)}
                />
        }
    }

    return (
        <PaperBox>
            {getLinkDialog()}
            <Box p={2} display='flex' justifyContent='space-between'>
                <Typography variant='h5' gutterBottom>
                    <TranslatedMessage id={title} />
                </Typography>
            </Box>
            <List>
                {list.map(({ key, value, type, short, format, edit }, index) => (
                    <InfoBoxRow
                        key={key}
                        divider={index < list.length - 1}
                        property={key}
                        value={renderValue({ value, type, short, format, edit })}
                    />
                ))}
            </List>
        </PaperBox>
    )
}

