import { FIELDS } from './reducer';

export const transactionFiltration = ({ url, data }) => {
    if (data[FIELDS.FIRST_NAME]) {
        url += `&transactionClientData.firstName=${data[FIELDS.FIRST_NAME]}`;
    }

    if (data[FIELDS.LAST_NAME]) {
        url += `&transactionClientData.lastName=${data[FIELDS.LAST_NAME]}`;
    }

    if (data[FIELDS.STATUS]) {
        url += `&status=${data[FIELDS.STATUS].value}`;
    }

    if (data[FIELDS.COUNTRY]) {
        url += `&transactionClientData.country=${data[FIELDS.COUNTRY].code}`;
    }

    if (data[FIELDS.AMOUNT_FROM]) {
        url += `&amount[gte]=${data[FIELDS.AMOUNT_FROM]}`;
    }

    if (data[FIELDS.AMOUNT_TO]) {
        url += `&amount[lte]=${data[FIELDS.AMOUNT_TO]}`;
    }

    if (data[FIELDS.TOKEN_SYMBOL]) {
        url += `&baseSymbol=${data[FIELDS.TOKEN_SYMBOL].value}`;
    }

    if (data[FIELDS.TYPE]) {
        url += `&type=${data[FIELDS.TYPE].value}`
    }

    if (data[FIELDS.AGENT_ID]) {
        url += `&transactionCCData.paymentIntent=${data[FIELDS.AGENT_ID]}`
    }

    return url;
}
