import React, { Fragment, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Image from './image';
import ImagePreview from '../components/image-preview';
import { fileUpload } from '../utils';
import { removeFile, updateFileOrder } from '../../../common/reducers/save-file-location';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
    input: {
        display: 'none',
    }
}));

const EmptyTag: React.FC<any> = ({ max }) => (
    <Grid
        item
        sx={{ height: '214px', }}
        xs={max !== 1 ? 4 : 12}
    >
        <Box
            sx={{
                background: '#0000000A',
                height: '100%',
                width: '100%',
            }}
        />
    </Grid>
);

const UploadImage: React.FC<{
    context: any;
    storeLocation: string;
    max?: number,
    minHeight?: string;
    error?: boolean;
}> = ({ context, storeLocation, max = 12, minHeight = '300px', error = false }) => {
    const classes = useStyles();
    const [state, dispatch] = React.useContext(context);
    const selectedFiles = state.fileLocation[storeLocation] || [];

    const handleCapture = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        Promise.all(
            Array.from(target.files).map((file: File, idx) => fileUpload(file, storeLocation, idx + 1, dispatch))
        );
    };

    const deleteFile = (contentUrl) => {
        dispatch(removeFile({ id: storeLocation, contentUrl }));
    }

    const updateOrder = (contentUrl, order) => {
        dispatch(updateFileOrder({ id: storeLocation, contentUrl, order }));
    }

    const makePrimary = (contentUrl) => {
        updateOrder(contentUrl, 0);
    }

    const emptyTags = () => {
        if (max === 1) {
            return 1;
        }

        if (selectedFiles.length == 0) {
            return 3;
        }
        if (selectedFiles.length < 3) {
            return 3 - selectedFiles.length;
        }
        if (selectedFiles.length <= 6) {
            return 6 - selectedFiles.length;
        }
        if (selectedFiles.length <= 9) {
            return 9 - selectedFiles.length;
        }
        if (selectedFiles.length <= 12) {
            return 12 - selectedFiles.length;
        }
    }

    const [galleryImage, setGalleryImage] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

    return (
        <div
            style={{
                border: error ? '1px solid #d32f2f' : '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                minHeight: minHeight,
                padding: '24px'
            }}
        >
            <ImagePreview
                open={Boolean(galleryImage)}
                image={galleryImage}
                onClose={() => setGalleryImage(null)}
            />

            <Grid
                container
                spacing={3}
            >
                {selectedFiles.map((fileLoc, i) => (
                    <Grid
                        key={`${fileLoc.title}${i}`}
                        item
                        data-index={i}
                        sx={{ height: '214px', }}
                        xs={max !== 1 ? 4 : 12}
                        draggable={i !== 0}
                        onDragStart={e => {
                            setFrom(Number(e.currentTarget.dataset.index))
                        }}
                        onDragOver={e => {
                            e.preventDefault();
                            const dragIndex = e.currentTarget.dataset.index === '0' ? 1 : e.currentTarget.dataset.index;
                            setTo(Number(dragIndex));
                        }}
                        onDragEnd={() => {
                            updateOrder(selectedFiles[from].contentUrl, to);
                            setFrom(null);
                            setTo(null);
                        }}
                    >
                        <Image
                            key={fileLoc.name + i}
                            file={fileLoc.contentUrl}
                            index={i}
                            isDragged={from}
                            deleteFile={deleteFile}
                            makePrimary={makePrimary}
                            setGalleryImage={setGalleryImage}
                        />
                    </Grid>
                ))}

                {selectedFiles.length < max && (
                    <Fragment>
                        {
                            [...Array(emptyTags())].map((x, i) => (<EmptyTag key={i} max={max} />))
                        }
                    </Fragment>
                )}
                <Grid item xs={12}>
                    <input
                        accept="image/*"
                        className={classes.input}
                        disabled={selectedFiles.length === max}
                        id={storeLocation}
                        multiple={max !== 1}
                        onChange={handleCapture}
                        type="file"
                    />
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        {error && (
                            <Typography color="error" variant='body2'>
                                <TranslatedMessage id='translate.validation.message.image_min_count' />
                            </Typography>
                        )}
                        <span/>
                        <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                            <label htmlFor={storeLocation}>
                                <Box
                                    sx={
                                        selectedFiles.length >= max
                                            ? { color: 'text.disabled' }
                                            : { color: 'text.inherit', cursor: 'pointer' }
                                    }
                                    bgcolor="#E0E0E0"
                                    px={2}
                                    py={1}
                                    borderRadius={1}
                                    fontSize="body2.fontSize"
                                >
                                    <TranslatedMessage id="translate.select.files" />
                                </Box>
                            </label>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default UploadImage;
