import React, { useContext, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ApiContext } from '@wealthberry/common-base/src/api/index';
import { useApiCall } from '../api/hook';
import { LoaderContext } from '@wealthberry/common-base/src/loader/context';

interface Props {
    endpoints: string[];
    getters?: string[];
    showChildren?: boolean;
}

export const PartialPageLoad: React.FC<Props> = (
    {
        endpoints = [],
        getters = [],
        showChildren = true,
        children
    }) => {
    const [state] = useContext(ApiContext);
    const loader = useContext(LoaderContext);
    const api = useApiCall();

    useEffect(() => {

        getters.map((endpoint) => {
            try {
                const url = new URL(endpoint);
                api('GET', endpoint)
            } catch (e) {
                // invalid url passed, do nothing
                // happens because we don't want to change size of dependency array when having conditional api calls
            }
        })
    }, getters);

    const loading = endpoints.reduce(
        (acc, api: string) => acc || (state[api] ? Object.values(state[api]).reduce((a, method) => a || method.loading, false) : false),
        false
    );

    return (
        <div style={{ position: 'relative' }}>
            {showChildren && children}
            <Backdrop
                sx={{
                    zIndex: 99999999,
                    color: '#fff',
                    position: 'absolute'
                }}
                open={loading && !loader.parentLoaderVisible}
                transitionDuration={1000}
            >
                <CircularProgress />
            </Backdrop>
        </div>
    );
}
