import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { immutableFieldsLiquidation, initialStateStepTwo, } from './constants';
import { LiquidationDialog } from './liquidation-dialog';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

interface Props {
    id: string;
    onClose: (event?) => void;
    open: boolean;
    liquidationInfo: any;
    currency: string;
}

export const LiquidationDialogStepTwo: FC<Props> = ({ onClose, liquidationInfo, id, currency, ...rest }) => {
    const getBody = () => {
        return {
            'asset': `/api/assets/${id}`,
            'amount': liquidationInfo.amount,
            'signature': 'hash1'
        };
    }

    const triggerClose = () => {
        onClose();
    }

    return (
        <LiquidationDialog
            {...rest}
            title="translate.liquidation.dialog.title.step.two"
            id={id}
            onClose={triggerClose}
            getBody={getBody}
            successMessage="The asset is liquidated and the amount is distributed."
            initialState={initialStateStepTwo}
            liquidationInfo={liquidationInfo}
            immutableFields={immutableFieldsLiquidation}
        >
            {[(
                <Grid item xs={12}>
                    <Box pb={2}>
                        <Alert severity="warning">
                            <TranslatedMessage id="translate.liquidation.dialog.alert" />
                        </Alert>
                    </Box>
                </Grid>
            ), (
                <Grid item xs={12}>
                    <Typography variant="body2" color='textSecondary'>
                        <TranslatedMessage id="translate.asset.liquidate.are.you.sure" />
                    </Typography>
                </Grid>
            )]}
        </LiquidationDialog>
    );
};
