import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { formatDateTechnical } from '@wealthberry/common-base/src/utils';
import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import { getFiltersLoadingState } from '@wealthberry/common-base/src/loader/context';
import { getAssetLogsAPI } from '../../common/api/getters';

export default function AssetsListLogs({ logs, assets, staff }: { assets: CombinedAsset[], logs, staff }) {
    const headItems = ['translate.asset.name', 'translate.event.description', 'translate.performed.by', 'translate.date'];
    const loading = getFiltersLoadingState([getAssetLogsAPI()]);
    // TODO test with mergedData once there are more logs
    // const mergedData = mergeData(logs, staff, [], 'staffId');
    return (
        <React.Fragment>
            <WBList
                loading={loading}
                emptyMessage='translate.pages.list.empty'
                headItems={headItems}
                data={logs}
                colSpan={headItems.length}
                row={(log) => (
                    <TableRow key={log.assetId}>
                        <TableCell component="th" scope="row">
                            {assets.find(asset => asset.id === log.assetId)?.name}
                        </TableCell>
                        <TableCell>
                            {log.description}
                        </TableCell>
                        <TableCell>
                            {staff.find(staff => staff.id === log.staffId)?.email}
                        </TableCell>
                        <TableCell>
                            {formatDateTechnical(log.producedAt)}
                        </TableCell>
                    </TableRow>
                )}
            />
        </React.Fragment>
    );
}
