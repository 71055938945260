import { useParams } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const AddNewRealEstateAsset = lazy(() => import('./real-estate/index'));
const AddNewFineArtAsset = lazy(() => import('./fine-art/index'));

export function AddNewAsset() {
    const { type } = useParams<{ type: string }>();

    return (
        <Suspense fallback={
            <Backdrop
                open={true}
                transitionDuration={1000}
            >
                <CircularProgress />
            </Backdrop>
        }>
            {type === 'realEstate'
                ? <AddNewRealEstateAsset />
                : <AddNewFineArtAsset />}
        </Suspense>
    );
}
