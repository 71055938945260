import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IntlProvider } from '@wealthberry/common-base/src/packages/translated-message';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { PrivateRoute } from './common/components/private-route';
import Assets from './pages/assets-list/index';
import { Navigation } from './common/components/navigation';
import { ROUTES } from './common/constants';
import { NotificationProvider } from './common/components/notification-provider';
import { getItem, getTokens, setItem } from '@wealthberry/common-base/src/utils/localstorage';
import { ApiContext, applyMiddleware, initialState, reducer } from '@wealthberry/common-base/src/api/index';
import { useApiCall } from './common/api/hook';
import Authentication from './pages/authentication';
import Asset from './pages/asset-view';
import Deposits from './pages/deposits';
import Investors from './pages/investors';
import PrimaryMarket from './pages/primary-market';
import Withdrawals from './pages/withdrawals';
import Liquidations from './pages/liquidations';
import { AddNewAsset } from './pages/assets-list/add-new-asset';
import { AssetOwner } from './pages/asset-owner';
import { AssetOwnerDetails } from './pages/asset-owner/details';
import CONFIG from '../src/config';
import { InvestorDetails } from './pages/investors/investor-details';
import { TransactionDetails } from './common/components/transaction-details';

const theme = createTheme({
    components: {
        MuiLink: {
            defaultProps: {
                sx: {
                    cursor: 'pointer'
                }
            }
        },
        MuiTableCell: {
            defaultProps: {
                sx: {
                    fontSize: '1rem',
                    letterSpacing: 0
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#4A148C',
        },
        secondary: {
            main: '#06B405',
        },
    },
    typography: {
        button: {
            textTransform: 'initial'
        },
        overline: {
            lineHeight: 1.66,
        }
    }
});

export const App: React.FC = () => {
    const [language, setLanguage] = React.useState(getItem('wb-language'))
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const enhancedDispatch = applyMiddleware(dispatch);
    const publicApi = useApiCall(true);
    const api = useApiCall();
    const messages = getItem('wb-translations') || {};

    React.useEffect(() => {
        setItem('wb-language', language);
    }, [language]);

    React.useEffect(() => {
        Promise.all([
            publicApi('GET', `${CONFIG.CLIENTS_API}/docs`),
            publicApi('GET', `${CONFIG.ORDERS_API}/docs`),
            publicApi('GET', `${CONFIG.ASSETS_API}/docs`)
        ])
            .then((res: any) => {
                const [clients, orders, assets] = res;
                window.localStorage.setItem('wb-doc', JSON.stringify({
                    [`${CONFIG.CLIENTS_API}`]: clients.paths,
                    [`${CONFIG.ORDERS_API}`]: orders.paths,
                    [`${CONFIG.ASSETS_API}`]: assets.paths
                }))
            })
            .catch(console.log)
    }, []);
    const tokens = getTokens();

    React.useEffect(() => {
        publicApi('GET', `${CONFIG.CLIENTS_API}/countries?regions=eu,gb`).then((list: any) => {
            const dictionary = list.reduce((acc, { code, name }) => ({ ...acc, [code]: name }), {});
            window.localStorage.setItem('wb-countries', JSON.stringify({ list, dictionary }))
        })
    }, [tokens.token]);

    return (
        <IntlProvider defaultLocale="en" locale={language} messages={messages[language]}>
            <ThemeProvider theme={theme}>
                <ApiContext.Provider value={[state, enhancedDispatch]}>
                    <NotificationProvider>
                        <CssBaseline />
                        <Navigation>
                            <Switch>
                                <PrivateRoute path={ROUTES.UPDATE_DRAFT_ASSET} component={AddNewAsset} />
                                <PrivateRoute path={ROUTES.ADD_NEW_ASSET} component={AddNewAsset} />
                                <PrivateRoute path={ROUTES.ASSET_OWNER_DETAILS} component={AssetOwnerDetails} />
                                <PrivateRoute path={ROUTES.ASSET} component={Asset} />
                                <PrivateRoute path={ROUTES.ASSET_OWNER} component={AssetOwner} />
                                <PrivateRoute path={ROUTES.ADMIN} component={Assets} />
                                <PrivateRoute path={ROUTES.INVESTORS} component={Investors} />
                                <PrivateRoute path={ROUTES.INVESTOR_DETAILS} component={InvestorDetails} />
                                <PrivateRoute path={ROUTES.DEPOSITS} component={Deposits} />
                                <PrivateRoute path={ROUTES.TRANSACTION_DETAILS} component={TransactionDetails} />
                                <PrivateRoute path={ROUTES.LIQUIDATIONS} component={Liquidations} />
                                <PrivateRoute path={ROUTES.PRIMARY_MARKET} component={PrimaryMarket} />
                                <PrivateRoute path={ROUTES.WITHDRAWALS} component={Withdrawals} />
                                <Route
                                    path={ROUTES.LOGIN}
                                    render={() => <Authentication language={language} setLanguage={setLanguage} />}
                                />
                            </Switch>
                        </Navigation>
                    </NotificationProvider>
                </ApiContext.Provider>
            </ThemeProvider>
        </IntlProvider>
    )
}
