import React, { FC, useCallback, useContext } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WBLabel from '@wealthberry/common-base/src/components/wb-label';
import List from '@mui/material/List';
import { InfoBoxRow } from './info-box-row';
import { PaperBox } from './paper-box';
import { PageLoad } from './page-load';
import { useHistory, useParams } from 'react-router-dom';
import {
    completeWithdrawalApi,
    getTransactionDetails,
    getTransactionDetailsApi,
    getWithdrawalDetails,
    getWithdrawalDetailsApi
} from '../api/getters';
import { ApiContext } from '@wealthberry/common-base/src/api/index';
import { formatCountry, formatDateTimeTechnical } from '../utils';
import { formatCurrency, getRoute } from'@wealthberry/common-base/src/utils';
import Button from '@mui/material/Button';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';
import { Alert, Dialog, DialogTitle } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ROUTES, TRANSACTION_TYPE } from '../constants';
import Link from '@mui/material/Link';
import { useApiCall } from '../api/hook';
import { NotificationManager } from '@wealthberry/common-base/src/notifications';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

const colorMap = {
    'translate.status.done': 'success',
    'translate.status.pending': 'warning'
}

const StatusLabel = ({ text }) => {
    return (
        <WBLabel text={text} density={0} color={colorMap[text]} variant="light" />
    )
}

const selectors: Record<string, (details) => ({ title: string, rows: any[], children?: Function })> = {
    [TRANSACTION_TYPE.Deposit]: ({ baseAmount, createdAt, details, status, history }) => {
        return {
            title: 'translate.deposit.details',
            rows: [
                { property: 'translate.amount', value: formatCurrency(parseInt(baseAmount)) },
                { property: 'translate.status', value: <StatusLabel text={status} /> },
                { property: 'translate.date', value: formatDateTimeTechnical(createdAt) },
                { property: 'translate.agent', value: details?.agent },
                { property: 'translate.agent.transaction.id', value: details?.paymentIntentId },
                { property: 'translate.card.type', value: details?.type },
                { property: 'translate.last4', value: details?.card },
                { property: 'translate.expiry.date', value: `${details?.expiryMonth}/${details?.expiryYear}` },
                {
                    property: 'translate.investor.name', value: (
                        <Link
                            data-e2e='link'
                            onClick={() => history.push(getRoute(ROUTES.INVESTOR_DETAILS, { id: details?.clientId }))}>
                            {details?.firstName} {details?.lastName}
                        </Link>
                    )
                }
            ]
        }
    },
    [TRANSACTION_TYPE.Buy]: (
        {
            assetId,
            baseAmount,
            baseSymbol,
            status,
            createdAt,
            price,
            details,
            history
        }
    ) => {
        return {
            title: 'translate.primary.market.order.details',
            rows: [
                { property: 'translate.amount', value: formatCurrency(parseInt(baseAmount) * parseInt(price)) },
                { property: 'translate.status', value: <StatusLabel text={status} /> },
                { property: 'translate.date', value: formatDateTimeTechnical(createdAt) },
                {
                    property: 'translate.token.symbol', value: (
                        <Link data-e2e='link' onClick={() => history.push(getRoute(ROUTES.ASSET, { id: assetId }))}>
                            {baseSymbol}
                        </Link>
                    )
                },
                { property: 'translate.token.price', value: parseInt(price) },
                { property: 'translate.number.of.tokens', value: parseInt(baseAmount) },
                { property: 'translate.asset.name', value: details?.name },
                { property: 'translate.asset.class', value: <TranslatedMessage id={details?.class} /> },
                { property: 'translate.investor.name', value: (
                    <Link data-e2e='link' onClick={() => history.push(getRoute(ROUTES.INVESTOR_DETAILS, { id: details?.clientId }))}>
                        {details?.firstName} {details?.lastName}
                    </Link>
                ) }
            ]
        }
    },
    [TRANSACTION_TYPE.Withdrawal]: (transaction) => {
        const {
            additionalDetails,
            completedAt,
            country,
            createdAt,
            currency,
            details,
            history,
            iban,
            id,
            staffEmail,
            status,
            swiftCode,
            withdrawalAddress
        } = transaction

        const statusColors = {
            'completed': 'success',
            'pending': 'warning',
        }

        return {
            title: 'translate.withdrawal.details',
            rows: [
                { property: 'translate.amount', value: formatCurrency(parseInt(details?.amount)) },
                { property: 'translate.status', value: <WBLabel density={0} text={status} color={statusColors[status]} variant="light" /> },
                { property: 'translate.date.created', value: formatDateTimeTechnical(createdAt) },
                { property: 'translate.date.completed', value: completedAt ? formatDateTimeTechnical(completedAt) : '-' },
                { property: 'translate.completed.by', value: staffEmail ?? '-' },
                { property: 'translate.investor.first.name', value: additionalDetails?.client?.firstName },
                {
                    property: 'translate.investor.last.name', value: (
                        <Link
                            data-e2e='link'
                            onClick={() => history.push(getRoute(ROUTES.INVESTOR_DETAILS, { id: additionalDetails?.client?.id }))}>
                            {additionalDetails?.client?.lastName}
                        </Link>
                    )
                },
                { property: 'translate.iban', value: iban },
                { property: 'translate.bic.swift', value: swiftCode },
                { property: 'translate.account.currency', value: currency },
                { property: 'translate.bank.country', value: formatCountry(country) },
                { property: 'translate.recipient.country', value: formatCountry(withdrawalAddress?.country) },
                { property: 'translate.recipient.address', value: withdrawalAddress?.addressLine1 },
                { property: 'translate.recipient.postal.code', value: withdrawalAddress?.zip },
                { property: 'translate.recipient.city', value: withdrawalAddress?.city },
                { property: 'translate.recipient.region', value: withdrawalAddress?.region }
            ],
            children: ({ isOpen, setIsOpen }) => {
                const api = useApiCall();
                const COMPLETE_WITHDRAWAL_API = completeWithdrawalApi(id);

                const updateWithdrawal = useCallback(() => {
                    api('PUT', COMPLETE_WITHDRAWAL_API, {})
                        .then(() => {
                            setIsOpen();
                            api('GET', getWithdrawalDetailsApi(id))
                                .then(() => NotificationManager.success('translate.deposit.complete.success'))

                        })
                }, [isOpen, JSON.stringify(transaction)]);

                return !Boolean(completedAt) && [
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={setIsOpen}
                        data-e2e='btn-mark-completed'
                    >
                        <TranslatedMessage id="translate.mark.as.completed" />
                    </Button>,

                    <Dialog open={isOpen} maxWidth="xs" fullWidth>
                        <DialogTitle>
                            <TranslatedMessage id="translate.complete.withdrawal" />
                        </DialogTitle>

                        <Grid container spacing={2} p={3}>
                            <Grid item>
                                <Alert severity="warning">
                                    <TranslatedMessage id="translate.complete.withdrawal.dialog.alert" />
                                </Alert>
                            </Grid>

                            <Grid item>
                                <Typography>
                                    <TranslatedMessage id="translate.complete.withdrawal.dialog.message" />
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box p={2} display="flex" alignContent="flex-end" justifyContent="flex-end">
                            <Button  data-e2e='btn-cancel' disableElevation color="inherit" onClick={setIsOpen} sx={{ mr: 2 }}>
                                <TranslatedMessage id="translate.button.cancel" />
                            </Button>
                            <Button data-e2e='btn-confirm' disableElevation color="primary" onClick={updateWithdrawal} variant="contained">
                                <TranslatedMessage id="translate.confirm" />
                            </Button>
                        </Box>
                    </Dialog>
                ]
            }
        }
    },
    [TRANSACTION_TYPE.Burn]: ({ assetId, baseAmount, baseSymbol, createdAt, details, status, price, currency, history }) => {
        return {
            title: 'translate.liquidation.details',
            rows: [
                { property: 'translate.amount', value: formatCurrency(parseFloat(price) * parseFloat(baseAmount), currency) },
                { property: 'translate.status', value: <StatusLabel text={status} /> },
                { property: 'translate.date', value: formatDateTimeTechnical(createdAt) },
                { property: 'translate.token.symbol', value: (
                    <Link onClick={() => history.push(getRoute(ROUTES.ASSET, { id: assetId }))}>
                        {baseSymbol}
                    </Link>
                ) },
                { property: 'translate.number.of.tokens', value: parseFloat(baseAmount) },
                { property: 'translate.asset.name', value: details?.name },
                { property: 'translate.asset.class', value: <TranslatedMessage id={details?.class} /> },
                { property: 'translate.original.investment', value: formatCurrency(details?.buyPrice) },
                { property: 'translate.realized.return', value: `${details?.realizedReturn}%` },
                { property: 'translate.investor.name', value: (
                    <Link onClick={() => history.push(getRoute(ROUTES.INVESTOR_DETAILS, { id: details?.clientId }))}>
                        {details?.firstName} {details?.lastName}
                    </Link>
                ) }
            ]
        }
    }
}

export const TransactionDetails: FC = () => {
    const [state] = useContext(ApiContext);
    const [isOpen, setIsOpen] = useModal();
    const history = useHistory();
    const { type, id } = useParams<{ type: string; id: string }>();

    function getDetails() {
        if (type === TRANSACTION_TYPE.Withdrawal) {
            const API = getWithdrawalDetailsApi(id);

            return {
                TRANSACTION_DETAILS_API: API,
                transactionDetails: getWithdrawalDetails(state, API)
            }
        }

        const API = getTransactionDetailsApi(id);
        return {
            TRANSACTION_DETAILS_API: API,
            transactionDetails: getTransactionDetails(state, API)
        }
    }

    const { TRANSACTION_DETAILS_API, transactionDetails } = getDetails()
    const { rows, title, children = false } = selectors[type]({ ...transactionDetails, history });

    return (
        <PageLoad endpoints={[TRANSACTION_DETAILS_API]} getters={[TRANSACTION_DETAILS_API]}>
            <Container maxWidth="lg">
                <Box py={4} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4">
                        <TranslatedMessage id={title} />
                    </Typography>
                    {children && children({ isOpen, setIsOpen })}
                </Box>

                <PaperBox>
                    <List>
                        {rows.map((row, i) => <InfoBoxRow divider={i < rows.length - 1} {...row} />)}
                    </List>
                </PaperBox>
            </Container>
        </PageLoad>
    )
}
