import { FC, Fragment } from 'react';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Divider from '@mui/material/Divider';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

interface Props {
    onClose: (event?) => void;
    open: boolean;
    asset: CombinedAsset;
}

export const AssetCharacteristicsDialog: FC<Props> = ({ onClose, open, asset }) => {
    return (
        <Dialog onClose={onClose} open={open} maxWidth='sm' fullWidth>
            <DialogTitle>
                <TranslatedMessage id="translate.characteristics.dialog.title" />
            </DialogTitle>
            <Box px={1}>
                <List>
                    {asset.customFields.map((doc) => (
                        <Fragment>
                            <ListItem key={doc.contentUrl}>
                                <Typography variant="body1" color="textSecondary">
                                    {doc.name}
                                </Typography>

                                <ListItemSecondaryAction>
                                    <Box fontSize="body1.fontSize" color="textSecondary" fontWeight="fontWeightMedium">
                                        {doc.value}
                                    </Box>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant='middle' />
                        </Fragment>
                    ))}
                </List>
                <Box p={2} display="flex" alignContent='flex-end' justifyContent="flex-end">
                    <Button disableElevation onClick={onClose} color="inherit">
                        <TranslatedMessage id='translate.button.close' />
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};
