import { filters } from '../../common/constants';
import { formatCurrencySymbol } from '@wealthberry/common-base/src/utils';

export const FIELDS = {
    TOKEN_SYMBOL: 'tokenSymbol',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    AMOUNT_FROM: 'baseAmount[gte]',
    AMOUNT_TO: 'baseAmount[lte]',
    COUNTRY: 'country',
}
export const immutableFields = {
    [FIELDS.TOKEN_SYMBOL]: {
        id: FIELDS.TOKEN_SYMBOL,
        label: 'translate.token.symbol',
        type: 'autocomplete'
    },
    [FIELDS.FIRST_NAME]: {
        id: FIELDS.FIRST_NAME,
        label: 'translate.first.and.middle.name',
    },
    [FIELDS.LAST_NAME]: {
        id: FIELDS.LAST_NAME,
        label: 'translate.last.name',
    },
    [FIELDS.AMOUNT_FROM]: {
        id: FIELDS.AMOUNT_FROM,
        label: 'translate.amount.from',
        xs: 6,
        _label: `From ${formatCurrencySymbol()}{value}`,
    },
    [FIELDS.AMOUNT_TO]: {
        id: FIELDS.AMOUNT_TO,
        label: 'translate.amount.to',
        xs: 6,
        _label: `to ${formatCurrencySymbol()}{value}`,
    },
    [FIELDS.COUNTRY]: {
        id: FIELDS.COUNTRY,
        label: 'translate.country',
        type: 'autocomplete',
    }
}

export const formInitial = {
    ...filters,
    dialog: {
        [FIELDS.TOKEN_SYMBOL]: {
            error: false,
            value: null,
        },
        [FIELDS.FIRST_NAME]: {
            error: false,
            value: '',
        },
        [FIELDS.LAST_NAME]: {
            error: false,
            value: '',
        },
        [FIELDS.AMOUNT_FROM]: {
            error: false,
            value: '',
        },
        [FIELDS.AMOUNT_TO]: {
            error: false,
            value: '',
        },
        [FIELDS.COUNTRY]: {
            error: false,
            value: null,
        },
    },
    selected: []
}
