import { FIELDS } from './reducer';
import { getValues } from '@wealthberry/common-base/src/utils/index';

export const logFiltration = ({ url, data }) => {
    if (data[FIELDS.ASSET_ID]) {
        url += `&assetId=${data[FIELDS.ASSET_ID]}`;
    }

    if (data[FIELDS.CATEGORY]) {
        url += `&description=${data[FIELDS.CATEGORY]}`;
    }

    if (data[FIELDS.OWNER_NAME]) {
        url += `&assetOwnerId=${data[FIELDS.OWNER_NAME]}`
    }

    if (data[FIELDS.PERFORMED_BY]) {
        url += `&staffId=${data[FIELDS.PERFORMED_BY]}`
    }

    return url;
}

export const initialLogFiltration = ({ url, form }) => {
    const dates: { from: string; to: string; } = getValues(form.filters);
    const from = (new Date(dates?.from) as any) / 1000;
    const to = (new Date(dates?.to).setHours(23, 59) as any) / 1000;
    const dateFilter = `producedAt[gte]=${from}&producedAt[lte]=${to}`;
    return `${url}${dateFilter}`;
}
