import { FC, useCallback, useEffect, useReducer } from 'react';
import Grid from '@mui/material/Grid';
import { AssetOwnerFields, AssetOwnerImmutable, AssetOwnerMutable } from '../create/form';
import { WBFormField } from '@wealthberry/common-base/src/forms/field';
import { AssetOwnerContext } from '../create/reducer';
import { useApiCall } from '../../../common/api/hook';
import { getAssetOwnerInfoApi, getAssetOwnersApi } from '../../../common/api/getters';
import { NotificationManager } from '@wealthberry/common-base/src/notifications';
import { extraProps, FORM_ID, formSelector, countries } from '../utils';
import { getItem } from '@wealthberry/common-base/src/utils/localstorage';
import CONFIG from '../../../config';
import { handleSubmit } from '@wealthberry/common-base/src/forms/handle-submit';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import WBDialogWrapper from '../../../common/components/wb-dialog-wrapper';

export const DialogEditAssetOwner: FC<{
    open: boolean;
    setClose: VoidFunction;
    assetOwnerInfo: AssetOwner
}> = (props) => {
    const { open, setClose, assetOwnerInfo } = props;
    const [form, dispatch] = useReducer(updateFormReducer, { [FORM_ID]: AssetOwnerMutable });
    const api = useApiCall();

    const ASSET_OWNERS_API = getAssetOwnersApi();
    const validations = getItem('wb-doc')?.[`${CONFIG.ASSETS_API}`]?.['/api/asset_owners']?.['post']?.['x-validation'] || {};
    const ASSET_OWNER_INFO_API = getAssetOwnerInfoApi(assetOwnerInfo.id);
    const submitForm = useCallback(() => {
        api('PUT', `${ASSET_OWNERS_API}/${assetOwnerInfo.id}`, formSelector(form[FORM_ID]))
            .then(() => {
                api('GET', ASSET_OWNER_INFO_API)
                    .then(() => {
                        setClose();
                        NotificationManager.success('translate.asset.owner.updated');
                    });
            });
    }, [JSON.stringify(form)]);

    const formSubmit = handleSubmit({
        dispatch,
        formId: FORM_ID,
        form: form[FORM_ID],
        validations,
        callback: submitForm
    });

    useEffect(() => {
        if (!Object.values(assetOwnerInfo).length) {
            return;
        }
        const country = countries.find(country => country.code === assetOwnerInfo.country);
        const phoneCode = countries.find(country => assetOwnerInfo.phone.substring(0, 4).includes(country.countryCode));
        dispatch({
            type: 'UPDATE_FORM',
            payload: {
                [FORM_ID]: {
                    [AssetOwnerFields.CompanyName]: { value: assetOwnerInfo.companyName },
                    [AssetOwnerFields.CompanyTaxNumber]: { value: assetOwnerInfo.companyTaxNumber },
                    [AssetOwnerFields.Country]: { value: country },
                    [AssetOwnerFields.Address]: { value: assetOwnerInfo.address },
                    [AssetOwnerFields.Zip]: { value: assetOwnerInfo.zip },
                    [AssetOwnerFields.City]: { value: assetOwnerInfo.city },
                    [AssetOwnerFields.Region]: { value: assetOwnerInfo.region },
                    [AssetOwnerFields.PhoneCode]: { value: phoneCode?.countryCode },
                    [AssetOwnerFields.Phone]: { value: assetOwnerInfo.phone.replace(phoneCode?.countryCode, '') },
                    [AssetOwnerFields.Email]: { value: assetOwnerInfo.email },
                    [AssetOwnerFields.ResponsiblePerson]: { value: assetOwnerInfo.responsiblePerson },
                }
            }
        });
    }, [assetOwnerInfo]);

    return (
        <WBDialogWrapper
            open={open}
            onClose={setClose}
            onSubmit={formSubmit}
            onCloseBtnLabel='translate.button.cancel'
            onSubmitBtnLabel='translate.button.update'
            title='translate.edit.asset.owner'
        >
            <AssetOwnerContext.Provider value={[form, dispatch]}>
                <AssetOwnerContext.Consumer>
                    {([{ assetOwnerForm }, d]) => (
                        <Grid container spacing={3} px={3} pb={3}>
                            {Object.keys(assetOwnerForm).map((key) => (
                                <WBFormField
                                    key={key}
                                    {...assetOwnerForm[key]}
                                    _fieldInfo={{ ...AssetOwnerImmutable[key], ...extraProps[key] }}
                                    setState={(updatedField) => d({
                                        type: 'UPDATE_FORM',
                                        payload: { [FORM_ID]: { [updatedField.id]: updatedField } }
                                    })}
                                    validations={validations[key]}
                                />
                            ))}
                        </Grid>
                    )}
                </AssetOwnerContext.Consumer>
            </AssetOwnerContext.Provider>
        </WBDialogWrapper>
    )
}
