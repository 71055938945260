import React from 'react';
import { setItem, setTokens } from '@wealthberry/common-base/src/utils/localstorage';
import { NotificationManager } from '@wealthberry/common-base/src/notifications';
import { ROUTES } from '../constants';
import { history } from '../../index';
import { ActionTypes, ApiContext } from '@wealthberry/common-base/src/api/index';
import { jsonRequest } from './utils';
import { publicJsonRequest } from '@wealthberry/common-base/src/api/utils';
import CONFIG from '../../config';

// arbitrary number
const MAX_RETRIES = 3;

export function useApiPoll() {
    const [, dispatch] = React.useContext(ApiContext);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    return function (condition: (data: any) => boolean) {
        let retried = 0;
        return async function call(method: string, url: string, body?: object) {
            dispatch({ type: ActionTypes.Register, payload: { method, url } });

            try {
                const data = await jsonRequest(method, url, body);

                if (retried === MAX_RETRIES) {
                    dispatch({ type: ActionTypes.SaveError, payload: { error: { message: 'max-retries-reached' }, method, url } });
                    return { error: 'max-retries-reached' };
                }

                if (condition(data)) {
                    dispatch({ type: ActionTypes.SaveResponse, payload: { data, method, url } });
                    return;
                }

                await delay(2000);

                retried += 1;
                return await call(method, url, body);
            } catch (e) {
                // copy pasted from below useApiCall
                if (e.status === 401) {
                    jsonRequest('GET', `${CONFIG.ADMIN_API}/authenticate/refresh`)
                        .then((tokens: { token: string; refreshToken: string }) => {
                            setTokens(tokens);
                            return call(method, url, body);
                        })
                        .catch(() => {
                            // failed to refresh tokens
                            setTokens(null);
                            setItem('wb-client', null);
                            NotificationManager.error('translate.session.expired')
                            history.push(ROUTES.ADMIN);
                        });
                } else {
                    e.json()
                        .then((error) => {
                            dispatch({ type: ActionTypes.SaveError, payload: { error, method, url } });
                            const globalError = error?.violations?.find(({ propertyPath }) => propertyPath === '');
                            if (globalError) {
                                NotificationManager.error(globalError.message);
                            } else {
                                NotificationManager.error(error.detail);
                            }
                        });
                }
            }
        }
    }
}

export function useApiCall(publicApi = false) {
    const [, dispatch] = React.useContext(ApiContext);

    const request = publicApi ? publicJsonRequest : jsonRequest;

    return function call(method: string, url: string, body?: object) {
        dispatch({ type: ActionTypes.Register, payload: { method, url } });

        return new Promise((resolve, reject) => {
            request(method, url, body)
                .then((data) => {
                    dispatch({ type: ActionTypes.SaveResponse, payload: { data, method, url } });
                    resolve(data);
                })
                .catch((e) => {
                    if (e.status === 401 && !publicApi) {
                        jsonRequest('GET', `${CONFIG.ADMIN_API}/authenticate/refresh`)
                            .then((tokens: { token: string; refreshToken: string }) => {
                                setTokens(tokens);
                                return call(method, url, body);
                            })
                            .catch(() => {
                                // failed to refresh tokens
                                setTokens(null);
                                setItem('wb-client', null);
                                NotificationManager.error('translate.session.expired')
                                history.push(ROUTES.ADMIN);
                            });
                    } else {
                        e.json()
                            .then((error) => {
                                dispatch({ type: ActionTypes.SaveError, payload: { error, method, url } });
                                const globalError = error?.violations?.find(({ propertyPath }) => propertyPath === '');
                                if (globalError) {
                                    NotificationManager.error(globalError.message);
                                } else {
                                    NotificationManager.error(error.detail);
                                }
                                reject({ error, status: e.status });
                            });
                    }
                });
        });

    }
}
