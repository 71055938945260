import React, { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Filter1OutlinedIcon from '@mui/icons-material/Filter1Outlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Fade from '@mui/material/Fade';
import CONFIG from '../../../config';
import WBLabel from '@wealthberry/common-base/src/components/wb-label';

const useStyles = makeStyles((theme: Theme) => ({
    hover: {
        position: 'absolute',
        top: 0,
        left: 0,
        background: 'rgba(0,0,0,0.5)',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    wrapper: {
        overflow: 'hidden',
    }
}));

const Image: FC<any> = ({ file, index, deleteFile, makePrimary, setGalleryImage, max, isDragged }) => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const [deleteCheck, setDeleteCheck] = useState(false);

    const handlePopoverOpen = (event) => {
        if (!isDragged) {
            setVisible(true);
        }
    };

    const handlePopoverClose = () => {
        setVisible(false);
        setDeleteCheck(false);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            sx={{
                background: '#0000000A',
                height: '100%',
                width: '100%',
                position: 'relative',
            }}
        >
            <div
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className={classes.wrapper}
            >
                <img
                    width="100%"
                    height="100%"
                    style={{
                        objectFit: 'contain'
                    }}
                    src={`${CONFIG.ASSETS}${file}`}
                />
                <Fade in={visible}>
                    <div className={classes.hover}>
                        {index !== 0 && (
                            <IconButton size="small" onClick={() => makePrimary(file)} sx={{ pr: 1 }}>
                                <Avatar sx={{ bgcolor: 'white' }}>
                                    <Filter1OutlinedIcon color="action" />
                                </Avatar>
                            </IconButton>
                        )}
                        {(
                            <IconButton size="small" onClick={() => setGalleryImage(file)}>
                                <Avatar sx={{ bgcolor: 'white' }}>
                                    <RemoveRedEyeOutlinedIcon color="action" />
                                </Avatar>
                            </IconButton>
                        )}

                        {!deleteCheck && (
                            <IconButton size="small" onClick={() => setDeleteCheck(true)}>
                                <Avatar sx={{ bgcolor: 'white' }}>
                                    <DeleteOutlineOutlinedIcon color="action" />
                                </Avatar>
                            </IconButton>
                        )}
                        {deleteCheck && (
                            <IconButton size="small" onClick={() => deleteFile(file)}>
                                <Box
                                    sx={{
                                        bgcolor: 'error.main',
                                        color: 'error.contrastText',
                                        p: 1,
                                        px: 2,
                                        height: '42px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '15px',
                                        borderRadius: 20
                                    }}
                                >
                                    Remove ?
                                </Box>
                            </IconButton>
                        )}
                    </div>
                </Fade>

                {index === 0 && max !== 1 && (
                    <Box sx={{
                        position: 'absolute',
                        top: 16,
                        left: 0,
                    }}>
                        <WBLabel text="translate.status.primary" variant="dark" color="success" size="large" density={0}/>
                    </Box>
                )}
            </div>
        </Box>
    );
};

export default Image;
