import { formatCurrency, formatDateTechnical, formatCurrencySymbol } from '@wealthberry/common-base/src/utils';

const FORM_FIELDS = {
    AMOUNT: 'amount',
    RETURN_PERCENT: 'returnPercent',
    TARGET_RETURN: 'targetReturn'
}

export const immutableFields = {
    [FORM_FIELDS.AMOUNT]: {
        id: FORM_FIELDS.AMOUNT,
        label: 'Amount to distribute',
        InputProps: {
            startAdornment: formatCurrencySymbol()
        },
    }
}
export const initialStateStepOne = {
    ['liquidation']: {
        [FORM_FIELDS.AMOUNT]: {
            error: false,
            value: '',
            touched: false,
        }
    },
}

export const immutableFieldsLiquidation = {
    [FORM_FIELDS.AMOUNT]: {
        id: FORM_FIELDS.AMOUNT,
        label: 'Liquidation amount',
        inputProps: {
            readOnly: true,
        },
    },
    [FORM_FIELDS.RETURN_PERCENT]: {
        id: FORM_FIELDS.RETURN_PERCENT,
        label: 'Realised return',
        inputProps: {
            readOnly: true,
        },
    },
    [FORM_FIELDS.TARGET_RETURN]: {
        id: FORM_FIELDS.TARGET_RETURN,
        label: 'Target return',
        inputProps: {
            readOnly: true,
        },
    }
}
export const initialStateStepTwo = {
    ['liquidation']: {
        [FORM_FIELDS.AMOUNT]: {
            error: false,
            value: '',
            touched: false,
        },
        [FORM_FIELDS.RETURN_PERCENT]: {
            error: false,
            value: '',
            touched: false,
        },
        [FORM_FIELDS.TARGET_RETURN]: {
            error: false,
            value: '',
            touched: false,
        }
    },
};

export const getDialogListItems = ({ liquidation, isAssetLiquidated, asset }) => {
    if (isAssetLiquidated) {
        return [{
            key: 'Liquidation amount',
            value: formatCurrency(parseFloat(liquidation?.amount), asset.currency)
        }, {
            key: 'Realised return',
            value: liquidation?.returnPercent + '%',
        }, {
            key: 'Liquidation date',
            value: formatDateTechnical(liquidation?.createdAt, true)
        }]
    }
    return [{
        key: 'Liquidation amount',
        value: formatCurrency(parseFloat(liquidation?.amount) || 0, asset.currency)
    }];
}
