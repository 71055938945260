import React, { FC, useContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { ApiContext } from '@wealthberry/common-base/src/api/index';
import { PageLoad } from '../../common/components/page-load';
import {
    getAssetOwners,
    getAssetOwnersApi,
    getAssetOwnersLogsApi,
    getClientsApi, getStaff,
    getStaffApi
} from '../../common/api/getters';
import { DialogCreateAssetOwner } from './create/dialog-create-asset-owner';
import { formatCountry } from '../../common/utils';
import { formatDateTechnical, getRoute, getValues } from '@wealthberry/common-base/src/utils';
import { ROUTES } from '../../common/constants';
import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabPanel } from '../../common/components/tab-panel';
import Autocomplete from '@mui/material/Autocomplete';
import { TranslatedMessage, useIntlWithDefaultMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { getFiltersLoadingState } from '@wealthberry/common-base/src/loader/context';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import { initialLogFiltration, logFiltration } from '../../common/logs/utils';
import { useApiCall } from '../../common/api/hook';
import { FIELDS, formInitial } from '../../common/logs/reducer';
import DateFilters from '../../common/logs/date-filters';

export const AssetOwner: FC = () => {
    const intl = useIntlWithDefaultMessage();
    const history = useHistory();
    const api = useApiCall();
    const [state] = useContext(ApiContext);
    const [items, setItems] = useState<Transaction[]>([]);
    const [form, dispatch] = useReducer(updateFormReducer, formInitial);
    const columns = ['translate.name', 'translate.country', 'translate.date.added'];
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const ASSET_OWNERS_API = getAssetOwnersApi();
    const assetOwners = getAssetOwners(state, ASSET_OWNERS_API);

    const STAFF_API = getStaffApi();
    const staff = getStaff(state, STAFF_API);
    const ASSET_OWNERS_LOGS_API = getAssetOwnersLogsApi();

    const eventTypeOptions = Object.entries(
        items.reduce((a, { category, description }) => ({ ...a, [category]: description }), {})
    ).map(([c, d]) => ({ category: c, description: d }))

    const handleFiltration = () => {
        const filters = getValues(form.dialog);
        let url = initialLogFiltration({ url: `${ASSET_OWNERS_LOGS_API}?`, form });
        url = logFiltration({ url, data: filters });
        api('GET', url).then((result: Transaction[]) => {
            setItems(result);
        });
    }

    useEffect(() => {
        handleFiltration()
    }, [form.filters, form.dialog]);

    const [search, setSearchQuery] = useState(null);
    const filteredAssetOwners = search ? assetOwners.filter(asset => asset.id === search.id) : assetOwners;
    const loading = getFiltersLoadingState([getClientsApi()]);

    return (
        <PageLoad endpoints={[ASSET_OWNERS_API, STAFF_API]} getters={[ASSET_OWNERS_API, STAFF_API]}>
            <Container disableGutters maxWidth="lg">
                <Box py={4} display="flex" justifyContent="space-between">
                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">
                            <TranslatedMessage id="translate.asset.owners" />
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        indicatorColor="primary"
                        onChange={(e, newValue) => setSelectedTab(() => newValue)}
                        textColor="primary"
                        value={selectedTab}
                        variant="standard"
                    >
                        <Tab label={<TranslatedMessage id="translate.list" />} />
                        <Tab label={<TranslatedMessage id="translate.log" />} />
                    </Tabs>
                </Box>
                <TabPanel value={selectedTab} index={0}>
                    <Grid item xs={12}>
                        <Box mb={4} display="flex" alignItems="center" justifyContent="space-between">
                            <Box>
                                <Autocomplete
                                    size='small'
                                    getOptionLabel={(option: AssetOwner) => option.companyName}
                                    options={assetOwners}
                                    onChange={(event, newValue) => setSearchQuery(newValue)}
                                    renderInput={(params) => <TextField
                                        sx={{ width: '225px' }}
                                        label={intl.formatMessage({ id: 'translate.search.asset.owner.name' })}
                                        {...params} />}
                                />
                            </Box>

                            <Box>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    data-e2e="add-asset-owner"
                                    onClick={() => setIsOpen(true)}
                                >
                                    <TranslatedMessage id="translate.add.new.asset.owner" />
                                </Button>
                            </Box>
                        </Box>
                        <WBList
                            loading={loading}
                            emptyMessage='translate.pages.deposit.list.empty'
                            headItems={columns}
                            data={filteredAssetOwners}
                            row={(owner) => (
                                <TableRow key={owner.id}>
                                    <TableCell>
                                        <Link
                                            onClick={() => history.push(getRoute(ROUTES.ASSET_OWNER_DETAILS, { id: owner.id }))}>
                                            {owner.companyName}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {formatCountry(owner.country)}
                                    </TableCell>
                                    <TableCell>
                                        {formatDateTechnical(owner.createdAt)}
                                    </TableCell>
                                </TableRow>
                            )}
                        />
                    </Grid>
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                    <Grid item xs={12}>
                        <Box mb={4} display="flex" alignItems="center" justifyContent="space-between">
                            <DateFilters filters={form.filters} dispatch={dispatch} />

                            <Box pr={2} flex={1}>
                                <Autocomplete
                                    size='small'
                                    getOptionLabel={(option: AssetOwner) => option.companyName}
                                    options={assetOwners}
                                    onChange={(event, newValue) => {
                                        dispatch({
                                            type: 'UPDATE_FORM',
                                            payload: {
                                                dialog: {
                                                    [FIELDS.OWNER_NAME]: { value: newValue.id, id: FIELDS.OWNER_NAME },
                                                }
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField label="Owner name" {...params} />}
                                />
                            </Box>

                            <Box pr={2} flex={1}>
                                <Autocomplete
                                    size='small'
                                    getOptionLabel={(option) => option.category}
                                    options={eventTypeOptions}
                                    onChange={(event, newValue) => {
                                        dispatch({
                                            type: 'UPDATE_FORM',
                                            payload: {
                                                dialog: {
                                                    [FIELDS.CATEGORY]: { value: newValue?.description, id: FIELDS.CATEGORY },
                                                }
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField label="Event type" {...params} />} />
                            </Box>
                            <Box flex={1}>
                                <Autocomplete
                                    size='small'
                                    getOptionLabel={(option) => option.name}
                                    options={staff}
                                    onChange={(event, newValue) => {
                                        dispatch({
                                            type: 'UPDATE_FORM',
                                            payload: {
                                                dialog: {
                                                    [FIELDS.PERFORMED_BY]: { value: newValue?.id, id: FIELDS.PERFORMED_BY },
                                                }
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField label="Performed by" {...params} />} />
                            </Box>
                        </Box>

                        <WBList
                            loading={getFiltersLoadingState([ASSET_OWNERS_LOGS_API])}
                            emptyMessage="translate.pages.deposit.list.empty"
                            headItems={['translate.owner.name', 'translate.event.description', 'translate.performed.by', 'translate.date']}
                            data={items}
                            row={(log) => (
                                <TableRow key={log.id}>
                                    <TableCell>
                                        {assetOwners.find((owner) => owner.id === log.assetOwnerId)?.companyName}
                                    </TableCell>
                                    <TableCell>
                                        {log.description}
                                    </TableCell>
                                    <TableCell>
                                        {staff.find(staff => staff.id === log.staffId)?.email}
                                    </TableCell>
                                    <TableCell>
                                        {formatDateTechnical(log.producedAt)}
                                    </TableCell>
                                </TableRow>
                            )}
                        />
                    </Grid>
                </TabPanel>
            </Container>

            <DialogCreateAssetOwner open={isOpen} setClose={() => setIsOpen(false)} />
        </PageLoad>
    )
}
