import CONFIG from '../../config';

export const loginWithGooglePromise = () => new Promise((resolve, reject) => {
    // Loading Google OAuth
    // https://codeburst.io/adding-google-sign-in-to-your-webapp-a-react-example-dcec8c73cb9f
    const { gapi } = window as any;

    if (!gapi) {
        reject()
    }

    gapi.load('auth2', () => {
        (window as any).auth2 = gapi.auth2.init({ client_id: CONFIG.GAUTH_CLIENT_ID })

        gapi.load('signin2', () => {
            gapi.signin2.render('gLoginButton', {
                prompt: 'select_account',
                scope: 'profile email',
                width: '240',
                height: '50',
                longtitle: true,
                onsuccess: (data) => resolve(data),
                onfailure: (err) => reject(err)
            })
        })
    });
});

export const logoutWithGooglePromise = () => new Promise((resolve, reject) => {
    const { gapi } = window as any;

    if (!gapi) {
        reject();
    }

    gapi.load('auth2', () => {
        const auth2 = gapi.auth2.init({ client_id: CONFIG.GAUTH_CLIENT_ID });
        auth2.signOut()
            .then(resolve)
            .catch(reject);
    });
})
