import { FC, useCallback, useReducer } from 'react';
import Grid from '@mui/material/Grid';
import { AssetOwnerImmutable, AssetOwnerMutable } from './form';
import { WBFormField } from '@wealthberry/common-base/src/forms/field';
import { AssetOwnerContext } from './reducer';
import { useApiCall } from '../../../common/api/hook';
import { getAssetOwnersApi } from '../../../common/api/getters';
import { NotificationManager } from '@wealthberry/common-base/src/notifications';
import { getItem } from '@wealthberry/common-base/src/utils/localstorage';
import CONFIG from '../../../config';
import { handleSubmit } from '@wealthberry/common-base/src/forms/handle-submit';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import WBDialogWrapper from '../../../common/components/wb-dialog-wrapper';
import { FORM_ID, formSelector, extraProps } from '../utils';

export const DialogCreateAssetOwner: FC<{
    open: boolean;
    setClose: VoidFunction;
}> = (props) => {
    const { open, setClose } = props;
    const [form, dispatch] = useReducer(updateFormReducer, { [FORM_ID]: AssetOwnerMutable });
    const api = useApiCall();

    const ASSET_OWNERS_API = getAssetOwnersApi();
    const validations = getItem('wb-doc')?.[`${CONFIG.ASSETS_API}`]?.['/api/asset_owners']?.['post']?.['x-validation'] || {};

    const submitForm = useCallback(() => {
        api('POST', ASSET_OWNERS_API, formSelector(form[FORM_ID]))
            .then(() => {
                api('GET', ASSET_OWNERS_API)
                    .then(() => {
                        setClose();
                        NotificationManager.success('translate.asset.owner.created');
                    });
            });
    }, [JSON.stringify(form)]);

    const formSubmit = handleSubmit({
        dispatch,
        formId: FORM_ID,
        form: form[FORM_ID],
        validations,
        callback: submitForm
    });

    return (
        <WBDialogWrapper
            open={open}
            onClose={setClose}
            onSubmit={formSubmit}
            onCloseBtnLabel='translate.button.cancel'
            onSubmitBtnLabel='translate.button.confirm'
            title='translate.add.new.asset.owner'
        >
            <AssetOwnerContext.Provider value={[form, dispatch]}>
                <AssetOwnerContext.Consumer>
                    {([{ assetOwnerForm }, d]) => (
                        <Grid container spacing={3} px={3} pb={3}>
                            {Object.keys(assetOwnerForm).map((key) => (
                                <WBFormField
                                    key={key}
                                    {...assetOwnerForm[key]}
                                    _fieldInfo={{ ...AssetOwnerImmutable[key], ...extraProps[key] }}
                                    setState={(updatedField) => d({
                                        type: 'UPDATE_FORM',
                                        payload: { [FORM_ID]: { [updatedField.id]: updatedField } }
                                    })}
                                    validations={validations[key]}
                                />
                            ))}
                        </Grid>
                    )}
                </AssetOwnerContext.Consumer>
            </AssetOwnerContext.Provider>
        </WBDialogWrapper>
    )
}
