import { FC } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export const PaperBox: FC = ({ children }) => (
    <Grid item xs={12}>
        <Paper square variant="outlined">
            {children}
        </Paper>
    </Grid>
)
