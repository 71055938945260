export const SAVE_FILE_LOCATION = 'SAVE_FILE_LOCATION';
export const SAVE_FILES = 'SAVE_FILES';
export const REMOVE_FILE_LOCATION = 'REMOVE_FILE_LOCATION';
export const UPDATE_FILE_ORDER = 'UPDATE_FILE_ORDER';

export const saveFile = ({ id, contentUrl, priority, title, alt }) => ({
    type: SAVE_FILE_LOCATION,
    payload: {
        id,
        location: { contentUrl, priority, title, alt }
    }
});

export const saveFiles = ({ id, fileLocations }) => ({
    type: SAVE_FILES,
    payload: { id, fileLocations }
});

export const removeFile = ({ id, contentUrl }) => ({
    type: REMOVE_FILE_LOCATION,
    payload: { id, contentUrl }
});

export const updateFileOrder = ({ id, contentUrl, order }) => ({
    type: UPDATE_FILE_ORDER,
    payload: { id, contentUrl, order }
});

export function saveFileLocationReducer(state, action) {
    switch (action.type) {
        case SAVE_FILE_LOCATION: {
            const { id, location } = action.payload;

            return {
                ...state,
                fileLocation: {
                    ...state.fileLocation,
                    [id]: state.fileLocation[id]
                        ? [...state.fileLocation[id], location]
                        : [location]
                }
            };
        }

        case REMOVE_FILE_LOCATION: {
            const { id, contentUrl } = action.payload;

            return {
                ...state,
                fileLocation: {
                    ...state.fileLocation,
                    [id]: state.fileLocation[id].filter((file) => file.contentUrl !== contentUrl)
                }
            }
        }

        case SAVE_FILES: {
            const { id, fileLocations } = action.payload;
            return {
                ...state,
                fileLocation: {
                    ...state.fileLocation,
                    [id]: fileLocations
                }
            }
        }

        case UPDATE_FILE_ORDER: {
            const { id, contentUrl, order } = action.payload;
            const element = state.fileLocation[id].find((file) => file.contentUrl === contentUrl);

            const reorderedArray = state.fileLocation[id]
                .filter((file) => file.contentUrl !== contentUrl)
                .reduce((acc, current, idx) => {
                    if (idx === order) {
                        return [...acc, element, current];
                    }

                    return [...acc, current];
                }, [])
                .concat(order === state.fileLocation[id].length - 1 && element)
                .filter(Boolean)
                .map((file, i) => ({ ...file, priority: i + 1 }));

            return {
                ...state,
                fileLocation: {
                    ...state.fileLocation,
                    [id]: reorderedArray
                }
            }
        }
    }
}
