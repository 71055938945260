import React, { ChangeEvent, FC, useState } from 'react';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { fileRequest } from '@wealthberry/common-base/src/api/utils';
import CONFIG from '../../../config';

interface Props {
    onClose: (event?) => void;
    open: boolean;
    documents: any[];
}

export const InvestmentDocumentsEditDialog: FC<Props> = ({ onClose, open, documents }) => {
    const [documentInfo, setDocumentInfo] = useState({ error: false, name: '' });

    const handleCapture = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const arrayOfFiles = Array.from(target.files);
        return Promise.all(arrayOfFiles.map((doc: File, index: number) => {
            const formData = new FormData();
            formData.append('file', doc);

            return new Promise((resolve, reject) => {
                fileRequest<{ contentUrl: string }>('POST', `${CONFIG.ASSETS_API}/media_objects`, formData,)
                    .then(({ contentUrl }) => {
                        setDocumentInfo({ name: doc.name, error: false });
                       /* dispatch({
                            type: SAVE_FILES,
                            payload: {
                                id: 'assetValuationDocuments',
                                updatedField: {
                                    contentUrl,
                                    title: doc.name,
                                }
                            }
                        });*/
                        resolve({});
                    })
                    .catch(reject);
            })
        }))
    };

    const acceptValues = '.pdf,.doc';

    return (
        <Dialog onClose={onClose} open={open} maxWidth='sm' fullWidth>
            <DialogTitle>
                Edit documents
            </DialogTitle>
            <Box px={3}>
                <Paper square variant="outlined">
                    <Table data-e2e="table">
                        <TableBody>
                            {documents.map((doc, index) => (
                                <TableRow data-e2e="table-row">
                                    <TableCell align='justify'>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography variant="body1">
                                                {doc.title}
                                            </Typography>
                                            <DeleteOutlinedIcon />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow data-e2e="table-row">
                                <TableCell align='right'>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <input
                                            accept={acceptValues}
                                            multiple
                                            style={{ display: 'none' }}
                                            id={'storeLocation'}
                                            type="file"
                                            onChange={handleCapture}
                                        />
                                        <label htmlFor={'storeLocation'}>
                                            <Box
                                                sx={{ color: 'text.inherit', cursor: 'pointer' }}
                                                bgcolor="#E0E0E0"
                                                px={2}
                                                py={1}
                                                borderRadius={1}
                                                fontSize="body2.fontSize"
                                            >
                                                <TranslatedMessage id="translate.select.files" />
                                            </Box>
                                        </label>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Box>
            <Grid item xs={12} sx={{ textAlign: 'end', px: 3, py: 2 }}>
                <Button disableElevation onClick={onClose} color="inherit" sx={{ mr: 2 }} data-e2e="btn-cancel">
                    <TranslatedMessage id='translate.button.cancel' />
                </Button>
                <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    data-e2e="btn-update"
                    //onClick={formSubmit}
                >
                    <TranslatedMessage id='translate.button.update' />
                </Button>
            </Grid>
        </Dialog>
    );
};
