import React, { useContext, useEffect, useReducer, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ApiContext } from '@wealthberry/common-base/src/api/index';
import { getRoute, getValues } from '@wealthberry/common-base/src/utils';
import { ROUTES } from '../../common/constants';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { TabPanel } from '../../common/components/tab-panel';
import { PageLoad } from '../../common/components/page-load';
import AssetsList from './asset-lists'
import AssetsListLogs from './asset-list-logs'
import { mergeData } from '../../common/utils';
import {
    getAssetLogsAPI,
    getAssets,
    getAssetsAPI,
    getAssetsStats,
    getAssetsStatsAPI, getStaff,
    getStaffApi
} from '../../common/api/getters';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { FIELDS, formInitial } from '../../common/logs/reducer';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import { useApiCall } from '../../common/api/hook';
import { initialLogFiltration, logFiltration } from '../../common/logs/utils';
import DateFilters from '../../common/logs/date-filters'

export default function Assets() {
    const history = useHistory();
    const [state] = useContext(ApiContext);
    const [search, setSearchQuery] = useState(null);
    const [form, dispatch] = useReducer(updateFormReducer, formInitial);
    const api = useApiCall();
    const [items, setItems] = useState<Transaction[]>([]);

    const ASSETS_API = getAssetsAPI();
    const USER_ASSETS_STATS_API = getAssetsStatsAPI();

    const assets = getAssets(state, ASSETS_API);
    const assetsStats = getAssetsStats(state, USER_ASSETS_STATS_API);

    const mergedData = mergeData(assets, assetsStats);
    const filteredAssets = search ? mergedData.filter(asset => asset.id === search.id) : mergedData;
    const [selectedTab, setSelectedTab] = useState(0);

    const eventTypeOptions = Object.entries(
        items.reduce((a, { category, description }) => ({ ...a, [category]: description }), {})
    ).map(([c, d]) => ({ category: c, description: d }))

    const STAFF_API = getStaffApi();
    const staff = getStaff(state, STAFF_API);

    const handleFiltration = () => {
        const filters = getValues(form.dialog);
        let url = initialLogFiltration({ url: `${getAssetLogsAPI()}?`, form });
        url = logFiltration({ url, data: filters });
        api('GET', url).then((result: Transaction[]) => {
            setItems(result);
        });
    }

    useEffect(() => {
        handleFiltration()
    }, [form.filters, form.dialog]);

    return (
        <Container disableGutters maxWidth="lg">
            <Box py={4} display="flex" justifyContent="space-between">
                <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4">
                        <TranslatedMessage id='translate.assets' />
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    indicatorColor="primary"
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    textColor="primary"
                    value={selectedTab}
                    variant="standard"
                >
                    <Tab label={<TranslatedMessage id="translate.list" />} />
                    <Tab label={<TranslatedMessage id="translate.log" />} />
                </Tabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
                <Grid item xs={12}>
                    <Box mb={4} display="flex" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Autocomplete
                                size='small'
                                getOptionLabel={(option) => option.name}
                                options={mergedData}
                                onChange={(event, newValue) => setSearchQuery(newValue)}
                                renderInput={(params) => <TextField
                                    sx={{ width: '225px' }}
                                    label="Аsset name" {...params} />}
                            />
                        </Box>
                        <Box display='flex' flexGrow={1} justifyContent='flex-end'>
                            <Box pr={2}>
                                <Button
                                    disableElevation
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    data-e2e="add-new-real-estate"
                                    onClick={() => history.push(getRoute(ROUTES.ADD_NEW_ASSET, { type: 'realEstate' }))}>
                                    Add new real estate
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    disableElevation
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    data-e2e="add-new-fine-art"
                                    onClick={() => history.push(getRoute(ROUTES.ADD_NEW_ASSET, { type: 'fineArt' }))}>
                                    Add new fine art
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <PageLoad endpoints={[ASSETS_API, USER_ASSETS_STATS_API]}
                              getters={[ASSETS_API, USER_ASSETS_STATS_API, STAFF_API]}>
                        <AssetsList assets={filteredAssets} />
                    </PageLoad>
                </Grid>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <Grid item xs={12}>
                    <Box mb={4} display="flex" alignItems="center" justifyContent="space-between">
                        <DateFilters filters={form.filters} dispatch={dispatch} />
                        <Box flex={1} pr={2}>
                            <Autocomplete
                                size='small'
                                getOptionLabel={(option) => option.name}
                                options={mergedData}
                                onChange={(event, newValue) => {
                                    dispatch({
                                        type: 'UPDATE_FORM',
                                        payload: {
                                            dialog: {
                                                [FIELDS.ASSET_ID]: { value: newValue?.id, id: FIELDS.ASSET_ID },
                                            }
                                        }
                                    })
                                }}
                                renderInput={(params) => <TextField
                                    label="Аsset name" {...params} />}
                            />
                        </Box>
                        <Box flex={1} pr={2}>
                            <Autocomplete
                                size='small'
                                getOptionLabel={(option) => option.category}
                                options={eventTypeOptions}
                                onChange={(event, newValue) => {
                                    dispatch({
                                        type: 'UPDATE_FORM',
                                        payload: {
                                            dialog: {
                                                [FIELDS.CATEGORY]: { value: newValue?.description, id: FIELDS.CATEGORY },
                                            }
                                        }
                                    })
                                }}
                                renderInput={(params) => <TextField
                                    label="Event type" {...params} />}
                            />
                        </Box>

                        <Box flex={1}>
                            <Autocomplete
                                size='small'
                                getOptionLabel={(option) => option.name}
                                options={staff}
                                onChange={(event, newValue) => {
                                    dispatch({
                                        type: 'UPDATE_FORM',
                                        payload: {
                                            dialog: {
                                                [FIELDS.PERFORMED_BY]: { value: newValue?.id, id: FIELDS.PERFORMED_BY },
                                            }
                                        }
                                    })
                                }}
                                renderInput={(params) => <TextField
                                    label="Performed by" {...params} />}
                            />
                        </Box>
                    </Box>
                    <AssetsListLogs logs={items} assets={assets} staff={staff} />
                </Grid>
            </TabPanel>
        </Container>
    );
}
