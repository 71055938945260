import { FC, useContext, useEffect, useReducer, useState } from 'react';
import { useApiCall } from '../../common/api/hook';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';

import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import { PageLoad } from '../../common/components/page-load';
import { formInitial } from './reducer';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import { getTokenDetails, getTokenDetailsApi, getTransactionsAPI } from '../../common/api/getters';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Row } from './row';
import { FilterDialog } from './filter-dialog';
import { getValues } from '@wealthberry/common-base/src/utils/index';
import { Filters } from '@wealthberry/common-base/src/components/wb-list/filters';
import { initialFiltration } from '../../common/utils';
import { transactionFiltration } from '../deposits/utils';
import { ApiContext } from '@wealthberry/common-base/src/api/index';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { getFiltersLoadingState } from '@wealthberry/common-base/src/loader/context';

const Liquidations: FC = () => {
    const api = useApiCall();
    const [open, toggle] = useModal();
    const [form, dispatch] = useReducer(updateFormReducer, formInitial);
    const [items, setItems] = useState<Transaction[]>([]);
    const [state] = useContext(ApiContext);
    const TOKEN_DETAILS_API = `${getTokenDetailsApi()}?asset.status=liquidated`;
    const tokens = getTokenDetails(state, getTokenDetailsApi()).map(({ symbol }) => ({ name: symbol, value: symbol }));
    const handleFiltration = () => {
        const filters = getValues(form.dialog);
        let url = initialFiltration({ url: `${getTransactionsAPI()}?type=burn&`, form });
        url = transactionFiltration({ url, data: filters });
        api('GET', url).then((result: Transaction[]) => {
            setItems(result);
        });
    }

    useEffect(() => {
        handleFiltration()
    }, [form.selected, form.filters]);

    const loading = getFiltersLoadingState([getTransactionsAPI()])
    return (
        <PageLoad endpoints={[TOKEN_DETAILS_API]} getters={[TOKEN_DETAILS_API]}>
            <Container disableGutters maxWidth="lg">
                <Box py={4} display="flex" justifyContent="space-between">
                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant='h4'>
                            <TranslatedMessage id='translate.pages.liquidations.list.title' />
                        </Typography>
                    </Box>
                </Box>
                <WBList
                    loading={loading}
                    emptyMessage='translate.pages.liquidations.list.empty'
                    headItems={[
                        'translate.amount',
                        'translate.token.symbol',
                        'translate.investor.name',
                        'translate.investor.country',
                        'translate.date',
                        'translate.status'
                    ]}
                    form={form}
                    dispatch={dispatch}
                    data={items}
                    toggle={toggle}
                    formInitial={formInitial}
                    row={(item) => <Row item={item} />}
                >
                    <Filters
                        form={form}
                        formInitial={formInitial}
                        dispatch={dispatch}
                        toggle={toggle}
                    />
                    <FilterDialog
                        open={open}
                        formInitial={formInitial}
                        toggle={toggle}
                        form={form}
                        dispatch={dispatch}
                        tokens={tokens}
                    />
                </WBList>
            </Container>
        </PageLoad>
    );
}

export default Liquidations;
