import React, { useState } from 'react';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { formatDateTimeTechnical } from '../../common/utils';
import { formatCurrency, getRoute } from '@wealthberry/common-base/src/utils';
import { ASSET_STATUS, ROUTES, STATUS_COLOR_MAP } from '../../common/constants';
import { useHistory } from 'react-router-dom';
import { fundraisingGoal, raisedFunds, sortFunctions } from './utils';
import { useApiCall } from '../../common/api/hook';
import { NotificationManager } from '@wealthberry/common-base/src/notifications';
import { getAssetsAPI } from '../../common/api/getters';
import { AssetStatusUpdate } from '../asset-view/dialogs/asset-status-update';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';
import { getDateDifferenceInDays } from '../../common/utils/date';
import WBLabel from '@wealthberry/common-base/src/components/wb-label';
import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

const sortState = {
    up: <ArrowUpwardIcon fontSize="small" />,
    down: <ArrowDownwardIcon fontSize="small" />,
    neutral: <ArrowDownwardIcon fontSize="small" />
}

const transitions = {
    neutral: 'down',
    up: 'down',
    down: 'up'
}

export default function AssetsList({ assets }: { assets: CombinedAsset[] }) {
    const history = useHistory();
    const api = useApiCall();

    const [state, setState] = useState([
        { key: 'name', value: 'translate.asset.name', sorted: 'neutral' },
        { key: 'class', value: 'translate.asset.class', sorted: 'neutral' },
        { key: 'funds', value: 'translate.raised.funds', sorted: 'neutral' },
        { key: 'goal', value: 'translate.fundraising.goal', sorted: 'neutral' },
        { key: 'owner', value: 'translate.asset.owner', sorted: 'neutral' },
        { key: 'created', value: 'translate.date.created', sorted: 'down' },
        { key: 'published', value: 'translate.date.published', sorted: 'neutral' },
        { key: 'status', value: 'translate.status.name', sorted: 'neutral' }
    ]);
    const [open, toggle] = useModal();
    const [selectedAsset, setSelectedAsset] = useState<Asset>();

    const toggleSort = (key: string) => {
        setState((state) => state.map((cell) => ({
            ...cell,
            sorted: key === cell.key ? transitions[cell.sorted] : 'neutral'
        })));
    }

    const selectedFilter = state.find(({ sorted }) => sorted !== 'neutral');
    const sortDirection = sortFunctions(selectedFilter.sorted);

    const dialogProps = {
        assetId: selectedAsset?.id,
        title: 'translate.restore.asset.dialog.title',
        alert: 'translate.restore.asset.dialog.alert',
        alertValues: { days: getDateDifferenceInDays(selectedAsset?.deletionDate * 1000) },
        message: 'translate.restore.asset.dialog.message',
        button: 'translate.button.restore',
        onComplete: () => {
            toggle();
            const ASSETS_API = getAssetsAPI();
            api('GET', ASSETS_API);
            NotificationManager.success('translate.asset.restore.success');
        },
        open,
        toggle,
        status: ASSET_STATUS.Draft
    }

    const handleAssetNameClick = (asset) => {
        setSelectedAsset(asset);
        switch (asset.status) {
            case ASSET_STATUS.Draft: {
                const map = {
                    real_estate: 'realEstate',
                    fine_art: 'fineArt'
                }

                history.push(getRoute(ROUTES.UPDATE_DRAFT_ASSET, {
                    id: asset.id, draftId: asset.draftId, type: map[asset.class]
                }))
                break;
            }

            case ASSET_STATUS.Deleted: {
                toggle();
                break;
            }

            default:
                history.push(getRoute(ROUTES.ASSET, { id: asset.id }));
        }
    }

    return (
        <React.Fragment>
            <WBList
                emptyMessage='translate.pages.deposit.list.empty'
                renderHeadItems={() => state.map((cell) => {
                    const inActive = selectedFilter.key !== cell.key;
                    return (
                        <TableCell
                            key={cell.key}
                            onClick={() => toggleSort(cell.key)}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: inActive ? 'text.secondary' : 'text.primary',
                                    '& > div': {
                                        '& > button': {
                                            backgroundColor: 'transparent',
                                            opacity: 1,
                                            transition: (theme) => theme.transitions.create(['color', 'opacity'], {
                                                easing: theme.transitions.easing.sharp,
                                                duration: theme.transitions.duration.enteringScreen,
                                            })
                                        }
                                    }
                                }
                            }}
                        >
                            <div style={{ display: 'inline-flex', flexWrap: 'nowrap', position: 'relative' }}>
                                <TranslatedMessage id={cell.value} />
                                <IconButton
                                    disableRipple
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: -32,
                                        color: inActive ? 'text.secondary' : 'text.primary',
                                        ...(inActive && ({ opacity: 0 })),
                                        transition: (theme) => theme.transitions.create(['color', 'opacity'], {
                                            easing: theme.transitions.easing.sharp,
                                            duration: theme.transitions.duration.leavingScreen
                                        })
                                    }}
                                >
                                    {sortState[cell.sorted]}
                                </IconButton>
                            </div>
                        </TableCell>
                    )
                })}
                data={assets.sort(sortDirection[selectedFilter.key])}
                colSpan={state.length}
                row={(asset) => (
                    <TableRow key={asset.id}>
                        <TableCell component="th" scope="row">
                            <Link onClick={() => handleAssetNameClick(asset)}>
                                {asset.name}
                            </Link>
                        </TableCell>
                        <TableCell sx={{ minWidth: '115px', fontSize: '16px' }}>
                            <TranslatedMessage id={asset.class} />
                        </TableCell>
                        <TableCell sx={{ minWidth: '120px', fontSize: '16px' }}>
                            {formatCurrency(
                                raisedFunds(asset)
                            )}
                        </TableCell>
                        <TableCell sx={{ minWidth: '140px', fontSize: '16px' }}>
                            {formatCurrency(
                                fundraisingGoal(asset)
                            )}
                        </TableCell>
                        <TableCell>
                            {
                                asset?.assetOwner?.companyName
                                    ? (
                                        <Link onClick={() => history.push(
                                            getRoute(ROUTES.ASSET_OWNER_DETAILS, { id: asset.assetOwner.id })
                                        )}>
                                            {asset.assetOwner.companyName}
                                        </Link>
                                    )
                                    : '-'
                            }
                        </TableCell>
                        <TableCell sx={{ minWidth: '160px', fontSize: '16px' }}>
                            {formatDateTimeTechnical(asset.createdAt)}
                        </TableCell>
                        <TableCell sx={{ minWidth: '160px', fontSize: '16px' }}>
                            {asset.publishedAt ? formatDateTimeTechnical(asset.publishedAt) : '-'}
                        </TableCell>
                        <TableCell sx={{ minWidth: '120px' }}>
                            {STATUS_COLOR_MAP[asset.status] ?
                                <WBLabel
                                    type='inline'
                                    text={asset.status}
                                    variant="text"
                                    color={STATUS_COLOR_MAP[asset.status]}
                                    size="large"
                                    density={0}
                                />
                                : asset.status
                            }
                        </TableCell>
                    </TableRow>
                )}
            />
            <AssetStatusUpdate {...dialogProps} />
        </React.Fragment>
    );
}
