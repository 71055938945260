import { jsonRequest } from '../../common/api/utils';
import { fileRequest } from '@wealthberry/common-base/src/api/utils';
import CONFIG from '../../config';
import { getItem, setItem, setTokens } from '@wealthberry/common-base/src/utils/localstorage';
import { NotificationManager } from '@wealthberry/common-base/src/notifications';
import { history } from '../../index';
import { ROUTES } from '../../common/constants';
import { saveFile } from '../../common/reducers/save-file-location';

export const getCustomFieldsData = (stepOneCustomFields) => {
    return Object.keys(stepOneCustomFields).map((key, index) => ({
            'type': 'string',
            'priority': index,
            'name': key,
            'value': stepOneCustomFields[key].value
        })
    );
}

export const sortFunctions = (direction: string) => {
    const reverse = direction === 'down' ? -1 : 1;
    return {
        name:       (a: Asset, b: Asset) => reverse * a.name.localeCompare(b.name),
        class:      (a: Asset, b: Asset) => reverse * a.class.localeCompare(b.class),
        status:     (a: Asset, b: Asset) => reverse * a.status.localeCompare(b.status),
        funds:      (a: CombinedAsset, b: CombinedAsset) => reverse * (raisedFunds(a) - raisedFunds(b)),
        goal:       (a: CombinedAsset, b: CombinedAsset) => reverse * (fundraisingGoal(a) - fundraisingGoal(b)),
        owner:      (a: CombinedAsset, b: CombinedAsset) => reverse * (a.assetOwner?.companyName.localeCompare(b.assetOwner?.companyName)),
        published:  (a: CombinedAsset, b: CombinedAsset) => reverse * (a.createdAt - b.createdAt)
    }
}

export function raisedFunds(a: CombinedAsset): number {
    return (a.totalBoughtTokens || 0) * (a.tokenIssuancePrice || 0)
}

export function fundraisingGoal(a: CombinedAsset): number {
    return (a.tokenSupply - a.reservedTokens) * a.tokenIssuancePrice;
}

export const fileUpload = (image: File, id: string, priority, dispatch) => {
    const formData = new FormData();
    formData.append('file', image);

    return new Promise((resolve, reject) => {
        fileRequest<{ contentUrl: string }>('POST', `${CONFIG.ASSETS_API}/media_objects`, formData)
            .then(({ contentUrl }) => {
                dispatch(saveFile({ id, contentUrl, priority, title: image.name, alt: image.name }));
                resolve({ contentUrl });
            })
            .catch((e) => {
                if (e.status === 401) {
                    jsonRequest('GET', `${CONFIG.ADMIN_API}/authenticate/refresh`)
                        .then((tokens: { token: string; refreshToken: string }) => {
                            setTokens(tokens);
                            fileRequest<{ contentUrl: string }>('POST', `${CONFIG.ASSETS_API}/media_objects`, formData)
                                .then(({ contentUrl }) => {
                                    dispatch(saveFile({ id, contentUrl, priority, title: image.name, alt: image.name }));
                                    resolve({});
                                })
                        })
                        .catch(() => {
                            // failed to refresh tokens
                            setTokens(null);
                            setItem('wb-client', null);
                            NotificationManager.error('translate.session.expired')
                            history.push(ROUTES.ADMIN);
                        });
                } else {
                    reject(e)
                }
            });
    })
}

export const getFormErrors = (state) => {
    return Object.keys(state).reduce((acc, formId) => {
        const fieldErrors = Object.values(state[formId]).reduce((a, field) => a || field?.error, false);
        return acc || fieldErrors
    }, false);
}

export function getValidations(activeStep: number) {
    const validations = getItem('wb-doc')?.[`${CONFIG.ASSETS_API}`]?.['/api/assets']?.['post']?.['x-validation'] || {};

    if (activeStep !== 2) {
        const removedNotBlankValidation = Object.entries(validations).reduce((acc, [key, value]) => {
            return {
                ...acc,
                [key]: value.filter(({ name }) => name !== 'NotBlank')
            }
        }, {});

        return { validations: removedNotBlankValidation };
    }

    return { validations };
}
