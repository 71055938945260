import React, { lazy, Suspense, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ApiContext } from '@wealthberry/common-base/src/api/index';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useHistory, useParams } from 'react-router-dom';
import AssetValuations from './asset-valuation/asset-valuations';
import AssetLiquidation from './asset-liquidation/asset-liquidation';
import { PageLoad } from '../../common/components/page-load';
import InfoBox from './info-box';
import { details, investmentDetails, tokenDetails } from './constants';
import {
    getAsset,
    getAssetAPI,
    getLiquidation,
    getLiquidationAPI,
    getUserAssetStats,
    getUserAssetStatsAPI
} from '../../common/api/getters';
import { PaperBox } from '../../common/components/paper-box';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';
import { ASSET_STATUS, ROUTES } from '../../common/constants';
import { AssetStatusUpdate } from './dialogs/asset-status-update';
import { useApiCall } from '../../common/api/hook';
import { TabPanel } from '../../common/components/tab-panel';
import { NotificationManager } from '@wealthberry/common-base/src/notifications';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

const SaleProgress = lazy(() => import('../assets-list/components/sale-progress-chart'));

const realEstateFields = ['location', 'subcategory', 'totalArea', 'yearCompleted'];
const fineArtFields = ['authorName', 'fineartYearOfCreation', 'authorBirthyear', 'authorDeathyear', 'authorDescriptionDialog', 'authorImageDialog'];

export default function Asset() {
    const [state] = useContext(ApiContext);
    const { id: assetId } = useParams<{ id: string }>();

    const api = useApiCall();
    const history = useHistory();
    const [startSale, setStartSale] = useModal();
    const [del, setDelete] = useModal();
    const [announce, setAnnounce] = useModal();

    const ASSET_API = getAssetAPI(assetId);
    const USER_ASSET_STATS_API = getUserAssetStatsAPI(assetId);
    const ASSET_LIQUIDATION = getLiquidationAPI(assetId);
    const asset: Asset = getAsset(state, ASSET_API);
    const userAssetStats = getUserAssetStats(state, USER_ASSET_STATS_API)
    const mergedData = { ...asset, ...userAssetStats };
    const liquidation = getLiquidation(state, ASSET_LIQUIDATION);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filteredDetails = asset.class === 'translate.asset.class.fine_art'
        ? details.filter(({ id }) => !realEstateFields.includes(id))
        : details.filter(({ id }) => !fineArtFields.includes(id))

    const assetStatsEndpoints = asset.status === ASSET_STATUS.SaleOpen
        ? [USER_ASSET_STATS_API]
        : [];

    const TitleButtons: Record<string, Partial<ButtonProps>> = {
        Announce: {
            children: <TranslatedMessage id="translate.asset.status.action.announce" />,
            color: 'primary',
            id: 'announce',
            onClick: setAnnounce,
            variant: 'contained'
        },
        StartSale: {
            children: <TranslatedMessage id="translate.asset.status.action.start.sale" />,
            color: 'primary',
            id: 'start_sale',
            onClick: setStartSale,
            variant: 'contained',
        },
        Delete: {
            children: <TranslatedMessage id="translate.asset.status.delete" />,
            color: 'primary',
            id: 'delete',
            onClick: setDelete,
            variant: 'outlined'
        }
    }

    const VisibleButtons = {
        [ASSET_STATUS.Created]: [TitleButtons.Announce, TitleButtons.StartSale, TitleButtons.Delete],
        [ASSET_STATUS.Deleted]: [TitleButtons.Restore],
        [ASSET_STATUS.ComingSoon]: [TitleButtons.StartSale],
        [ASSET_STATUS.SaleOpen]: [],
        [ASSET_STATUS.Draft]: [],
        [ASSET_STATUS.Liquidated]: []
    }

    const DIALOGS = [
        {
            title: 'translate.delete.asset.dialog.title',
            alert: 'translate.delete.asset.dialog.alert',
            message: 'translate.delete.asset.dialog.message',
            button: 'translate.button.delete',
            open: del,
            toggle: setDelete,
            onComplete: () => {
                setDelete();
                api('GET', ASSET_API);
                history.push(ROUTES.ADMIN);
                NotificationManager.success('translate.asset.delete.success')
            },
            status: ASSET_STATUS.Deleted
        },
        {
            title: 'translate.sale.open.dialog.title',
            alert: 'translate.sale.open.dialog.alert',
            message: 'translate.sale.open.dialog.message',
            button: 'translate.button.sale.open',
            open: startSale,
            toggle: setStartSale,
            onComplete: () => {
                setStartSale();
                api('GET', ASSET_API);
                NotificationManager.success('translate.asset.start.sale.success')
            },
            status: ASSET_STATUS.SaleOpen
        },
        {
            title: 'translate.announce.asset.dialog.title',
            alert: 'translate.announce.asset.dialog.alert',
            message: 'translate.announce.asset.dialog.message',
            button: 'translate.button.announce',
            open: announce,
            onComplete: () => {
                setAnnounce();
                api('GET', ASSET_API);
                NotificationManager.success('translate.asset.announce.success')
            },
            toggle: setAnnounce,
            status: ASSET_STATUS.ComingSoon,
        }
    ]

    return (
        <PageLoad endpoints={[ASSET_API]} getters={[ASSET_API, ASSET_LIQUIDATION]}>
            <Container maxWidth="lg">
                <Box py={4} display="flex" justifyContent="space-between">
                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">
                            {asset.name}
                        </Typography>
                    </Box>
                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="flex-end">
                        {asset?.status && VisibleButtons[asset.status].map((props) => (
                            <Button {...props} sx={{ ml: 2 }} />
                        ))}
                    </Box>
                </Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Details" />
                    <Tab label="News update" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={4}>
                            <InfoBox asset={mergedData} list={filteredDetails} title='translate.asset.details' />
                            <InfoBox asset={mergedData} list={investmentDetails} title='translate.investment.details' />
                            <InfoBox asset={mergedData} list={tokenDetails} title='translate.token.details' />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={4}>
                            <PaperBox>
                                <Box p={2} display="flex" justifyContent="space-between">
                                    <Typography variant="h5" gutterBottom>
                                        <TranslatedMessage id="translate.sale.progress" />
                                    </Typography>
                                </Box>
                                <Grid container>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <PageLoad endpoints={assetStatsEndpoints}>
                                            <SaleProgress asset={asset} assetStats={userAssetStats} />
                                        </PageLoad>
                                    </Suspense>
                                </Grid>
                            </PaperBox>
                            <AssetValuations asset={mergedData} title='Asset valuations' />
                                <AssetLiquidation
                                    asset={mergedData}
                                    liquidation={liquidation}
                                    title='translate.asset.liquidation'
                                />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    -
                </TabPanel>
            </Container>

            {DIALOGS.map((d) => <AssetStatusUpdate {...d} key={d.title} assetId={assetId} />)}
        </PageLoad>
    );
}
