import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import { formatCountry, formatDateTimeTechnical } from '../../common/utils';
import { formatCurrency, getRoute } from '@wealthberry/common-base/src/utils';
import { ROUTES } from '../../common/constants';
import WBLabel from '@wealthberry/common-base/src/components/wb-label';

import { useHistory } from 'react-router-dom';
import { statusColors } from '../deposits/row';

export const Row: FC<{ item: Transaction }> = ({ item }) => {
    const history = useHistory();

    return (
        <TableRow key={item.id} hover style={{ cursor: 'pointer' }}>
            <TableCell component="th" scope="row">
                <Link onClick={() => history.push(getRoute(ROUTES.TRANSACTION_DETAILS, { type: 'burn', id: item.id }))}>
                    {formatCurrency(parseFloat(item.price) * parseFloat(item.baseAmount), item.currency) }
                </Link>
            </TableCell>
            <TableCell>
                <Link onClick={() => history.push(getRoute(ROUTES.ASSET, { id: item.assetId }))}>
                    {item.baseSymbol}
                </Link>
            </TableCell>
            <TableCell>
                <Link onClick={() => history.push(getRoute(ROUTES.INVESTOR_DETAILS, { id: item.details.clientId }))}>
                    {item.details?.firstName} {item.details?.lastName}
                </Link>
            </TableCell>
            <TableCell>
                {formatCountry(item.details?.country)}
            </TableCell>
            <TableCell>
                {formatDateTimeTechnical(item.createdAt)}
            </TableCell>
            <TableCell>
                <WBLabel text={item.status} type='inline' color={statusColors[item.status]} variant="text" />
            </TableCell>
        </TableRow>
    )
}
