import React, { FC } from 'react';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { parseRichText } from '@wealthberry/common-base/src/packages/text-editor';
import Typography from '@mui/material/Typography';

interface Props {
    onClose: (event?) => void;
    open: boolean;
    description: string;
}

export const DescriptionDialog: FC<Props> = ({ onClose, open, description }) => {
    const parsedDescription = parseRichText(description);
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>
                <TranslatedMessage id="translate.description.dialog.title" />
            </DialogTitle>
            <Box p={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography component='div'>
                            {parsedDescription}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'end' }}>
                        <Button disableElevation onClick={onClose} color="inherit">
                            <TranslatedMessage id='translate.button.close' />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};
