import React, { FC, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { loadGMaps } from '../../../common/utils/google-maps';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';

interface Props {
    onClose: (event?) => void;
    open: boolean;
    location: any;
}

export const LocationDialog: FC<Props> = ({ onClose, open, location }) => {
    useEffect(() => {
        loadGMaps(() => {
            const info = location ? JSON.parse(location) : { address: { formatted_address: '' } };
            const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
                center: { lat: info.lat, lng: info.lng },
                zoom: 12,
                disableDefaultUI: true,
            });

            const contentString = `<p>${info.address.formatted_address}</p>`;

            const infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            const marker = new google.maps.Marker({
                position: { lat: info.lat, lng: info.lng },
                map,
                title: info.address.formatted_address,
            });

            infowindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
            });

            marker.addListener('click', () => {
                infowindow.open({
                    anchor: marker,
                    map,
                    shouldFocus: false,
                });
            });

        });
    }, []);

    return (
        <Dialog onClose={onClose} open={open} maxWidth='md' fullWidth>
            <div id="map" style={{ height: '540px' }}></div>
            <div style={{
                background: '#fff',
                borderRadius: '100%',
                position: 'absolute',
                top: '16px',
                right: '16px'
            }}>
                <IconButton size="small" onClick={onClose}>
                    <CloseOutlinedIcon />
                </IconButton>
            </div>
        </Dialog>
    );
};
