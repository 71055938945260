import { getClientsApi } from '../../common/api/getters';
import { FC, useEffect, useReducer, useState } from 'react';
import { useApiCall } from '../../common/api/hook';
import { useModal } from '@wealthberry/common-base/src/hooks/useModal';
import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import { FIELDS, formInitial } from './reducer';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Row } from './row';
import { FilterDialog } from './filter-dialog';
import { getValues } from '@wealthberry/common-base/src/utils/index';
import { Filters } from '@wealthberry/common-base/src/components/wb-list/filters';
import { initialFiltration } from '../../common/utils';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { getFiltersLoadingState } from '@wealthberry/common-base/src/loader/context';

export const clientsFiltration = ({ url, data }) => {
    if (data[FIELDS.EMAIL]) {
        url += `&email=${data[FIELDS.EMAIL]}`;
    }

    if (data[FIELDS.FIRST_NAME]) {
        url += `firstName=${data[FIELDS.LAST_NAME]}`;
    }

    if (data[FIELDS.LAST_NAME]) {
        url += `lastName=${data[FIELDS.LAST_NAME]}`;
    }

    if (data[FIELDS.PHONE_CODE] || data[FIELDS.PHONE]) {
        url += `&status=${data[FIELDS.PHONE_CODE]}${data[FIELDS.PHONE]}`;
    }

    if (data[FIELDS.COMPANY]) {
        url += `&companyName=${data[FIELDS.COMPANY]}`;
    }

    if (data[FIELDS.COUNTRY]) {
        url += `&clientAddress.country=${data[FIELDS.COUNTRY].code}`;
    }

    if (data[FIELDS.COMPLETED_PROFILE]) {
        url += `&status=${data[FIELDS.COMPLETED_PROFILE] ? 1 : 0}`;
    }

    return url;
}

const Investors: FC = () => {
    const api = useApiCall();
    const [open, toggle] = useModal();
    const [form, dispatch] = useReducer(updateFormReducer, formInitial);
    const [items, setItems] = useState<any[]>([]);

    const handleFiltration = () => {
        const filters = getValues(form.dialog);
        let url = initialFiltration({ url: `${getClientsApi()}?`, form });
        url = clientsFiltration({ url, data: filters });

        api('GET', url).then((result: Transaction[]) => {
            setItems(result);
        });
    }

    useEffect(() => {
        handleFiltration()
    }, [form.selected, form.filters]);
    const loading = getFiltersLoadingState([getClientsApi()])
    return (
        <Container disableGutters maxWidth="lg">
            <Box py={4} display="flex" justifyContent="space-between">
                <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant='h4'>
                        <TranslatedMessage id='translate.investors' />
                    </Typography>
                </Box>
            </Box>
            <WBList
                loading={loading}
                emptyMessage='translate.pages.investors.list.empty'
                headItems={[
                    'translate.email',
                    'translate.investor.name',
                    'translate.country',
                    'translate.join.date',
                ]}
                immutableFieldsCustom={{
                    from: { label: 'Joined from' },
                    to: { label: 'Joined to' },
                }}
                form={form}
                dispatch={dispatch}
                data={items}
                toggle={toggle}
                formInitial={formInitial}
                row={(item) => <Row item={item} />}
            >
                <Filters
                    form={form}
                    formInitial={formInitial}
                    dispatch={dispatch}
                    toggle={toggle}
                />
                <FilterDialog
                    open={open}
                    formInitial={formInitial}
                    toggle={toggle}
                    form={form}
                    dispatch={dispatch}
                />
            </WBList>
        </Container>
    );
}

export default Investors;
