import { filters } from '../../common/constants';
import { formatCurrencySymbol } from '@wealthberry/common-base/src/utils';

export const FIELDS = {
    STATUS: 'status',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    AMOUNT_FROM: 'baseAmount[gte]',
    AMOUNT_TO: 'baseAmount[lte]',
    IBAN: 'iban',
    COUNTRY: 'country',
    COMPLETED_BY: 'CompletedBy',
    COMPLETED_FROM: 'CompletedBy[gte]',
    COMPLETED_TO: 'CompletedBy[lte]',
}
export const immutableFields = {
    [FIELDS.STATUS]: {
        id: FIELDS.STATUS,
        label: 'translate.status',
        type: 'autocomplete',
        options: [{
            'name': 'Completed',
            'value': 'translate.status.completed'
        }, {
            'name': 'Pending',
            'value': 'translate.status.pending'
        }],
        getOptionLabel: (option) => option.name,
    },
    [FIELDS.FIRST_NAME]: {
        id: FIELDS.FIRST_NAME,
        label: 'translate.first.and.middle.name',
        type: 'text',
    },
    [FIELDS.LAST_NAME]: {
        id: FIELDS.LAST_NAME,
        label: 'translate.last.name',
        type: 'text',
    },
    [FIELDS.AMOUNT_FROM]: {
        id: FIELDS.AMOUNT_FROM,
        label: 'translate.amount.from',
        type: 'text',
        xs: 6,
        _label: `From ${formatCurrencySymbol()}{value}`,
    },
    [FIELDS.AMOUNT_TO]: {
        id: FIELDS.AMOUNT_TO,
        label: 'translate.amount.to',
        type: 'text',
        xs: 6,
        _label: `to ${formatCurrencySymbol()}{value}`,
    },
    [FIELDS.IBAN]: {
        id: FIELDS.IBAN,
        label: 'translate.iban',
    },
    [FIELDS.COUNTRY]: {
        id: FIELDS.COUNTRY,
        label: 'translate.country',
        type: 'autocomplete',
    },
    [FIELDS.COMPLETED_BY]: {
        id: FIELDS.COMPLETED_BY,
        label: 'translate.completed.by',
    },
    [FIELDS.COMPLETED_TO]: {
        id: FIELDS.COMPLETED_TO,
        label: 'translate.completed.to',
        type: 'text',
        xs: 6,
    },
    [FIELDS.COMPLETED_FROM]: {
        id: FIELDS.COMPLETED_FROM,
        label: 'translate.completed.from',
        type: 'text',
        xs: 6,
    },
}

export const formInitial = {
    ...filters,
    dialog: {
        [FIELDS.STATUS]: {
            error: false,
            value: null,
        },
        [FIELDS.FIRST_NAME]: {
            error: false,
            value: '',
        },
        [FIELDS.LAST_NAME]: {
            error: false,
            value: '',
        },
        [FIELDS.AMOUNT_FROM]: {
            error: false,
            value: '',
        },
        [FIELDS.AMOUNT_TO]: {
            error: false,
            value: '',
        },
        [FIELDS.IBAN]: {
            error: false,
            value: '',
        },
        [FIELDS.COUNTRY]: {
            error: false,
            value: null,
        },
        [FIELDS.COMPLETED_BY]: {
            error: false,
            value: '',
        },
        [FIELDS.COMPLETED_FROM]: {
            error: false,
            value: '',
        },
        [FIELDS.COMPLETED_TO]: {
            error: false,
            value: '',
        },
    },
    selected: []
}
