import { FC } from 'react';
import List from '@mui/material/List';
import { InfoBoxRow } from '../../../common/components/info-box-row';
import { raisedFunds } from '../../assets-list/utils';
import { formatCurrency } from '@wealthberry/common-base/src/utils';
import WBDialogWrapper from '../../../common/components/wb-dialog-wrapper';

export const DialogRaisedFunds: FC<{
    assets: CombinedAsset[];
    open: boolean;
    setClose: VoidFunction;
}> = (props) => {
    const { assets, open, setClose } = props;

    return (
        <WBDialogWrapper
            open={open}
            onClose={setClose}
            title='translate.raised.funds'
        >
            <List sx={{ pl: 1, pr: 1 }}>
                {assets.map((asset, i) => (
                    <InfoBoxRow
                        key={asset.id}
                        divider={i < assets.length - 1}
                        property={asset.name}
                        value={formatCurrency(raisedFunds(asset))}
                    />
                ))}
            </List>
        </WBDialogWrapper>
    )
}
