import React, { FC, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import CONFIG from '../../../config';

const ImagePreview: FC<{ open: boolean; onClose: () => void; image: any }> = ({ onClose, open, image }) => {
    const [selected, setSelected] = useState(null);

    const handleClose = () => {
        setSelected(null);
        onClose();
    }
    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xl" fullWidth sx={{ width: '100%', height: '100%' }}>
            <Box
                display="flex"
                justifyContent={selected ? 'space-between' : 'flex-end'}
                alignItems="center"
                flexGrow={1}
                p={3}
                pb={2}
            >
                <IconButton
                    size="small"
                    onClick={handleClose}
                >
                    <CloseOutlinedIcon />
                </IconButton>
            </Box>
            <Box px={5} pb={5} display="flex" justifyContent="space-between" alignItems="center" width="100%"
                 height="85vh">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    width="100%"
                    height="100%"
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'relative'
                        }}
                    >
                        <img
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                bottom: 0,
                                right: 0,
                                margin: '0 auto',
                                maxWidth: '100%',
                                objectFit: 'contain'
                            }}
                            src={`${CONFIG.ASSETS}${image}`}
                        />
                    </div>
                </Box>
            </Box>
        </Dialog>
    );
}

export default ImagePreview;
