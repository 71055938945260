import * as React from 'react';
import { AuthenticationPageTemplate } from './components/authentication-page-template';
import { makeStyles } from '@mui/styles';
import {
    Login,
} from './pages';
import { ROUTES } from '../../common/constants';
import { Route, Switch } from 'react-router-dom';
import { Dispatch } from 'react';
import { getTokens } from '@wealthberry/common-base/src/utils/localstorage';
import { history } from '../../index';
import { LoginTest } from './pages/login-test';

const useStyles = makeStyles(() => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const Authentication: React.FC<{ language: string; setLanguage: Dispatch<any> }> = ({ language, setLanguage }) => {
    const classes = useStyles();
    const { token, error } = getTokens();

    if (token) {
        history.push(ROUTES.ADMIN);
    }

    return (
        <AuthenticationPageTemplate language={language} setLanguage={setLanguage}>
            <div className={classes.paper}>
                <Switch>
                    <Route path={ROUTES.LOGIN_TEST} component={LoginTest} />
                    <Route path={ROUTES.LOGIN} component={Login} />
                </Switch>
            </div>
        </AuthenticationPageTemplate>
    )
}

export default Authentication;
