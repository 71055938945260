import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Paper } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import { LiquidationDialogStepOne } from './liquidation-dialog-step-one';
import { LiquidationDialogStepTwo } from './liquidation-dialog-step-two';
import { getDialogListItems } from './constants';
import { ASSET_STATUS } from '../../../common/constants';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { PartialPageLoad } from '../../../common/components/partial-page-load';
import { getLiquidationAPI } from '../../../common/api/getters';

export default function AssetLiquidation({ asset, liquidation, title }) {
    const [open, setDialogState] = useState(false);
    const isAssetLiquidated = asset.status === ASSET_STATUS.Liquidated || liquidation?.liquidationEvents?.find((event) => event.eventName === 'requested');
    const list = getDialogListItems({ liquidation, isAssetLiquidated, asset });
    const ASSET_LIQUIDATION = getLiquidationAPI(asset.assetId);

    return (
        <Grid item xs={12}>
            {!liquidation?.amount && (
                <LiquidationDialogStepOne
                    open={open}
                    onClose={() => setDialogState(false)}
                    id={asset.id}
                />
            )}
            {liquidation?.amount && (
                <LiquidationDialogStepTwo
                    open={open}
                    onClose={() => setDialogState(false)}
                    id={asset.id}
                    currency={asset.currency}
                    liquidationInfo={liquidation}
                />
            )}
            <PartialPageLoad endpoints={[ASSET_LIQUIDATION]}>
                <Paper square variant="outlined">
                    <Box p={2} display='flex' justifyContent='space-between'>
                        <Typography variant='h5' gutterBottom>
                            <TranslatedMessage id={title} />
                        </Typography>
                        {!isAssetLiquidated && (
                            <Button
                                disabled={asset.status !== ASSET_STATUS.SaleOpen}
                                variant="outlined"
                                onClick={() => setDialogState(true)}
                            >
                                <TranslatedMessage
                                    id={!liquidation?.amount
                                        ? 'translate.add.liquidation.amount'
                                        : 'translate.liquidate.asset'
                                    }
                                />
                            </Button>
                        )}
                    </Box>
                    <List>
                        {list.map(({ key, value }, index) => (
                            <ListItem divider={index < list.length - 1} key={key}>
                                <Typography variant="body1" color="textSecondary">
                                    {key}
                                </Typography>

                                <ListItemSecondaryAction>
                                    <Box
                                        fontWeight="fontWeightMedium"
                                        fontSize="body1.fontSize"
                                    >
                                        {value}
                                    </Box>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </PartialPageLoad>
        </Grid>
    );
}

