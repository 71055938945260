import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: '100%',
        margin: '0 auto',
        width: '100%',
    },
    content: {
        flexGrow: 1,
        marginTop: 80,
        background: '#F5F5F5',
        overflow: 'auto',
        height: 'calc(100vh - 80px)',
        paddingLeft: 240,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 180,
        },
    }
}));
