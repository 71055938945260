import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router'
import { createBrowserHistory } from 'history';
import CONFIG from './config'

import { App } from './app';

export const history = createBrowserHistory();

function prepare() {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { worker } = require('./mocks/browser');

        worker.start({
            onUnhandledRequest: 'bypass'
        });
    }

    if (!window.localStorage.getItem('wb-language')) {
        window.localStorage.setItem('wb-language', JSON.stringify('en'));
    }

    if (CONFIG.TEST) {
        return Promise.resolve();
    }

    return new Promise((resolve) => {
        fetch(
            'https://lingua.wealthberry.com/wb/admin/messages.json',
            { method: 'GET' }
        )
            .then((response) => response.json())
            .then((res) => window.localStorage.setItem('wb-translations', JSON.stringify(res)))
            .then(resolve)
    });

}

prepare().then(() => {
    ReactDOM.render(
        <Router history={history}>
            <App />
        </Router>,
        document.getElementById('root')
    );
});
