import { FC } from 'react';
import { getValues } from '@wealthberry/common-base/src/utils';
import {
    immutableFields,
    initialStateStepOne,
} from './constants';
import { LiquidationDialog } from './liquidation-dialog';

interface Props {
    id: string;
    onClose: (event?) => void;
    open: boolean;
}

export const LiquidationDialogStepOne: FC<Props> =  ({ id, ...rest })=> {
    const getBody = (form) => {
        const values = getValues(form);
        return {
            'asset': `/api/assets/${id}`,
            'amount': values.amount,
            'signature': 'hash1'
        };
    }

    return (
        <LiquidationDialog
            {...rest}
            title="translate.liquidation.dialog.title"
            successMessage="The liquidation amount is added and ready for distribution."
            id={id}
            getBody={getBody}
            initialState={initialStateStepOne}
            immutableFields={immutableFields}
        />
    );
};
