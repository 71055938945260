import { FC } from 'react';
import Grid from '@mui/material/Grid';
import { immutableFields } from './reducer';
import { WBFormField } from '@wealthberry/common-base/src/forms/field';
import { WBFilterDialogWrapper } from '@wealthberry/common-base/src/components/wb-list/wb-filter-dialog';
import { FIELDS } from './reducer';

type Props = {
    toggle: () => void,
    open: boolean;
    dispatch: any;
    form: any;
    formInitial: any;
    tokens: any[];
}

export const FilterDialog: FC<Props> = ({ toggle, open, form, dispatch, formInitial, tokens }) => {
    if (!tokens) {
        return null;
    }

    const custom = {
        [FIELDS.TOKEN_SYMBOL]: {
            options: tokens
        }
    }
    return (
        <WBFilterDialogWrapper
            title="translate.filter.liquidations"
            toggle={toggle}
            open={open}
            form={form}
            dispatch={dispatch}
            formInitial={formInitial}
        >
            <Grid container spacing={3}>
                {Object.keys(form.dialog).map((key) => (
                    <WBFormField
                        key={key}
                        {...form.dialog[key]}
                        _fieldInfo={{
                            ...immutableFields[key],
                            ...custom[key]
                        }}
                        setState={(updatedField) =>
                            dispatch({
                                type: 'UPDATE_FORM',
                                payload: {
                                    dialog: {
                                        [key]: updatedField,
                                    }
                                }
                            })
                        }
                        validations={[]}
                    />
                ))}
            </Grid>
        </WBFilterDialogWrapper>
    );
};

