import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return value === index && (
        <Box pt={4}>
            <Grid container spacing={4}>
                {children}
            </Grid>
        </Box>
    );
}
