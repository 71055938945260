import React, { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useApiCall } from '../api/hook';
import { compose } from '@wealthberry/common-base/src/utils';
import { getFiltersLoadingState, LoaderContext } from '@wealthberry/common-base/src/loader/context';

interface Props {
    endpoints: string[];
    getters?: string[];
    showChildren?: boolean;
}

function withGetApiCalls(Component) {
    return function (props) {
        const { endpoints = [], getters = [] } = props;
        const api = useApiCall();

        useEffect(() => {
            Promise.all(getters.map((endpoint) => {
                try {
                    const url = new URL(endpoint);
                    api('GET', endpoint);
                } catch (e) {
                    // invalid url passed, do nothing
                    // happens because we don't want to change size of dependency array when having conditional api calls
                }
            }))
        }, endpoints);

        return <Component {...props} />;
    }
}

const Loader: React.FC<Props> = (
    {
        endpoints = [],
        showChildren = true,
        children
    }) => {

    const loading = getFiltersLoadingState(endpoints)

    return (
        <React.Fragment>
            <LoaderContext.Provider value={{
                parentLoaderVisible: loading
            }}>
                {showChildren && children}
                <Backdrop
                    sx={{
                        zIndex: 99999999,
                        color: '#fff',
                    }}
                    open={loading}
                    transitionDuration={1000}
                >
                    <CircularProgress />
                </Backdrop>
            </LoaderContext.Provider>
        </React.Fragment>
    );
}

export const PageLoad = compose(
    withGetApiCalls
)(Loader);
