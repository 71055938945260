import { FC } from 'react';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants';
import Typography from '@mui/material/Typography';
import { useIntlWithDefaultMessage } from '@wealthberry/common-base/src/packages/translated-message';

export const NavigationDrawer: FC = () => {
    const intl = useIntlWithDefaultMessage();
    const history = useHistory();

    const MENU_OPTIONS = [
        { value: 'translate.home', key: 'home' },
        { value: 'translate.investors', key: 'investors', action: () => history.push(ROUTES.INVESTORS) },
        { value: 'translate.deposits', key: 'deposits', action: () => history.push(ROUTES.DEPOSITS) },
        { value: 'translate.withdrawals', key: 'withdrawals', action: () => history.push(ROUTES.WITHDRAWALS) },
        { value: 'translate.primary.market.orders', key: 'orders', action: () => history.push(ROUTES.PRIMARY_MARKET) },
        { value: 'translate.liquidations', key: 'liquidations', action: () => history.push(ROUTES.LIQUIDATIONS) },
        { value: 'translate.assets', key: 'assets', action: () => history.push(ROUTES.ADMIN) },
        { value: 'translate.asset.owners', key: 'asset-owners', action: () => history.push(ROUTES.ASSET_OWNER) }
    ]

    return (
        <Drawer
            variant="permanent"
            PaperProps={{
                sx: {
                    position: 'absolute',
                    paddingTop: '80px',
                    whiteSpace: 'nowrap',
                    width: {
                        xs: 180,
                        sm: 180,
                        md: 180,
                        lg: 240
                    },
                    height: '100vh',
                }
            }}
            open={true}
        >
            <Divider />
            <MenuList>
                {MENU_OPTIONS.map(({ value, key, action }) => (
                    <MenuItem key={key} onClick={action} data-e2e={`nav-${key}`}>
                        <Typography gutterBottom>
                            {intl.formatMessage({ id: value })}
                        </Typography>
                    </MenuItem>
                ))}
            </MenuList>
        </Drawer>
    );
}
