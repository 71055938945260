import { getCountries } from '@wealthberry/common-base/src/utils/localstorage';
import { TextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { getFlagEmoji, getFlagEmojiByCode } from '@wealthberry/common-base/src/utils/flag';
import { Fragment } from 'react';
import { getValues } from '@wealthberry/common-base/src/utils/index';

export const FORM_ID = 'assetOwnerForm';
export const countries = getCountries('list') || [];
export const extraProps: Record<string, Partial<TextFieldProps>> = {
    'phone-code': {
        children: countries.map(({ countryCode, name, code }) => (
            <MenuItem key={code} value={countryCode} onClick={(e) => e.stopPropagation()}>
                {`${getFlagEmoji(code)} ${countryCode} ${name}`}
            </MenuItem>
        )),
        SelectProps: {
            renderValue: (code: string) => (
                <Fragment>
                        <span style={{ position: 'relative', top: '2px ' }}>
                            {getFlagEmojiByCode(code, countries)}
                        </span>
                    &nbsp;
                    {code}
                </Fragment>
            )
        },
        sx: {
            '& .MuiOutlinedInput-root': {
                '& .MuiOutlinedInput-notchedOutline': {
                    // borderRight: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                }
            }
        }
    },
    'phone': {
        sx: {
            '& .MuiOutlinedInput-root': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                }
            }
        }

    }
};

export function formSelector(form) {
    const { 'phone-code': phoneCode, phone, ...rest } = getValues(form);

    return {
        ...rest,
        country: rest.country.code,
        phone: `${phoneCode}${phone}`
    }
}
