import { getCountries } from '@wealthberry/common-base/src/utils/localstorage';
import { getValues } from '@wealthberry/common-base/src/utils';

export const formatDateTimeTechnical = (date) => {
    const options: any = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    };
    const dateTechnical = new Date(date * 1000).toLocaleString('en-SE', options).replace(',', ' ');
    const timeTechnical = new Date(date * 1000).toLocaleString('en-SE', {
        hour: 'numeric',
        minute: 'numeric',
       // second: 'numeric'
    });

    return `${dateTechnical} ${timeTechnical}`;
}

export const getStatus = (status) => {
    return status === 'published' ? 'Sale open' : status
}

export const mapCategoryToTranslate = {
    residential: 'translate.asset.type.option.residential',
    commercial: 'translate.asset.type.option.commercial',
    industrial: 'translate.asset.type.option.industrial',
    'raw-land': 'translate.asset.type.option.raw-land',
    painting: 'translate.category.painting',
    sculpture: 'translate.category.sculpture',
    // TODO remove when returned from response
    default: '-'
}

export const mergeData = (arr = [], arr2 = [], arr3 = [], key = 'id') => {
    return arr.map((item) => {
        const id = item[key];
        const asset = arr2.find((i) => i[key] === id);
        const asset2 = arr3 ? arr3.find((i) => i[key] === id) : {};
        return Object.assign({}, item, asset, asset2)
    });
}

export const getPickerFormat = (date) => date.toISOString().split('T')[0];

export const initialFiltration = ({ url, form }) => {
    const dates: { from: string; to: string; } = getValues(form.filters);
    const from = (new Date(dates?.from) as any) / 1000;
    const to = (new Date(dates?.to).setHours(23, 59) as any) / 1000;
    const dateFilter = `createdAt[gte]=${from}&createdAt[lte]=${to}`;
    return `${url}${dateFilter}`;
}

export const formatCountry = (country) => {
    const countries = getCountries('dictionary');

    return countries[country];
}
