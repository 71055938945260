import CONFIG from '../../config';
import { getTokens } from '@wealthberry/common-base/src/utils/localstorage';

const apiRequest = <T>(method: string, url: string, headers, body?): Promise<T> => new Promise((resolve, reject) => {
    fetch(url, {
        headers,
        method,
        body
    })
        .then((response) => {
            if (!response.ok) {
                throw response
            }

            // there's nothing in response body
            if (response.status === 204) {
                return {};
            }

            return response.json();
        })
        .then(resolve)
        .catch(reject);
});

export const jsonRequest = <T>(method: string, url: string, body?): Promise<T> => {
    const { token, refreshToken } = getTokens();

    // New tokens are issued when we pass our current refresh token as token to clients.local.wealthberry.com/api/authenticate/refresh
    const separator = url.includes('?') ? '&' : '?';
    const endpoint = `${url}${separator}token=${url.includes(`${CONFIG.ADMIN_API}/authenticate/refresh`) ? refreshToken : token}`

    return apiRequest(method, endpoint, {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        JSON.stringify(body)
    )
}
