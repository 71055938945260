import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { immutableFields } from '@wealthberry/common-base/src/components/wb-list/form';

function DateFilters({ filters, dispatch }) {
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {Object.keys(filters)
                    .map((key) => {
                            return (
                                <Box key={key} flex={1} display="flex" alignItems="center"
                                     justifyContent="center"
                                     pr={2}>
                                    <DatePicker
                                        views={['year', 'month', 'day']}
                                        {...filters[key]}
                                        {...immutableFields[key]}
                                        onChange={(value) => {
                                            dispatch({
                                                type: 'UPDATE_FORM',
                                                payload: {
                                                    filters: {
                                                        [key]: { value: value, id: key },
                                                    }
                                                }
                                            });
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            size="small"
                                            helperText={null}
                                        />}
                                    />
                                </Box>
                            )
                        }
                    )}
            </LocalizationProvider>
        </Box>
    );
}

export default DateFilters;
