import { FC, Fragment } from 'react';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import CONFIG from '../../../config';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';

interface Props {
    onClose: (event?) => void;
    open: boolean;
    documents: any[];
}

export const InvestmentDocumentsDialog: FC<Props> = ({ onClose, open, documents }) => {
    return (
        <Dialog onClose={onClose} open={open} maxWidth='sm' fullWidth>
            <DialogTitle>
                <TranslatedMessage id="translate.documents.dialog.title" />
            </DialogTitle>
            <Box px={3}>
                <List>
                    {documents.map((doc, index) => (
                        <Fragment>
                            <ListItem disableGutters key={doc.contentUrl}>
                                <Typography variant="body1">
                                    {doc.title}
                                </Typography>

                                <ListItemSecondaryAction>
                                    <Link
                                        target="_blank"
                                        color="textSecondary"
                                        href={`${CONFIG.ASSETS}${doc.contentUrl}`}
                                    >
                                        <GetAppOutlinedIcon />
                                    </Link>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </Fragment>
                    ))}
                </List>
                <Box py={2} display="flex" alignContent='flex-end' justifyContent="flex-end">
                    <Button disableElevation onClick={onClose} color="inherit">
                        <TranslatedMessage id='translate.button.close' />
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};
