import Grid from '@mui/material/Grid';
import WBList from '@wealthberry/common-base/src/components/wb-list/wb-list';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { formatDateTechnical, getValues } from '@wealthberry/common-base/src/utils';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FIELDS } from '../../../common/logs/reducer';
import DateFilters from '../../../common/logs/date-filters';
import { initialLogFiltration, logFiltration } from '../../../common/logs/utils';
import { getClientLogsAPI } from '../../../common/api/getters';
import { useEffect, useState } from 'react';
import { useApiCall } from '../../../common/api/hook';
import { useParams } from 'react-router-dom';

export const Logs = ({ dispatch, form }) => {
    const { id: clientId } = useParams<{ id: string }>();
    const [logs, setItems] = useState<any[]>([]);
    const api = useApiCall();

    const handleFiltration = () => {
        const filters = getValues(form.dialog);
        let url = initialLogFiltration({ url: `${getClientLogsAPI()}?clientId=${clientId}&`, form });
        url = logFiltration({ url, data: filters });
        api('GET', url).then((result: Transaction[]) => {
            setItems(result);
        });
    }

    const options = Object.entries(
        logs.reduce((a, { category, description }) => ({ ...a, [category]: description }), {})
    ).map(([c, d]) => ({ category: c, description: d }))

    useEffect(() => {
        handleFiltration()
    }, [form.filters, form.dialog]);

    return (
        <Grid item xs={12}>
            <Box mb={4} display="flex" alignItems="center" justifyContent="space-between">
                <DateFilters filters={form.filters} dispatch={dispatch} />
                <Box flex={1} pr={2}>
                    <Autocomplete
                        size='small'
                        getOptionLabel={(option) => option.category}
                        options={options}
                        onChange={(event, newValue) => {
                            dispatch({
                                type: 'UPDATE_FORM',
                                payload: {
                                    dialog: {
                                        [FIELDS.CATEGORY]: { value: newValue?.description, id: FIELDS.CATEGORY },
                                    }
                                }
                            })
                        }}
                        renderInput={(params) => <TextField
                            sx={{ maxWidth: '225px' }}
                            label="Event type" {...params} />}
                    />
                </Box>
            </Box>
            <WBList
                emptyMessage="translate.pages.investor.list.empty"
                headItems={[
                    'translate.event.description',
                    'translate.date'
                ]}
                data={logs}
                row={(log) => (
                    <TableRow key={log.id} hover style={{ cursor: 'pointer' }}>
                        <TableCell component="th" scope="row">
                            {log.description}
                        </TableCell>
                        <TableCell>
                            {formatDateTechnical(log.producedAt)}
                        </TableCell>
                    </TableRow>
                )}
            />
        </Grid>
    )
}
