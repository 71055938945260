import * as React from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { getTokens } from '@wealthberry/common-base/src/utils/localstorage';

export const PrivateRoute: React.FC<RouteProps> = (props) => {
    const tokens = getTokens();

    if (!tokens?.token) {
        return <Redirect to="/" />
    }

    return <Route {...props} />
}
