import { FC, useCallback, useEffect, useReducer } from 'react';
import Box from '@mui/material/Box';
import { useApiCall, useApiPoll } from '../../../common/api/hook';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { getItem } from '@wealthberry/common-base/src/utils/localstorage';
import { WBFormField } from '@wealthberry/common-base/src/forms/field'
import { NotificationManager } from '@wealthberry/common-base/src/notifications';
import { reducer } from '../reducer';
import { getAssetAPI, getLiquidationAPI, submitLiquidationApi } from '../../../common/api/getters';
import { formatCurrency } from '@wealthberry/common-base/src/utils';
import CONFIG from '../../../config';
import { composeReducers } from '@wealthberry/common-base/src/utils/reducer';
import { updateFormReducer } from '@wealthberry/common-base/src/forms/reducer';
import { handleSubmit } from '@wealthberry/common-base/src/forms/handle-submit';
import { TranslatedMessage } from '@wealthberry/common-base/src/packages/translated-message';
import { useInterval } from '@wealthberry/common-base/src/hooks/use-interval';
import { ActionTypes, ApiContext } from '@wealthberry/common-base/src/api/index';
import { PartialPageLoad } from '../../../common/components/partial-page-load';

interface Props {
    onClose: (event?) => void;
    open: boolean;
    initialState: any;
    getBody: any;
    immutableFields: any;
    liquidationInfo?: any;
    successMessage: string;
    id: string;
    title: string;
}

export const LiquidationDialog: FC<Props> = (
    {
        children,
        initialState,
        onClose,
        open,
        getBody,
        immutableFields,
        liquidationInfo,
        id,
        title,
        successMessage
    }
) => {
    const api = useApiCall();
    const poll = useApiPoll();
    const liquidationReducer = composeReducers([updateFormReducer, reducer]);
    const [form, dispatch] = useReducer(liquidationReducer, initialState);
    const ASSET_LIQUIDATION = getLiquidationAPI(id);
    const ASSET_ID_API = getAssetAPI(id);
    const SUBMIT_LIQUIDATION_API = submitLiquidationApi();

    const pollAssetLiquidation = poll(({ liquidationEvents }) => {
        const liquidationEvent = liquidationEvents.find(({ eventName }) => eventName === 'liquidated');

        if (liquidationEvent) {
            NotificationManager.success(successMessage);
            onClose();
            return true;
        }

        return false;
    });

    const { liquidation } = form;
    const validations = getItem('wb-doc')?.[`${CONFIG.ASSETS_API}`]?.['/api/liquidate']?.['post']?.['x-validation'] || {};

    const triggerLiquidation = useCallback(() => {
        const body = getBody(liquidation);
        api('POST', SUBMIT_LIQUIDATION_API, body)
            .then(() => {
                if (!liquidationInfo) {
                    NotificationManager.success(successMessage);
                    api('GET', ASSET_ID_API);
                    api('GET', ASSET_LIQUIDATION);
                    onClose();
                } else {
                    const ASSET_LIQUIDATION = getLiquidationAPI(id);
                    pollAssetLiquidation('GET', ASSET_LIQUIDATION)
                        .then((res) => {
                            if (res.error === 'max-retries-reached') {
                                NotificationManager.error('Come back again later. This might take a while.')
                                onClose();
                            }
                        });
                }
            })
    }, [form]);

    const formSubmit = handleSubmit({
        dispatch,
        formId: 'liquidation',
        form: form.liquidation,
        validations,
        callback: triggerLiquidation
    });

    useEffect(() => {
        if (!liquidationInfo) {
            dispatch({
                type: 'UPDATE_FORM',
                payload: {
                    'liquidation': {
                        ['amount']: { id: 'amount', value: 0 }
                    }
                }
            });
        }
    }, [open]);

    useEffect(() => {
        if (liquidationInfo) {
            dispatch({
                type: 'UPDATE_FORM',
                payload: {
                    'liquidation': {
                        ['amount']: {
                            value: `${formatCurrency(parseFloat(liquidationInfo.amount))}`
                        },
                        ['targetReturn']: {
                            value: `${liquidationInfo.targetReturn}%`
                        },
                        ['returnPercent']: {
                            value: `${liquidationInfo.returnPercent}%`
                        }
                    }
                }
            });
        }
    }, []);

    return (
        <Dialog onClose={onClose} open={open} maxWidth='xs'>
                <DialogTitle>
                    <TranslatedMessage id={title} />
                </DialogTitle>
                <Box px={3} py={2}>
                    <Grid container spacing={2}>
                        {children && children[0]}
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                {Object.keys(liquidation).map((key) => (
                                    <Grid item xs={12}>
                                        <WBFormField
                                            {...liquidation[key]}
                                            _fieldInfo={immutableFields[key]}
                                            setState={(updatedField) =>
                                                dispatch({
                                                    type: 'UPDATE_FORM',
                                                    payload: {
                                                        liquidation: {
                                                            [key]: updatedField,
                                                        }
                                                    }
                                                })
                                            }
                                            validations={validations[key]}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {children && children[1]}
                        <Grid item xs={12} style={{ textAlign: 'end' }}>
                            <Button disableElevation onClick={onClose} color="inherit" sx={{ mr: 2 }}>
                                <TranslatedMessage id='translate.button.cancel' />
                            </Button>
                            <Button
                                disableElevation
                                color="primary"
                                variant="contained"
                                onClick={formSubmit}
                            >
                                <TranslatedMessage id='translate.button.confirm' />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
        </Dialog>
    );
};
