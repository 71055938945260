export const UPDATE_FIELD = 'UPDATE_FIELD';
export const SAVE_FILES = 'SAVE_FILES';
export const RESET_STATE = 'RESET_STATE';

export function reducer(state, action) {
    const { updatedField } = action.payload;
    switch (action.type) {
        case SAVE_FILES: {
            return {
                ...state,
                documents: updatedField
            }
        }
        case RESET_STATE: {
            return {
                ...state,
               ...action.payload
            }
        }
        default:
            return state;
    }
}

export const getFormValidationError = (form) => Object.values(form).find((field: any) => field.error);
