import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import WBLabel from '@wealthberry/common-base/src/components/wb-label';
import { formatCountry, formatDateTimeTechnical } from '../../common/utils';
import { formatCurrency, getRoute } from '@wealthberry/common-base/src/utils';
import Link from '@mui/material/Link';
import { ROUTES } from '../../common/constants';
import { useHistory } from 'react-router-dom';

export const statusColors = {
    'translate.status.done': 'success',
    'translate.status.pending': 'warning',
    'translate.status.failed': 'error',
    'translate.status.ledger_failed': 'error'
}

export const Row: FC<{ item: Transaction }> = ({ item }) => {
    const history = useHistory();
    return (
        <TableRow key={item.id} hover style={{ cursor: 'pointer' }}>
            <TableCell  component="th" scope="row">
                <Link onClick={() => history.push(getRoute(ROUTES.TRANSACTION_DETAILS, item))}>
                    {formatCurrency(parseInt(item.baseAmount), item.currency)}
                </Link>
            </TableCell>

            <TableCell >
                {item.details.type}
            </TableCell>
            <TableCell>
                <Link onClick={() => history.push(getRoute(ROUTES.INVESTOR_DETAILS, { id: item.details?.clientId }))}>
                    {item.details?.firstName} {item.details?.lastName}
                </Link>
            </TableCell>
            <TableCell>
                {formatCountry(item.details.country)}
            </TableCell>
            <TableCell>
                {formatDateTimeTechnical(item.createdAt)}
            </TableCell>
            <TableCell>
                <WBLabel type='inline' text={item.status} color={statusColors[item.status] || 'error'} variant="text" />
            </TableCell>
        </TableRow>
    )
}
